import { createContext, useContext, useState, useEffect } from 'react';
import { getCookie, setCookie, deleteUserCookiesFromBrowser } from '@utils/api';

import { baseURL } from '@utils/api';

const TokenContext = createContext({});

export const ACCESS_TOKEN = 'bd_access_token';
export const REFRESH_TOKEN = 'bd_refresh_token';

// set and get the tokens from cookies
export const setToken = token => {
	setCookie(ACCESS_TOKEN, token);
};
export const getToken = () => {
	const token = getCookie(ACCESS_TOKEN);
	return token;
};
export const setRefreshToken = token => {
	setCookie(REFRESH_TOKEN, token);
};
export const getRefreshToken = () => {
	return getCookie(REFRESH_TOKEN);
};

export const TokenProvider = ({ children }) => {
	const [worker, setWorker] = useState(null);

	const decodeToken = token => {
		if (!token) {
			throw new Error('Token is undefined or null');
		}

		const jwtPayload = token.split('.')[1];
		const payload = base64UrlDecode(jwtPayload);
		return JSON.parse(payload);
	};

	const refreshAuth = async () => {
		const originalAccessToken = getToken();
		const refreshToken = getRefreshToken();
		if (!originalAccessToken || !refreshToken) return;

		try {
			const response = await fetch(baseURL + '/auth/refresh', {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					access_token: originalAccessToken,
					refresh_token: refreshToken,
				}),
			});

			if (response.status === 200) {
				const data = JSON.parse(await response.text());
				const newAccessToken = data.token ?? null;

				if (!newAccessToken) {
					throw new Error(
						'Did not receive a new access token from the refresh token request!'
					);
				}

				setToken(newAccessToken);
			} else {
				deleteUserCookiesFromBrowser();

				// Check if the current URL is already the home url
				const currentURL = window.location.pathname;
				const homeURL = '/';

				if (currentURL !== homeURL) {
					window.location = homeURL;
				}
			}
		} catch (error) {
			console.error('something went wrong', error);
			deleteUserCookiesFromBrowser();
			throw error;
		}
	};

	useEffect(() => {
		const createWorker = () => {
			setWorker(prev => {
				if (prev) clearTimeout(prev);
				const token = getToken();
				if (!token) return null;

				const decodedToken = decodeToken(token);
				return setTimeout(async () => {
					refreshAuth();
				}, decodedToken.exp * 1000 - Date.now());
			});
		};

		const run = async () => {
			const token = getToken();

			if (!token) return;
			const payload = decodeToken(token);
			const exp = payload.exp;
			if (!exp) return;
			if (exp > Date.now() / 1000) {
				if (!!worker) return;
				await refreshAuth();

				createWorker();
				return;
			}

			if (exp < Date.now() / 1000) {
				await refreshAuth();
				createWorker();

				return;
			}
			return () => {
				setWorker(prev => {
					if (exp && exp > Date.now() / 1000) return prev;
					if (prev) clearTimeout(prev);
					return null;
				});
			};
		};

		if (!!worker) return;
		run();

		return () => {
			// cleanup
			if (worker) {
				clearTimeout(worker);
				setWorker(null);
			}
		};
	}, []);

	const value = {
		setToken,
		getToken,
		setRefreshToken,
		getRefreshToken,
		startWorker: () => refreshAuth(),
	};

	return (
		<TokenContext.Provider value={value}>{children}</TokenContext.Provider>
	);
};

export const useToken = () => {
	const context = useContext(TokenContext);
	if (!context) {
		throw new Error('useToken must be used within a TokenProvider');
	}
	return context;
};
// helpers

function b64DecodeUnicode(str) {
	return decodeURIComponent(
		window.atob(str).replace(/(.)/g, (m, p) => {
			let code = p.charCodeAt(0).toString(16).toUpperCase();
			if (code.length < 2) {
				code = '0' + code;
			}
			return '%' + code;
		})
	);
}

function base64UrlDecode(str) {
	let output = str.replace(/-/g, '+').replace(/_/g, '/');
	switch (output.length % 4) {
		case 0:
			break;
		case 2:
			output += '==';
			break;
		case 3:
			output += '=';
			break;
		default:
			throw new Error('base64 string is not of the correct length');
	}

	try {
		return b64DecodeUnicode(output);
	} catch (err) {
		return window.atob(output);
	}
}
