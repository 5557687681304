import Modal from 'react-bootstrap/Modal';
import styled from 'styled-components';

export const StyledModal = styled(Modal)`
    color: ${(props) => props.theme.colors.textColor};

    .btn-close {
        color: ${(props) => props.theme.colors.textColor};
    }

    .modal-header {
        border-bottom: 1px solid ${(props) => props.theme.colors.border};
    }

    .modal-footer {
        border-top: 1px solid ${(props) => props.theme.colors.border};
    }

    .modal-content {
        background-color: ${(props) => props.theme.colors.background};
    }
`;