/**
 * Checks if the password is valid
 * @param {string} password The password to check
 * @param {string} confirmPassword The password to check
 * @returns {string|boolean} Returns true if the password is valid, otherwise it returns a string with the error message
 */
export const checkPassword = (password, confirmPassword = null) => {
	const errors = [];

	if (confirmPassword) {
		if (password !== confirmPassword) {
			errors.push('Passwords do not match');
		}
	}
	if (password.length < 8) {
		errors.push('Password must be at least 8 characters long');
	}
	// make sure the password contains at least one uppercase letter
	if (!password.match(/[A-Z]/)) {
		errors.push('Password must contain at least one uppercase letter');
	}
	// make sure the password contains at least one lowercase letter
	if (!password.match(/[a-z]/)) {
		errors.push('Password must contain at least one lowercase letter');
	}
	// make sure the password contains at least one number
	if (!password.match(/[0-9]/)) {
		errors.push('Password must contain at least one number');
	}
	// make sure the password contains at least one special character including !@#$%^&*()_+*><
	if (!password.match(/[!@#$%^&*(),.?":{}|<>]/)) {
		errors.push('Password must contain at least one special character');
	}

	if (errors.length > 0) {
		return { validated: false, errors };
	}

	return {
		validated: true,
	};
};
