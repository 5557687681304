import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import styled from 'styled-components';

import { fetchUrl } from '@api';
import logo from '@assets/logo/logo_white.svg';

const ThumbnailLoader = styled.div`
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
	aspect-ratio: 1 / 1;
`;

const ThumbnailWrapper = styled(Link)`
	width: 100%;
	height: 100%;

	display: flex;
	align-items: center;
	justify-content: center;
	aspect-ratio: 1 / 1;
`;

const Thumbnail = styled(Card.Img)`
	object-fit: cover;
	width: 100%;
	height: 100%;

	&.fallback {
		background-color: #000;
		aspect-ratio: 1 / 1;
		padding-inline: 1rem;
		object-fit: contain;
	}
`;

const ProjectThumbnail = ({ uuid, ...props }) => {
	const { data, isLoading } = useQuery({
		queryKey: ['project_thumbnail', uuid],
		queryFn: () =>
			fetchUrl(`filelink?key=${uuid}/odm_orthophoto/thumbnail.png`),
		enabled: !!uuid,
	});

	if (isLoading) {
		return (
			<ThumbnailLoader {...props}>
				<Spinner animation="border" role="status">
					<span className="visually-hidden">Loading image</span>
				</Spinner>
			</ThumbnailLoader>
		);
	}

	return (
		<>
			<ThumbnailWrapper to={`/projects/${uuid}/`} {...props}>
				<Thumbnail
					variant="top"
					src={data?.code === 200 ? data.url : logo}
					className={data?.code === 200 ? '' : 'fallback'}
				/>
			</ThumbnailWrapper>
		</>
	);
};

export default ProjectThumbnail;
