import { useContext } from 'react';

import { UserContext } from '../../contexts/User.context';
import { getUserJobs } from '../../utils/api';

export default function useNotify() {
    const { currentUser, notifications, setNotifications } = useContext(UserContext);

    const sortyByProgressAndDate = (items) => {
        return items.sort((a, b) => {

            // Sort by descending created_at
            if (a.created_at > b.created_at) {
                return -1;
            }
            if (a.created_at < b.created_at) {
                return 1;
            }
            
            // If created_at is equal, sort by ascending progress
            if (a.progress > b.progress) {
                return 1;
            }
            if (a.progress < b.progress) {
                return -1;
            }
            
            // If progress is equal, sort by ascending uuid
            if (a.uuid > b.uuid) {
                return 1;
            }
            if (a.uuid < b.uuid) {
                return -1;
            }
            
            return 0; // Objects are equal
        });
    }

    const notify = (n) => {

        if(!Array.isArray(n)) {
            n = [n];
        }

        setNotifications(existing => {

            const merged = [...existing, ...n];

            // Filter out duplicates
            const filtered = merged.filter((obj, index, self) =>
                index === self.findIndex((o) => o.uuid === obj.uuid)
            );

            const sorted = sortyByProgressAndDate(filtered);

            const runningJobs = sorted.filter(item => item.job_status === "running");
            const otherJobs = sorted.filter(item => item.job_status !== "running");

            return [...runningJobs, ...otherJobs];
        });
    };

    const notifyUpdate = (item) => {
        // Find and update the existing notification with the new progress
        const updated = [...notifications].map(n => {
            
            if (n.uuid === item.uuid) {
                return item;
            }
            return n;
        });

        const sorted =  sortyByProgressAndDate([...updated]);
        setNotifications(sorted);
    }

    const checkForJobs = async (callback) => {
        /**
         * @description Get all jobs for user
         * @returns {Promise<Array>} Array of jobs
         */
        const getJobs = async () => {
            try{
                const jobs = [];
                await getUserJobs(currentUser.uuid).then(res => {
                    if(res?.length > 0) {
                        res.forEach(job => {
                            job.type = 'job';
                            jobs.push(job);
                        });
                    }
                });

                return jobs;
            } catch(e) {
                return [];
            }
        };

        const currentJobs = await getJobs();
        notify([...currentJobs]);

        if(callback) {
            callback();
        }

    }

    return { notify, notifyUpdate, checkForJobs };
}