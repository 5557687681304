import styled from 'styled-components';
import { useQuery } from '@tanstack/react-query';
import Dropdown from 'react-bootstrap/Dropdown';
import { AiOutlineCloudDownload } from 'react-icons/ai';

import { downloadFile } from '@utils/files';
import { fetchUrl } from '@api';

const ButtonContainer = styled.div`
	position: absolute;
	top: 8px;
	right: 8px;
	z-index: 2;
`;

const StyledDropdownItem = styled(Dropdown.Item)`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 10px;
`;

const Export = ({ exportData, annotationMode, project, isDemo }) => {
	const { uuid, title } = project;

	return (
		!annotationMode &&
		uuid && (
			<ButtonContainer>
				<Dropdown>
					<Dropdown.Toggle
						variant="success"
						id="export-downloads-toggle">
						Export
					</Dropdown.Toggle>

					<Dropdown.Menu variant="dark">
						<GeojsonExport
							exportData={exportData}
							title={title}
							disabled={!exportData || isDemo}
						/>
						<OrthophotoExport
							title={title}
							uuid={uuid}
							disabled={isDemo}
						/>
					</Dropdown.Menu>
				</Dropdown>
			</ButtonContainer>
		)
	);
};

export default Export;

const GeojsonExport = ({ exportData, title, disabled }) => {
	const geojsonData = {
		url: `data:text/json;charset=utf-8,${encodeURIComponent(
			JSON.stringify(exportData)
		)}`,
		title,
		fileExtension: 'geojson',
	};

	return (
		<StyledDropdownItem
			disabled={disabled}
			onClick={() => downloadFile(geojsonData)}>
			<AiOutlineCloudDownload /> <span>GeoJSON</span>
		</StyledDropdownItem>
	);
};

const OrthophotoExport = ({ title, uuid, disabled }) => {
	const { data } = useQuery({
		queryKey: ['project_orthophoto_signed_url', uuid],
		queryFn: () =>
			fetchUrl(`fileLink?key=${uuid}/odm_orthophoto/odm_orthophoto.tif`),
		enabled: !!uuid,
	});

	const orthophotoUrl = data?.url;
	const orthophotoData = {
		url: orthophotoUrl,
		title: `${title}_orthophoto`,
		fileExtension: 'tif',
	};

	return (
		<StyledDropdownItem
			disabled={!orthophotoUrl || disabled}
			onClick={() => downloadFile(orthophotoData)}>
			<AiOutlineCloudDownload /> <span>Orthophoto</span>
		</StyledDropdownItem>
	);
};
