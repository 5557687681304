
import { useQuery } from '@tanstack/react-query';

import { getDemoProjects } from '@utils/api';

import ProjectCard from '@components/projectComponents/ProjectCard';

const DemoProjects = ({wrapper: Wrapper = null}) => {

	const {data: demoProjects} = useQuery({
		queryKey: ["demo_projects"],
		queryFn: getDemoProjects,
		refetchOnWindowFocus: false,
	});

	if (!demoProjects || demoProjects?.length < 1 || !Wrapper ) return null;

	return (
		<Wrapper>
			<div className="row">
				{demoProjects.map((project, i) => (
					<div
						className="col-12 col-sm-6 col-md-4 col-lg-3"
						key={`${project.uuid}-${i}`}>
						<ProjectCard
							project={project}
							refetchProjects={() => {}} // No need to refetch demo projects
						/>
					</div>
				))}
			</div>
		</Wrapper>
	);
}
export default DemoProjects;
