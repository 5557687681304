import { api } from './index.js';

export const adminGetUsers = async ({
	offset = 0,
	limit = 100,
	search = '',
	tier = '',
	role = '',
	detailed = false,
} = {}) => {
	const params = {
		offset,
		limit,
		search,
		tier,
		role,
		detailed,
	};
	const { data } = await api.get('/admin/users', { params });
	return data;
};

export const adminGetProjects = async ({
	offset = 0,
	limit = 100,
	search = '',
	archived = false,
	dateRange,
	demo = false,
}) => {
	const params = {
		deleted: archived,
		search,
		offset,
		limit,
		demo,
	};

	if (dateRange?.from) params.start_date = dateRange.from;
	if (dateRange?.to) params.end_date = dateRange.to;

	const { data } = await api.get('/admin/projects', { params });
	return data;
};

export const adminVerifyEmail = async email => {
	try {
		const res = await api.put(`/admin/${email}/verify_email`);
		return res.data;
	} catch (err) {
		console.log(err);
		return err.response;
	}
};

export const adminApproveUser = async email => {
	try {
		const res = await api.put(`/admin/${email}/approve`);
		return res.data;
	} catch (err) {
		console.log(err);
		return err.response;
	}
};

export const adminUpdateUser = async (uuid, updateData) => {
	const { data } = await api.put(`/admin/users/${uuid}/update`, updateData);

	return data;
};

export const adminDeleteUser = async uuid => {
	const { data } = await api.delete(`/admin/users/${uuid}/delete`);
	return data;
};

export const getProjectResourceReport = async uuid => {
	try {
		const res = await api.get(`/admin/resource_report/${uuid}`);
		return res;
	} catch (err) {
		console.log(err);
		return err.response;
	}
};

export const adminGetProjectFilesDowloadUrl = async (
	project_uuid,
	path = 'images/',
	fileName = null
) => {
	let params = {
		path,
	};

	if (fileName) {
		params.file_name = fileName;
	}

	const { data } = await api.get(
		`/admin/get_project_files_urls/${project_uuid}`,
		{
			params,
		}
	);
	return data;
};

export const adminGetProjectJsonFile = async (
	project_uuid,
	path = 'log.json'
) => {
	let params = {
		path,
	};

	const { data } = await api.get(`/admin/project_json/${project_uuid}`, {
		params,
	});
	return data;
};

export const adminGetSagmakerFiles = async project_uuid => {
	const { data } = await api.get(
		`/admin/project_sagemaker_file_urls/${project_uuid}`
	);
	return data;
};

export const setDemoProject = async fields => {
	const { projectId: project_uuid, demoModel: demo_model_uuid } = fields;
	const { data } = await api.post(`/admin/demo_project`, {
		project_uuid,
		demo_model_uuid,
	});
	return data;
};

export const unsetDemoProject = async project_uuid => {
	const { data } = await api.post(
		`/admin/unset_demo_project/${project_uuid}`
	);
	return data;
};

// Not working correctly
// export const adminGetProjectImagesDowloadZip = async project_uuid => {
// 	const res = await api.get(
// 		`/admin/project_images_download_zip/${project_uuid}`,
// 		{
// 			headers: {
// 				'Content-Type': 'application/zip',
// 			},
// 		}
// 	);
// 	return res;
// };
