import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { convertToRgba, preventClickOnOverlay } from '@utils/map/helpers';
import { useProject } from '@contexts/Project.context';

const CarouselItem = styled.button`
	/* Remove default browser button styles */
	appearance: none;
	border: none;
	background: none;
	margin: 0;
	outline: none;

	max-width: 20rem;
	height: 100%;
	padding: 10px;
	position: relative;

	&:focus {
		outline: none;
	}
	&:hover {
		cursor: pointer;
		img {
			transform: scale(1.1);
		}
	}

	img {
		max-width: 100%;
		max-height: 100px;
		object-fit: contain;

		border-style: ${props => (props.isSelected ? 'solid' : 'none')};
		border-color: yellow;
		transform: scale(${props => (props.isSelected ? '1.15' : '1')});

		transition: all 0.1s ease-in-out;
	}
`;

const ClassesContainer = styled.div`
	position: absolute;
	top: 10px;
	left: 14px;
	width: 80%;
	height: 80%;
	z-index: 1;
	display: flex;
	flex-wrap: nowrap;
	font-size: 0.7rem;
	gap: 10px;
	color: white;
	text-shadow: 1px 1px 1px black;
`;
const ColorCircle = styled.div`
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background-color: ${props => props.color};
	display: inline-block;
	margin-right: 2px;
	box-shadow: 1px 1px 1px black;
`;

const NumberIndex = styled.div`
	position: absolute;
	bottom: 10px;
	left: 14px;
	font-size: 0.8rem;
	color: white;
	opacity: 0.8;
	text-shadow: 1px 1px 1px black;
`;

const SliderImage = ({ item, sliderRef, index }) => {
	const {
		filteredImages,
		selectedSingleImage,
		mapObject,
		colorScheme,
		dispatch,
	} = useProject();

	const [isSelected, setIsSelected] = useState(false);

	const handleImageClick = event => {
		event.preventDefault();
		dispatch({ type: 'setSelectedSingleImage', payload: item });
	};

	const handleMapClick = event => {
		// Prevent selection if the click happened on a an overlay
		if (!preventClickOnOverlay(event)) {
			dispatch({ type: 'setSelectedSingleImage', payload: null });
			setIsSelected(false);
		}
	};

	useEffect(() => {
		// For when a selectedImage can be set from outside the component

		if (!selectedSingleImage) {
			// If no image is selected, set isSelected to false and exit the function
			if (isSelected) {
				setIsSelected(false);
			}

			return;
		}

		// Get the name of the selected image
		const selectedImageName = selectedSingleImage.get('name');
		// Get the name of the current item
		const itemName = item.get('name');

		if (selectedImageName !== itemName) {
			// If the selected image name does not match the current item name, set isSelected to false and exit the function
			if (isSelected) {
				setIsSelected(false);
			}

			return;
		}

		// If the selected image name matches the current item name, set isSelected to true
		setIsSelected(true);

		// Find the index of the selected image in the items array
		const sliderIndex = filteredImages.findIndex(
			slide => slide.get('name') === selectedImageName
		);

		// Navigate to the corresponding slider index
		sliderRef.current.slickGoTo(sliderIndex);

		mapObject.on('click', handleMapClick);

		return () => {
			mapObject.un('click', handleMapClick);
		};
	}, [selectedSingleImage]);

	return (
		<CarouselItem
			onClick={handleImageClick}
			isSelected={isSelected}
			title={item.get('name')}>
			<DetectionHits
				detections={item.get('detections')}
				colorScheme={colorScheme}
			/>
			<img src={item.get('thumbnail')} alt={item.get('name')} />
			<NumberIndex>{index}</NumberIndex>
		</CarouselItem>
	);
};

export default SliderImage;

const DetectionHits = ({ detections, colorScheme }) => {
	if (!detections) return null;

	return (
		<ClassesContainer>
			{detections.classes.map((detection, index) => (
				<ClassItem
					key={index}
					detection={detection}
					color={colorScheme[detection.id]?.color}
				/>
			))}
		</ClassesContainer>
	);
};

const ClassItem = ({ detection, color }) => (
	<div>
		<ColorCircle color={convertToRgba(color)} />
		{detection.count}
	</div>
);
