import { useQuery } from '@tanstack/react-query';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

import { StyledModal } from '@components/modal/ModalStyle';
import { getProject, adminGetProjectJsonFile } from '@api';

const DetailsModal = ({ project_uuid, type, setType }) => {
	const { data, isLoading, isError, error } = useQuery({
		queryKey: ['details_modal', project_uuid, type],
		queryFn: () => {
			if (type === 'project') {
				return getProject(project_uuid);
			} else if (type === 'odm') {
				return adminGetProjectJsonFile(project_uuid, 'log.json');
			}
		},
		enabled: !!project_uuid,
		refetchOnWindowFocus: false,
	});

	return (
		<StyledModal
			variant="dark"
			show={!!type}
			centered={true}
			onHide={() => {
				setType(null);
			}}
			size="xl">
			<Modal.Header closeButton closeVariant="white">
				<Modal.Title>
					{type === 'project' && 'Project details'}
					{type === 'odm' && 'ODM log'}
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				{isLoading && <Spinner animation="border" variant="white" />}

				{!isLoading && isError && (
					<p>Error: {error?.response?.data?.detail ?? 'Unknown'}</p>
				)}

				{!isLoading && !isError && data && (
					<pre>{JSON.stringify(data, null, 2)}</pre>
				)}
			</Modal.Body>

			<Modal.Footer>
				<Button variant="secondary" onClick={() => setType(null)}>
					Close
				</Button>
			</Modal.Footer>
		</StyledModal>
	);
};
export default DetailsModal;
