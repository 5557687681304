import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { useQuery, useMutation } from '@tanstack/react-query';
import { useForm, Controller } from 'react-hook-form';
import debounce from 'lodash/debounce';

import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

import Select from '@components/form/Select';
import Alert from '@components/alert/Alert';

import {
	adminGetUsers,
	getOrganization,
	createOrganization,
	updateOrganization,
} from '@api';
import AdminView from '@components/layout/views/AdminView';
import OrganizationDetailsFormFields from '@components/forms/OrganizationDetailsFormFields';

const StyledActionsWrapper = styled.div`
	margin-top: calc(var(--bs-gutter-x) * 2);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const Organization = () => {
	const { organization_uuid } = useParams();
	const navigate = useNavigate();

	const [adminUsers, setAdminUsers] = useState([]);
	const [members, setMembers] = useState([]);
	const [saving, setSaving] = useState(false);

	const hasRun = useRef(false);

	const {
		isLoading,
		isError,
		data: organization,
		error,
	} = useQuery({
		queryKey: ['organization', organization_uuid],
		queryFn: () => getOrganization(organization_uuid),
		enabled: !!organization_uuid,
	});

	const mutationGetUsers = useMutation({
		mutationFn: input => {
			return adminGetUsers({ search: input });
		},
	});

	const loadOptions = debounce(async (inputValue, callback, existing) => {
		const data = await mutationGetUsers.mutateAsync(inputValue);
		const users =
			data?.users?.map(user => ({
				value: user,
				label: user.email,
			})) ?? [];

		// Remove existing users from the list
		const filteredUsers = users?.filter(
			user =>
				!existing.some(
					existingUser => existingUser.value.uuid === user.value.uuid
				)
		);

		callback(filteredUsers);
	}, 300);

	const mutationCreate = useMutation({
		mutationFn: org => {
			return createOrganization(org);
		},
		onSuccess: () => {
			navigate(-1);
		},
		onSettled: () => {
			setSaving(false);
		},
	});

	const mutationUpdate = useMutation({
		mutationFn: updatedOrg => {
			return updateOrganization(updatedOrg);
		},
		onSettled: () => {
			setSaving(false);
		},
	});

	const handleAdminSelectChange = selected => {
		setAdminUsers(selected);

		setMembers(
			members.filter(
				member =>
					!selected.some(
						selectedUser =>
							selectedUser.value.uuid === member.value.uuid
					)
			)
		);
	};

	const { control, handleSubmit } = useForm({
		defaultValues: {
			name: organization?.name || '',
			description: organization?.description || '',
			org_no: organization?.org_no || '',
			address: organization?.address || '',
			postal_code: organization?.postal_code || '',
			city: organization?.city || '',
			country: organization?.country || '',
			project_tags: organization?.project_tags || [],
		},
		values: organization,
	});

	const onSubmit = async data => {
		console.log('data', data);

		setSaving(true);

		// Clear members array
		data.members = [];

		// Add admin users to members array
		adminUsers?.forEach(m => {
			data.members.push({
				user_uuid: m.value.uuid,
				role: 'admin',
				access: [],
			});
		});

		// Add regular users to members array
		members?.forEach(m => {
			data.members.push({
				user_uuid: m.value.uuid,
				role: 'user',
				access: m.value.access ?? ['create'],
			});
		});

		// If data.org_no or data.postal_code is empty, set it to null, because the API expects null
		if (data.org_no === '') data.org_no = null;
		if (data.postal_code === '') data.postal_code = null;

		if (data.uuid) {
			mutationUpdate.mutate(data);
		} else {
			mutationCreate.mutate(data);
		}
	};

	useEffect(() => {
		if (!organization || hasRun.current) return;

		hasRun.current = true;

		console.log('setting org details', organization);

		if (organization?.members?.length > 0) {
			// Set members already in the organization
			setMembers(
				organization.members
					.filter(m => m.role === 'user')
					.map(m => ({
						value: {
							...m,
							uuid: m.user_uuid,
						},
						label: m.user_email,
					}))
			);

			// Set admin users already in the organization
			setAdminUsers(
				organization?.members
					.filter(m => m.role === 'admin')
					.map(m => ({
						value: {
							...m,
							uuid: m.user_uuid,
						},
						label: m.user_email,
					}))
			);
		}
	}, [organization]);

	if (isError)
		return (
			<AdminView title="Error loading organization">
				<Alert variant="danger">
					<p>
						Organization could not be loaded. Error:{' '}
						{error?.response?.data?.detail}
					</p>
				</Alert>
			</AdminView>
		);

	return (
		<AdminView
			title={
				organization?.name
					? `Organization: ${organization?.name}`
					: 'Create organization'
			}>
			{mutationCreate.isError && (
				<Alert variant="danger">
					Could not create organization. Error:{' '}
					{mutationCreate.error?.message}
				</Alert>
			)}

			{mutationUpdate.isError && (
				<Alert variant="danger">
					Could not update organization. Error:{' '}
					{mutationUpdate.error?.message}
				</Alert>
			)}

			{mutationUpdate.isSuccess && (
				<Alert variant="success">
					Organization successfully updated!
				</Alert>
			)}

			<form onSubmit={handleSubmit(onSubmit)}>
				<OrganizationDetailsFormFields control={control} />

				<div className="pb-4">
					<Select
						id="organization-admin-select"
						label="Organization admin"
						value={adminUsers}
						onChange={handleAdminSelectChange}
						loadOptions={(inputValue, callback) =>
							loadOptions(inputValue, callback, [...adminUsers])
						}
						noOptionsMessage={() => 'Start typing to search users'}
						isMulti
						async
						hideSelectedOptions
					/>

					<Select
						id="organization-members-select"
						label="Members"
						value={members}
						onChange={setMembers}
						loadOptions={(inputValue, callback) =>
							loadOptions(inputValue, callback, [
								...members,
								...adminUsers,
							])
						}
						noOptionsMessage={() => 'Start typing to search users'}
						isMulti
						async
						hideSelectedOptions
					/>
				</div>

				<Controller
					name="project_tags"
					control={control}
					render={({ field }) => (
						<Select
							{...field}
							value={field.value?.map(t => ({
								value: t,
								label: t,
							}))}
							onChange={e => {
								field.onChange(e.map(tag => tag.label));
							}}
							id="organization-tags"
							label="Project tags"
							noOptionsMessage={() => 'Start typing to add a tag'}
							helperText="By assigning project tags, users in this organization will be limited to add only these tags to their projects"
							isMulti
							creatable
						/>
					)}
				/>

				<StyledActionsWrapper>
					<div className="d-flex w-100 gap-2 justify-content-between align-content-center">
						<div>
							<Button
								type="button"
								variant="secondary"
								onClick={() => navigate(-1)}
								disabled={isLoading}>
								Back
							</Button>

							<Button
								type="submit"
								variant="success"
								className="ms-2"
								disabled={
									(isLoading && organization_uuid) || saving
								}>
								{organization?.name
									? saving
										? 'Saving...'
										: 'Save'
									: saving
									? 'Creating...'
									: 'Create'}
							</Button>
						</div>
						<div>
							<Link
								to={`/organization/${organization_uuid}/profile`}>
								View org. admin profile
							</Link>
						</div>
					</div>
				</StyledActionsWrapper>
			</form>
		</AdminView>
	);
};

export default Organization;
