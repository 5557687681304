import { Card } from "react-bootstrap";
import Results from "./filterBox/parts/Results";

const Summary = ({
	totalHexagons,
	filterBoxes,
	noMatchHexagons
}) => {

	if (!filterBoxes || filterBoxes.length === 0) {
		return null;
	}

	return (
		<div>
			<p className="mb-0">SUMMARY</p>
			<p className="small text-muted mb-1">Please note that selected filters may overlap</p>
			<Card body>
				<ul className="list-group list-group-flush">
					{filterBoxes.map((box, index) => (
						<li className="list-group-item px-0" key={`summary-box-${index}`}>
							<Results
								data={box}
								label={box.label || 'Unnamed label'}
								totalHexagons={totalHexagons}
								colorDot
							/>
						</li>
					))}

					{noMatchHexagons && (
						<li className="list-group-item px-0 mt-4">
							<Results
								data={noMatchHexagons}
								label="No filter match"
								totalHexagons={totalHexagons}
								showCalculation={false}
								colorDot
							/>
						</li>
					)}
				</ul>
			</Card>
		</div>
	);
};
export default Summary;
