import View from '@components/layout/views/View';

const Subscribe = () => {
	return (
		<View title="Pro Subscription">
			<div className="pb-4">
				<p>Hey there!</p>
				<p>
					Like what you see and want to get more involved?
					<br />
					<a href="mailto:post@biodrone.no?subject=Pro User Inquiry Biodrone Portal">
						Shoot us a request to post@biodrone.no
					</a>{' '}
					for upgrading to a pro user status, which will grant you the
					ability to have a subscription.
				</p>
				<p>
					Got questions or want more info? Just{' '}
					<a href="mailto:post@biodrone.no">
						drop us an email at post@biodrone.no
					</a>
					.<br />
					We're here to help and super excited that you're into what
					we've got going on!
				</p>
			</div>
		</View>
	);
};

export default Subscribe;
