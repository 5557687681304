import { Controller } from 'react-hook-form';

import Input from '@components/form/Input';

const OrganizationDetailsFormFields = ({ control }) => (
	<>
		<div className="pb-4">
			<Controller
				name="name"
				control={control}
				render={({ field }) => (
					<Input
						{...field}
						value={field.value || ''}
						type="text"
						label="Name"
						required
					/>
				)}
			/>

			<Controller
				name="description"
				control={control}
				render={({ field }) => (
					<Input
						{...field}
						value={field.value || ''}
						type="text"
						label="Description"
					/>
				)}
			/>
		</div>

		<div className="pb-4">
			<Controller
				name="org_no"
				control={control}
				render={({ field }) => (
					<Input
						{...field}
						value={field.value || ''}
						type="number"
						label="Organization number"
					/>
				)}
			/>

			<Controller
				name="address"
				control={control}
				render={({ field }) => (
					<Input
						{...field}
						type="text"
						value={field.value || ''}
						label="Address"
					/>
				)}
			/>

			<Controller
				name="postal_code"
				control={control}
				render={({ field }) => (
					<Input
						{...field}
						value={field.value || ''}
						type="text"
						label="Postal code"
					/>
				)}
			/>

			<Controller
				name="city"
				control={control}
				render={({ field }) => (
					<Input
						{...field}
						value={field.value || ''}
						type="text"
						label="City"
					/>
				)}
			/>

			<Controller
				name="country"
				control={control}
				render={({ field }) => (
					<Input
						{...field}
						value={field.value || ''}
						type="text"
						label="Country"
					/>
				)}
			/>
		</div>
	</>
);

export default OrganizationDetailsFormFields;
