import AdminView from '@components/layout/views/AdminView';
import AdminProjects from '@routes/adminRoutes/projects/components/Projects';

/**
 * Shows a admin table of projects with various filters
 */
const Projects = () => {
	return (
		<AdminView title="Projects">
			<AdminProjects />
		</AdminView>
	);
};
export default Projects;
