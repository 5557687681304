import { useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { Button } from 'react-bootstrap';

import { verifyUserEmail } from '@utils/api';

import View from '@components/layout/views/View';
import Loader from '@components/common/Loader';
import Alert from '@components/alert/Alert';

export default function VerifyUser() {
	const [searchParams] = useSearchParams();
	const email = searchParams.get('email');
	const token = searchParams.get('token');

	if (!email || !token) {
		return (
			<View maxWidth={550} title="Email verification">
				<VerifyUserMissingParams email={email} token={token} />
			</View>
		);
	}

	return (
		<View maxWidth={550} title="Email verification">
			<VerifyUserWithEmailAndToken email={email} token={token} />
		</View>
	);
}

function VerifyUserWithEmailAndToken({ email, token }) {
	const mutation = useMutation(() => verifyUserEmail(email, token), {
		retry: 1,
	});

	if (mutation?.isLoading) {
		return <Loader />;
	}

	if (mutation?.isError) {
		return <VerifyUserError error={mutation?.error} />;
	}

	if (mutation?.isSuccess) {
		return <VerifyUserSuccess data={mutation?.data} />;
	}

	return <VerifyUserForm email={email} mutation={mutation} />;
}

function VerifyUserMissingParams({ email, token }) {
	let missingParams = '';

	if (!email && !token) {
		missingParams = 'token and email';
	} else if (!email) {
		missingParams = 'email';
	} else if (!token) {
		missingParams = 'token';
	}

	return (
		<Alert variant="danger">
			Could not verify email. The url is missing {missingParams}.
		</Alert>
	);
}

function VerifyUserForm({ mutation, email }) {
	const verifyEmail = () => {
		mutation.mutate();
	};

	return (
		<>
			<p>
				Please verify your email address <em>{email}</em> by clicking
				the button below.
			</p>
			<Button
				variant="success"
				onClick={verifyEmail}
				disabled={mutation.isLoading}>
				Verify email
			</Button>
		</>
	);
}

function VerifyUserError({ error }) {
	console.error('Could not verify user', error);

	return (
		<Alert variant="danger">
			Could not verify email. Invalid token or email.
		</Alert>
	);
}

function VerifyUserSuccess({ data }) {
	const navigate = useNavigate();

	console.info('Server response', data);

	useEffect(() => {
		setTimeout(() => {
			navigate('/login');
		}, 5000);
	}, []);

	return (
		<Alert variant="success">
			<p className="mb-0">
				Email verified successfully.
				<br /> <Link to={'/login'}>Click here to log in</Link> or wait
				to be redirected.
			</p>
		</Alert>
	);
}
