import { useState, useEffect } from 'react';
import styled from 'styled-components';

import {
	show as showIntercom,
	hide as hideIntercom,
	onHide as onIntercomHide,
	onShow as onIntercomShow,
} from '@intercom/messenger-js-sdk';
import Button from 'react-bootstrap/Button';
import { BsFillChatDotsFill } from 'react-icons/bs';

const INTERCOM_APP_ID = import.meta.env.VITE_APP_INTERCOM_APP_ID;

const StyledChatbotToggle = styled(Button)`
	&[aria-pressed='true'] {
		background-color: var(--bs-btn-hover-bg);
	}
`;

// Possible Intercom Methods -> https://developers.intercom.com/installing-intercom/web/methods

const ChatbotToggle = () => {
	const [isOpen, setIsOpen] = useState(false);
	const [isCookieSet, setIsCookieSet] = useState(false);

	// Function to check if the cookie is set / Intercom is initialized
	const checkCookie = (attempts = 5) => {
		const cookieName = `intercom-device-id-${INTERCOM_APP_ID}`;
		const cookieExists = document.cookie
			.split(';')
			.some(cookie => cookie.trim().startsWith(`${cookieName}=`));

		if (cookieExists) {
			setIsCookieSet(true);
		} else if (attempts > 0) {
			// If the cookie is not set, check again in 500ms
			setTimeout(() => checkCookie(attempts - 1), 500);
		} else {
			setIsCookieSet(false);
		}
	};

	useEffect(() => {
		checkCookie();
	}, []);

	// If no cookie is set, do not render the chatbot toggle button, because Intercom will not work
	if (!isCookieSet) return;

	onIntercomHide(() => setIsOpen(false));
	onIntercomShow(() => setIsOpen(true));
	const toggleIntercom = () => (isOpen ? hideIntercom() : showIntercom());

	return (
		<StyledChatbotToggle
			variant="dark"
			title="Support"
			onClick={toggleIntercom}
			aria-pressed={isOpen}>
			<BsFillChatDotsFill />
		</StyledChatbotToggle>
	);
};

export default ChatbotToggle;
