import { useMemo } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';

import { useSearchParams } from 'react-router-dom';

import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';

import Table from './ProjectsTable';

import { adminGetProjects } from '@api';
import { formatAreaNumber } from '@utils/helpers';

import { FooterText } from '@routes/adminRoutes/sharedComponents/SharedStyledComponents';
import Loader from '@components/common/Loader';
import ProjectsFilterAdmin from '@components/projectComponents/projectFilters/ProjectsFilterAdmin';

/**
 * Shows a admin table of projects with various filters
 */
const Projects = ({ demo = false }) => {
	const [searchParams] = useSearchParams();

	const fetchAdminProjects = async ({ pageParam = 0 }) => {
		const search = searchParams.get('search');
		const archived = searchParams.get('showArchived');
		const range = searchParams.get('dateRange');

		return await adminGetProjects({
			offset: pageParam ?? 0,
			limit: 50,
			search,
			archived,
			dateRange: range ? JSON.parse(range) : null,
			demo,
		});
	};

	const mainQueryKey = demo ? 'admin_demo_projects' : 'admin_projects';

	const {
		data,
		error,
		fetchNextPage,
		hasNextPage,
		isFetching,
		isFetchingNextPage,
	} = useInfiniteQuery({
		queryKey: [mainQueryKey, searchParams.toString()],
		queryFn: fetchAdminProjects,
		getNextPageParam: (lastPage, allPages) => {
			if (Math.ceil(lastPage.total / lastPage.limit) > allPages.length) {
				return lastPage.offset + lastPage.projects?.length;
			}
			return undefined;
		},
		refetchOnWindowFocus: false,
	});

	const projects = data?.pages?.flatMap(page => page.projects) ?? [];
	const total = data?.pages?.[data.pages.length - 1]?.total ?? 0;

	const [totalArea] = useMemo(() => {
		let totalArea = 0;
		projects.forEach(project => {
			//If area is not -1, add it to the total
			if (project.area !== -1) {
				totalArea += project.area;
			}
		});
		return [totalArea];
	}, [data]);

	return (
		<>
			<ProjectsFilterAdmin loading={isFetching} />

			<div className="position-relative">
				{isFetching && <Loader />}

				{!isFetching && error && (
					<div className="alert alert-danger" role="alert">
						Something went wrong: {error.message ?? 'Unknown error'}
					</div>
				)}

				{!isFetching && !error && projects?.length === 0 && (
					<div>No projects found</div>
				)}

				{projects?.length > 0 && (
					<>
						<Table data={projects} loading={isFetching} />

						<footer className="col-md-12 mb-5">
							<div className="d-flex justify-content-between mb-4">
								<FooterText>
									Total area for selected filters:{' '}
									<span>{formatAreaNumber(totalArea)}</span>
								</FooterText>

								<FooterText>
									Showing {projects?.length} of {total}
								</FooterText>
							</div>

							{projects?.length < total && (
								<div className="text-center">
									<Button
										variant="success"
										className="mt-2 mb-2 col-6"
										disabled={
											!hasNextPage || isFetchingNextPage
										}
										onClick={() => fetchNextPage()}>
										<span>Load more</span>{' '}
										{isFetchingNextPage && (
											<Spinner
												animation="border"
												variant="light"
												size="sm"
											/>
										)}
									</Button>
								</div>
							)}
						</footer>
					</>
				)}
			</div>
		</>
	);
};
export default Projects;
