/**
 * Convert bytes to a human-friendly file size representation.
 *
 * @param {number} bytes - The number of bytes to convert.
 * @returns {string} Human-friendly file size representation.
 */
export function humanFileSize(bytes) {
	if (bytes == 0) return '0 Bytes';

	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
	const i = Math.floor(Math.log(bytes) / Math.log(1024));

	return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
}

/**
 * Convert bytes to the specified unit.
 * Using binary conversion (1 KB = 1024 bytes).
 *
 * @param {number} bytes - The number of bytes to convert.
 * @param {string} unit - The unit to convert to (e.g., 'GB', 'TB').
 * @returns {number} The size converted to the specified unit (rounded to 2 decimal places).
 * @throws {Error} If an invalid unit is specified.
 */
export const convertBytes = (bytes, unit = 'GB') => {
	// If bytes is not a number, return undefined
	if (isNaN(bytes)) {
		console.error('Invalid bytes. Please provide a valid number.');
		return undefined;
	}

	// Define conversion factors for various units
	const units = {
		B: Math.pow(2, 0),
		KB: Math.pow(2, 10),
		MB: Math.pow(2, 20),
		GB: Math.pow(2, 30),
		TB: Math.pow(2, 40),
		PB: Math.pow(2, 50),
		EB: Math.pow(2, 60),
		ZB: Math.pow(2, 70),
		YB: Math.pow(2, 80),
	};

	// Check if the specified unit is supported
	if (units[unit.toUpperCase()] === undefined) {
		throw new Error(
			'Invalid unit specified. Supported units are B, KB, MB, GB, TB, PB, EB, ZB, and YB.'
		);
	}

	// Convert bytes to the specified unit
	const converted = bytes / units[unit.toUpperCase()];

	// Return the converted size rounded to 2 decimal places
	return parseFloat(converted.toFixed(2));
};

// Function to download a file from a URL
export const downloadFile = ({ url, title, fileExtension }) => {
	if (!url || !title || !fileExtension) {
		console.error(
			'Invalid parameters. Please provide a valid URL, title, and file extension.'
		);
		return;
	}

	// Sanitize the project title by replacing special characters and spaces with underscores
	const sanitizedTitle = title.replace(/[^a-zA-Z0-9]/g, '_');

	// Create a new link element
	const link = document.createElement('a');

	// Set the href of the link to the object URL
	link.href = url;

	// Set the download attribute of the link to the sanitized title and file ending
	link.download = `${sanitizedTitle}.${fileExtension}`;

	// Append the link to the body
	document.body.appendChild(link);

	// Click the link to start the download
	link.click();

	// Remove the link from the body
	document.body.removeChild(link);
};
