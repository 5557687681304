import styled from 'styled-components';
import { AiOutlineRight } from 'react-icons/ai';

import SidebarTemplate from '../sidebarElements/SidebarTemplate';
import LabelButton from '../sidebarElements/LabelButton';
import HexagonAccordion from '../sidebarElements/HexagonAccordion';

import { formatAreaNumber } from '@utils/helpers';
import { useProject } from '@contexts/Project.context';
const StyledLabelButton = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	column-gap: 15px;

	svg {
		margin-top: 3px;
	}
`;

const Title = styled.div`
	font-size: 1rem;
	font-weight: 600;
	margin-bottom: 10px;
`;

const LabelDetails = styled.div`
	font-size: 0.9em;
	font-weight: normal;
	line-height: 1.1;
	p {
		margin-bottom: 10px;
		display: flex;
		flex-direction: column;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
`;

const Name = styled.span`
	text-transform: capitalize;
	color: ${p => p.theme.colors.textAccent};
	display: block;
`;

const Menu = styled.div`
	color: ${props => props.theme.colors.textColor};
	padding-top: 10px;
	padding-bottom: 20px;
`;

const DetailsName = styled.span`
	text-transform: capitalize;
	display: block;
`;

const DetailsList = styled.ul`
	list-style: none;
	padding: 0;
	margin: 0;

	font-weight: normal;
	line-height: 1.2;
	color: ${p => p.theme.colors.textColor};

	display: grid;
	grid-template-columns: auto auto;
	justify-content: space-between;

	margin-top: 4px;
	row-gap: 4px;

	li {
		display: grid;
		grid-template-columns: subgrid;
		grid-column: 1/-1;

		span:first-child {
			text-transform: capitalize;
			color: ${p => p.theme.colors.textAccent};
		}
	}
`;

const sidebarId = 'hexagonSummary';

const HexagonSummarySidebar = () => {
	const { hexagonInfo } = useProject();

	const hexagonsSummary = hexagonInfo?.metadata?.total;

	if (!hexagonsSummary?.detections) return;

	return (
		<SidebarTemplate
			index={2}
			sidebarId={sidebarId}
			header="Hexagons total summary"
			closeable={true}>
			<Menu>
				<HexagonAccordion hexagonData={hexagonsSummary} />
			</Menu>
		</SidebarTemplate>
	);
};
export default HexagonSummarySidebar;

export const HexagonSummaryPreview = () => {
	const { setActiveSidebar, hexagonInfo } = useProject();

	if (!hexagonInfo) return null;

	const hexagonsSummary = hexagonInfo?.metadata?.total;

	const { metadata: { count, area } = {}, detections = [] } =
		hexagonsSummary || {};

	return (
		<div>
			<hr className="mt-0" />

			<StyledLabelButton
				as={LabelButton}
				handleClick={() => {
					setActiveSidebar({ sidebarId, parentId: 'layerView' });
				}}>
				<Title>Hexagons Summary</Title>

				<AiOutlineRight />
			</StyledLabelButton>

			<LabelDetails>
				{count && (
					<p>
						<Name>Objects in total</Name>{' '}
						<span>{formatAreaNumber(count)}</span>
					</p>
				)}

				{area && (
					<p>
						<Name>Area</Name>{' '}
						<span>{formatAreaNumber(area)} m²</span>
					</p>
				)}

				{detections &&
					Object.keys(detections).map(key => (
						<DetectionDetail
							key={`hexagon-detail-${key}`}
							name={key}
							detection={detections[key]}
							totalCount={count}
						/>
					))}
			</LabelDetails>
		</div>
	);
};

const DetectionDetail = ({ name, detection, totalCount }) => {
	const { sum_object_area, mean_height, count } = detection;

	const percentOfTotal = Math.round((count / totalCount) * 100);

	return (
		<div className="mt-3">
			<DetailsName>
				{name} ({percentOfTotal}%)
			</DetailsName>
			<DetailsList>
				<li>
					<span>Count</span> <span>{formatAreaNumber(count)}</span>
				</li>
				{sum_object_area && sum_object_area != 0 && (
					<li>
						<span>Sum Object Area</span>{' '}
						<span>{formatAreaNumber(sum_object_area)} m²</span>
					</li>
				)}
				<li>
					<span>Mean Height of Objects</span>{' '}
					<span>
						{mean_height != 0 ? <>{mean_height} m</> : <>-</>}
					</span>
				</li>
			</DetailsList>
		</div>
	);
};
