import { useState } from 'react';
import { Button } from 'react-bootstrap';

import { useProject } from '@contexts/Project.context';
import PDFModal from './PDFModal';

const formatNumber = (num) => {
	return new Intl.NumberFormat().format(num);
}

const PDFExportButton = ({ filterBoxes, hexagonSize }) => {
	const { mapObject: map, extent, project } =
		useProject();

	const [content, setContent] = useState({});
	const [showModal, setShowModal] = useState(false);

	const handleExport = async () => {

		setShowModal(true);

		const size = map.getSize();
		const mapCanvas = document.createElement('canvas');
		mapCanvas.width = size[0];
		mapCanvas.height = size[1];

		// Fit the view to the extent
		map.getView().fit(extent);

		// Wait for the map to render
		await new Promise((resolve) => {
			map.once('rendercomplete', resolve);
		});

		//zoom out one level to make sure all is visible
		const currentZoom = map.getView().getZoom();
		map.getView().setZoom(currentZoom - 1);

		// Wait for the map to render
		await new Promise((resolve) => {
			map.once('rendercomplete', resolve);
		});

		const mapContext = mapCanvas.getContext('2d');
		Array.prototype.forEach.call(
			document.querySelectorAll('.ol-layer canvas'),
			function (canvas) {
			if (canvas.width > 0) {
				const opacity = canvas.parentNode.style.opacity;
				mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
				const transform = canvas.style.transform;
				// Get the transform parameters from the style's transform matrix
				const matrix = transform
				.match(/^matrix\(([^\(]*)\)$/)[1]
				.split(',')
				.map(Number);
					// Apply the transform to the export map context
					CanvasRenderingContext2D.prototype.setTransform.apply(
					mapContext,
					matrix,
				);
				mapContext.drawImage(canvas, 0, 0);
			}
		});

		mapContext.globalAlpha = 1;
		mapContext.setTransform(1, 0, 0, 1, 0, 0);
		const mapImageUrl = mapCanvas.toDataURL('image/jpeg');

		setContent({
			title: project?.title || 'Map',
			filters: filterBoxes.map((filterBox) => ({
				label: filterBox.label || 'No label',
				color: filterBox.color,
				totalArea: formatNumber(filterBox.totalArea),
				calculation: formatNumber(filterBox.totalCalculation),
			})),
			hexagonSize: formatNumber(hexagonSize),
			mapImageUrl: mapImageUrl
		});

		// Remove the map canvas
		mapCanvas.remove();

	}

	return(
		<>
			<Button
				onClick={handleExport}
				variant="dark"
				size="sm"
			>
				Generate PDF
			</Button>

			<PDFModal
				show={showModal}
				onHide={() => setShowModal(false)}
				content={content}
			/>

		</>
    );
};
export default PDFExportButton;
