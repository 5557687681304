import { Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';

import ListItem from './ListItem';

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: minmax(22rem, auto);
	align-content: start;
	justify-items: center;
	row-gap: 1rem;

	min-width: min-content;
	height: 100%;

	background: ${props => props.theme.colors.background};

	overflow-y: auto;
	overflow-x: hidden;
`;

const StyledSpinner = styled(Spinner)`
	margin-inline: auto;
`;

const NoProjectsMessages = styled.p`
	text-align: center;
	font-size: 1.2rem;
	margin: 0;
`;

const ListComponent = ({
	projectsData,
	totalProjects,
	map,
	projectDataQuery,
}) => {
	const { isLoading, isFetchingNextPage, fetchNextPage } = projectDataQuery;
	return (
		<Wrapper>
			{isLoading ? (
				<StyledSpinner animation="border" role="status">
					<span className="visually-hidden">Loading...</span>
				</StyledSpinner>
			) : projectsData?.length ? (
				<>
					{projectsData.map(projectData => (
						<ListItem
							projectData={projectData}
							map={map}
							key={projectData.uuid}
						/>
					))}
					{totalProjects > projectsData.length && (
						<Button
							variant="success"
							className="mt-2 mb-2 col-6"
							disabled={isFetchingNextPage}
							onClick={fetchNextPage}>
							Load More
							{isFetchingNextPage && (
								<Spinner
									animation="border"
									variant="light"
									size="sm"
									className="ms-2"
								/>
							)}
						</Button>
					)}
				</>
			) : (
				<NoProjectsMessages>No Projects Found</NoProjectsMessages>
			)}
		</Wrapper>
	);
};
export default ListComponent;
