import styled from 'styled-components';

const ColorDot = styled.span`
	display: inline-block;
	border-radius: 50%;
	width: 12px;
	height: 12px;
	border: 1px solid #fff;
	background-color: #fff;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill-opacity='.05'%3E%3Cpath d='M8 0h8v8H8zM0 8h8v8H0z'/%3E%3C/svg%3E");
	position: relative;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: ${({ color }) => color};
		border-radius: 50%;
	}
`;

const Results = ({
	data,
	label,
	colorDot = false,
	showCalculation = true
}) => {

	if(!data) return null;

	const formatNumber = (num) => {
		return new Intl.NumberFormat().format(num);
	}

	const color = data?.color;

	return(
		<div>
			<div className="text-muted small d-flex flex-row gap-2 align-items-center">
				{colorDot && <ColorDot color={`rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`} />}
				<span>{label ?? 'RESULTS'}</span>
			</div>

			<ul className="p-0 m-0">
				<li className="d-flex justify-content-between mb-1 text-end lh-sm">
					<span className="text-muted">Area</span>
					<span>{formatNumber(data?.totalArea)} m²<br/><span className="text-muted small">{data?.areaPercentage}%</span></span>

				</li>
				{showCalculation && (
					<li className="d-flex justify-content-between">
						<span className="text-muted">Calculation</span>
						<span>{formatNumber(data?.totalCalculation)}</span>
					</li>
				)}
			</ul>
		</div>
	);
}
export default Results;
