import { useEffect, useMemo, useState } from 'react';
import Form from 'react-bootstrap/Form';
import styled from 'styled-components';

import { useProject } from '@contexts/Project.context';
import {
	getHexagonOpacity,
	hexagonStyle,
	hiddenHexagonStyle,
} from '@utils/map/hexagon.layer';
import HistogramRangeSlider from '@components/histogramRangeSlider/RangeSliderWithHistorgram';
import HexagonSize from '@components/hexagonSize/HexagonSize';
import ModifyHexagon from './ModifyHexagon';

const FilterTab = styled.div`
	color: ${props => props.theme.colors.textColor};
	padding: 10px 20px 100px 20px;
	overflow-y: auto;
`;

export const FilterLabel = styled(Form.Label)`
	opacity: 0.7;
	font-size: 1rem;
	font-weight: 600;
	text-transform: capitalize;
	position: relative;
	top: 5px;
`;

const SizeGroup = styled(Form.Group)`
	margin-bottom: 45px;
`;


const FilterHexagon = ({ hexagonColor, setHexagonColor }) => {
	const { hexagonLayer } = useProject();

	const [hexagonFilters, setHexagonFilters] = useState({});

	// Setup data for histogram (count and height)
	const data = useMemo(() => {
		const features = hexagonLayer?.getSource()?.getFeatures();
		let updatedData = {};

		if(features) {
			features.forEach(feature => {
				const { detections } = feature.get('data') ?? {};

				Object.keys(detections).forEach(detectionType => {
					['height', 'count'].forEach(type => {
						const detectionLabel = detectionType + '_' + type;
						const value =
							type === 'height'
								? parseFloat(
										Number.isFinite(
											detections[detectionType]
												.mean_height
										)
											? detections[
													detectionType
											].mean_height.toFixed(1)
											: 0
								)
								: detections[detectionType].count;

						if (!updatedData[detectionLabel]) {
							updatedData[detectionLabel] = {
								data: [value],
								label: detectionType + ' ' + type,
							};
							return;
						}

						updatedData[detectionLabel].data.push(value);
					});
				});
			});

			// Calculate step size and min and max range values for the slider
			const steps = 6;
			const initialInputs = {};
			Object.keys(updatedData).forEach(detectionLabel => {
				let min = Math.min(...updatedData[detectionLabel].data);
				let max = Math.max(...updatedData[detectionLabel].data);
				if (detectionLabel.endsWith('_height')) {
					min = parseFloat(min.toFixed(1));
					max = parseFloat(max.toFixed(1));
				}
				if (!initialInputs[detectionLabel])
					initialInputs[detectionLabel] = {};

				initialInputs[detectionLabel] = {
					min,
					max,
				};
				let stepSize = parseFloat(Math.round((max - min) / steps));
				if (detectionLabel.endsWith('_height')) {
					stepSize = parseFloat(((max - min) / steps).toFixed(1));
				}
				updatedData[detectionLabel].dataset = [];
				updatedData[detectionLabel].labels = [];
				for (let i = 0; i < steps; i++) {
					let stepMin = min + i * stepSize;
					let stepMax = stepMin + stepSize;

					if (detectionLabel.endsWith('_height')) {
						stepMin = parseFloat(stepMin.toFixed(1));
						stepMax = parseFloat(stepMax.toFixed(1));
					}

					const count = updatedData[detectionLabel].data.filter(c => {
						const val = detectionLabel.endsWith('_height')
							? c.toFixed(1)
							: c;

						return !(val < stepMin || val > stepMax);
					}).length;

					updatedData[detectionLabel].dataset[i] = count || 0;
					updatedData[detectionLabel].labels[i] = stepMax;
				}
			});

			setHexagonFilters(initialInputs);
		}

		return updatedData;

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hexagonLayer]);

	useEffect(() => {
		if (Object.keys(hexagonFilters).length === 0) return;

		// Debounce with a delay of 100 milliseconds
		const timeoutId = setTimeout(() => {
			handleFilter(hexagonColor);
		}, 100);

		return () => {
			clearTimeout(timeoutId);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hexagonFilters]);

	// Update hexagon visibility
	const handleFilter = async hexColor => {
		const features = await hexagonLayer.getSource().getFeatures();

		features.forEach(feature => {
			let isVisibile = true;

			Object.keys(hexagonFilters).forEach(label => {
				const featureLabel = label
					.replace('_height', '')
					.replace('_count', '');
				const hexagonData =
					feature.values_.data.detections[featureLabel];

				// if no detections for the hexagon, skip
				if (!hexagonData) {
					return;
				}

				if (label.endsWith('_height')) {
					const hexagonHeight = parseFloat(
						Number.isFinite(hexagonData?.mean_height)
							? hexagonData?.mean_height.toFixed(1)
							: 0
					);
					const minHeight = hexagonFilters[label].min;
					const maxHeight = hexagonFilters[label].max;

					if (
						hexagonHeight < minHeight ||
						hexagonHeight > maxHeight
					) {
						isVisibile = false;
					}
					if (hexagonHeight === 0) isVisibile = true;
				} else if (label.endsWith('_count')) {
					const hexagonCount = hexagonData?.count || 0;
					const minCount = hexagonFilters[label].min;
					const maxCount = hexagonFilters[label].max;

					if (hexagonCount < minCount || hexagonCount > maxCount) {
						isVisibile = false;
					}
					if (hexagonCount === 0) isVisibile = true;
				}
			});

			if (isVisibile) {
				const heatIndicator =
					feature.getProperties().data.metadata.heatIndicator;
				const hexagonOpacity = getHexagonOpacity(heatIndicator);
				const style = hexagonStyle(hexColor.color, hexagonOpacity);
				feature.setStyle(style);
			} else {
				feature.setStyle(hiddenHexagonStyle);
			}
		});
	};

	return (
		<FilterTab>
			<SizeGroup>
				<HexagonSize />

				<ModifyHexagon
					setHexagonColor={value => {
						setHexagonColor(value);
						handleFilter(value);
					}}
					hexagonColor={hexagonColor}
				/>
			</SizeGroup>

			{Object.keys(data).map(key => {
				const detectionType = data[key];

				return (
					<div key={key}>
						{key.includes('height') && <hr className="my-5" />}

						<FilterLabel>{detectionType.label}</FilterLabel>
						<HistogramRangeSlider
							step={key.includes('height') ? 0.1 : 1}
							min={Math.min(...detectionType.data)}
							max={Math.max(...detectionType.data)}
							data={detectionType.dataset}
							labels={detectionType.labels}
							onChange={value => {
								setHexagonFilters(prev => ({
									...prev,
									[key]: { min: value[0], max: value[1] },
								}));
							}}
						/>
					</div>
				);
			})}
		</FilterTab>
	);
};

export default FilterHexagon;
