import styled from "styled-components";

export const KeyboardShortcut = styled.span`
    display: inline-block;
    margin-left: 6px;
    font-weight: 300;
`;

export const AnnotationToolBar = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;
