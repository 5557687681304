import styled from "styled-components";

const NoResultWrapper = styled.div`
    font-size: 0.9rem;
    margin: 0;
    opacity: 0.7;
    font-style: italic;
    text-transform: none;
`;

const NoResult = ({text = 'no analyzes found'}) => (<NoResultWrapper>{text}</NoResultWrapper>);
export default NoResult;