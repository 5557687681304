import styled from 'styled-components';

import { humanFileSize } from '@utils/files';

import StorageStatusProgress from '@components/storage/StorageStatusProgress';

const TypeColor = styled.span`
	background-color: ${p =>
		p.variant
			? `rgb(var(--bs-${p.variant}-rgb))`
			: `var(--bs-secondary-bg)`};
	width: 10px;
	height: 10px;
	border-radius: 50%;
	display: block;
	border: 1px solid rgba(255, 255, 255, 0.1);
`;

const ProgressDescription = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-top: 10px;
	line-height: 1em;
`;

const ProgressDescriptionItem = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
`;

export default function StorageStatus({
	projectFilesSize,
	usedStorage,
	totalStorage,
}) {
	if (!usedStorage && !totalStorage) return 'No storage status available.';

	return (
		<div>
			<StorageStatusProgress
				usedStorage={usedStorage}
				projectFilesSize={projectFilesSize}
				totalStorage={totalStorage}
			/>

			<ProgressDescription className="small">
				<ProgressDescriptionItem>
					<TypeColor variant="success" />
					<span>
						<strong>{humanFileSize(usedStorage)}</strong> used
					</span>
				</ProgressDescriptionItem>

				{projectFilesSize > 0 && (
					<ProgressDescriptionItem>
						<TypeColor variant="warning" />
						<span>
							<strong>~ {humanFileSize(projectFilesSize)}</strong>{' '}
							to be added
						</span>
					</ProgressDescriptionItem>
				)}

				<ProgressDescriptionItem>
					<TypeColor />
					<span>
						of <strong>{humanFileSize(totalStorage)}</strong> total
					</span>
				</ProgressDescriptionItem>
			</ProgressDescription>
		</div>
	);
}
