import { useRef, useState } from 'react';
import styled from 'styled-components';

import Slider from 'react-slick';
import { Tooltip } from 'react-tooltip';
import { AiOutlineFilter } from 'react-icons/ai';
import { BiSortAlt2 } from 'react-icons/bi';

import { useProject } from '@contexts/Project.context';

import SliderImage from './SliderImage';
import IndexSlider from './IndexSlider';
import SliderTool from './SliderTool';
import SortSingleImage from './SortSingleImage';
import FilterSingleImage from './FilterSingleImage';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Container = styled.div`
	position: absolute;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	z-index: 1;
	bottom: 36px;
	left: 0;
	width: 100%;
	background-color: ${props => props.theme.colors.background};
	padding-top: 5px;
`;

const CarouselContainer = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 100%;
	min-height: 85px;
	overflow: hidden;
	background-color: ${props => props.theme.colors.background};
`;

const CarouselItems = styled.div`
	width: 100%;
	margin-top: 0px;
	margin-bottom: 10px;
`;

const CarouselWrapper = styled.div`
	width: 100%;
	max-height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 10px;
	background-color: transparent;
	margin-bottom: 10px;
`;

const ToolsContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin-left: 38px;
	gap: 12px;
`;

export const StyledTooltip = styled(Tooltip)`
	z-index: 50;
`;

const IndexSliderContainer = styled.div`
	display: grid;
	grid-template-columns: 50px auto 50px;
	align-items: center;
	color: ${props => props.theme.colors.textAccent};
	font-size: 0.8rem;
`;
const Number = styled.div`
	text-align: center;
`;

const CarouselSlider = () => {
	const { singleImageFeatures, filteredImages } = useProject();

	let sliderRef = useRef(null);
	const [slideIndex, setSlideIndex] = useState([0]);
	const [slidesToShow, setSlidesToShow] = useState(0);

	const [tools, setTools] = useState([
		{ name: 'filtering', isActive: false },
		{ name: 'sorting', isActive: false },
	]);

	const hasDetections = singleImageFeatures?.some(
		item => item.get('detections')?.classes.length > 0
	);

	// Slides to show can not be more items.length because of bug in slick slider
	const slidesToShowLarge = Math.min(20, filteredImages?.length);
	const slidesToShowMedium = Math.min(10, filteredImages?.length);
	const slidesToShowSmall = Math.min(5, filteredImages?.length);

	const settings = {
		lazyLoad: 'ondemand',
		infinite: false,
		slidesToShow: slidesToShowLarge,
		slidesToScroll: slidesToShowLarge,
		centerPadding: '60px',
		initialSlide: 0,
		speed: 500,
		responsive: [
			{
				breakpoint: 2000,
				settings: {
					slidesToShow: slidesToShowMedium,
					slidesToScroll: slidesToShowMedium,
					beforeChange: (current, next) => {
						setSlideIndex([next]);
						setSlidesToShow(slidesToShowMedium);
					},
				},
			},
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: slidesToShowSmall,
					slidesToScroll: slidesToShowSmall,
					beforeChange: (current, next) => {
						setSlideIndex([next]);
						setSlidesToShow(slidesToShowSmall);
					},
				},
			},
		],
		beforeChange: (current, next) => {
			setSlideIndex([next]);
			setSlidesToShow(slidesToShowLarge);
		},
	};

	if (!singleImageFeatures?.length) {
		return null;
	}

	return (
		<Container>
			{hasDetections && (
				<>
					<StyledTooltip id="slider-tool-tooltip" variant="dark" />
					<ToolsContainer>
						<SliderTool
							tools={tools}
							setTools={setTools}
							toolId="sorting"
							toolName="Sort Images"
							tooltipId="slider-tool-tooltip"
							icon={<BiSortAlt2 />}>
							<SortSingleImage
								tools={tools}
								setTools={setTools}
							/>
						</SliderTool>
						<SliderTool
							tools={tools}
							setTools={setTools}
							toolId="filtering"
							toolName="Filter Images"
							tooltipId="slider-tool-tooltip"
							icon={<AiOutlineFilter />}>
							<FilterSingleImage
								tools={tools}
								setTools={setTools}
							/>
						</SliderTool>
					</ToolsContainer>
				</>
			)}
			<CarouselContainer>
				<CarouselWrapper>
					<CarouselItems>
						{filteredImages?.length > 0 && (
							<Slider
								ref={slider => {
									sliderRef.current = slider;
								}}
								{...settings}>
								{filteredImages?.map((item, index) => (
									<SliderImage
										key={item.get('name')}
										item={item}
										sliderRef={sliderRef}
										index={index + 1}
									/>
								))}
							</Slider>
						)}
					</CarouselItems>

					{filteredImages?.length > 0 && (
						<IndexSliderContainer>
							<Number>1</Number>
							<IndexSlider
								max={filteredImages.length - slidesToShow}
								sliderRef={sliderRef}
								slideIndex={slideIndex}
								setSlideIndex={setSlideIndex}
							/>
							<Number>{filteredImages.length}</Number>
						</IndexSliderContainer>
					)}
				</CarouselWrapper>
			</CarouselContainer>
		</Container>
	);
};

export default CarouselSlider;
