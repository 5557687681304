import { useState } from 'react';
import styled from 'styled-components';

import MapComponent from './map/Map';
import ListComponent from './list/List';
import Alert from '@components/alert/Alert';

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr 4fr;
	grid-template-rows: auto;
	grid-auto-flow: column;
	gap: 1rem;
	width: 100%;
	height: calc(${props => props.theme.utils.fullHeight} - 14.4rem);

	padding-bottom: 1rem;

	/* Layout Change for smaller than tablet size */
	@media (max-width: 991.98px) {
		grid-template-columns: 1fr;
		grid-template-rows: 2fr 3fr;
	}
`;

const ProjectsMapView = ({ projectsData, totalProjects, projectDataQuery }) => {
	const [map, setMap] = useState(null);

	const { error, isFetching } = projectDataQuery;

	if (error && !isFetching)
		return (
			<Alert heading={'An error occured:'} variant="danger">
				{error?.response?.data?.detail}
			</Alert>
		);

	return (
		<Wrapper>
			<ListComponent
				projectsData={projectsData}
				totalProjects={totalProjects}
				map={map}
				projectDataQuery={projectDataQuery}
			/>
			<MapComponent
				projectsData={projectsData}
				map={map}
				setMap={setMap}
				projectDataQuery={projectDataQuery}
			/>
		</Wrapper>
	);
};
export default ProjectsMapView;
