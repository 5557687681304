import _ from 'lodash';

import { useProject } from '@contexts/Project.context';
import PopoverPicker from '@components/popoverPicker/PopoverPicker';

const ModifyPoint = ({ id }) => {
	const { colorOptions, colorScheme, dispatch } = useProject();

	const defaultColor = colorScheme[id] && colorScheme[id].color
	? colorScheme[id].color
	: colorOptions[id];

	const debouncedDispatch = _.debounce((color) => {
		dispatch({
			type: 'setColorScheme',
			payload: {
				...colorScheme,
				[id]: {
					...colorScheme[id],
					color,
				},
			},
		});
	}, 300); // 300ms debounce time

	const changeColor = color => {
		debouncedDispatch(color);
	};

	return (
		<PopoverPicker color={defaultColor} onChange={val => {changeColor( val)}} popDirection="right" />
	);
};

export default ModifyPoint;
