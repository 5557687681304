import styled from 'styled-components';
import Badge from 'react-bootstrap/Badge';

const StyledBadgeUl = styled.ul`
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: ${p => p.align};
	gap: 0.2rem;
	width: 100%;
	li {
		display: inline-flex;
	}
`;

const ProjectTags = ({
	tags,
	align = 'flex-end',
	showNoResultText = false,
}) => {
	if (tags?.length === 0) {
		if (showNoResultText) {
			return (
				<p className="fs-6">
					<em>No tags added yet</em>
				</p>
			);
		}

		return null;
	}

	return (
		<StyledBadgeUl title="Project tags" align={align}>
			{tags.map((tag, index) => (
				<li key={`badge-${index}`}>
					<Badge bg="light" text="dark">
						{tag}
					</Badge>
				</li>
			))}
		</StyledBadgeUl>
	);
};

export default ProjectTags;
