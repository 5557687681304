import { useEffect } from 'react';

export default function useClickAway(ref, handleClickAway) {
	useEffect(() => {
		let startedInside = false;
		let startedWhenMounted = false;

		const listener = (event) => {
		  // Do nothing if `mousedown` or `touchstart` started inside ref element
		  if (startedInside || !startedWhenMounted) return;
		  // Do nothing if clicking ref's element or descendent elements
		  if (!ref.current || ref.current.contains(event.target)) return;

		  handleClickAway(event);
		};

		const validateEventStart = (event) => {
		  startedWhenMounted = ref.current;
		  startedInside = ref.current && ref.current.contains(event.target);
		};

		document.addEventListener("mousedown", validateEventStart);
		document.addEventListener("touchstart", validateEventStart);
		document.addEventListener("click", listener);

		return () => {
		  document.removeEventListener("mousedown", validateEventStart);
		  document.removeEventListener("touchstart", validateEventStart);
		  document.removeEventListener("click", listener);
		};
	  }, [ref, handleClickAway]);
}
