import Intercom from '@intercom/messenger-js-sdk';

import { useAuth } from '@contexts/User.context';
const INTERCOM_APP_ID = import.meta.env.VITE_APP_INTERCOM_APP_ID;

// Possible settings -> https://developers.intercom.com/installing-intercom/web/customization

const Chatbot = () => {
	const { currentUser } = useAuth();

	// Set Intercom settings
	let intercomSettings = {
		app_id: INTERCOM_APP_ID,
		hide_default_launcher: true,
		horizontal_padding: -25,
	};
	if (currentUser?.chatbot_hmac) {
		const logedInSettings = {
			user_id: currentUser.uuid,
			name: currentUser.name,
			email: currentUser.email,
			created_at: Math.floor(
				new Date(currentUser?.created_at).getTime() / 1000
			), // Convert totimestamp
			user_hash: currentUser.chatbot_hmac,
		};
		intercomSettings = { ...intercomSettings, ...logedInSettings };
	}

	// Initialize Intercom
	Intercom(intercomSettings);
};

export default Chatbot;
