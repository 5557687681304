import { forwardRef } from 'react';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';

import { FieldWrapper } from './CommonStyles';

const StyledCheck = styled(Form.Check)`
	/* background-color: ${props => props.theme.colors.modal};
	border: none;
	color: ${props => props.theme.colors.textColor};
	color-scheme: dark;

	&:focus {
		background-color: ${props => props.theme.colors.modal};
		border: none;
		color: ${props => props.theme.colors.textColor};
	} */
`;

// https://react-bootstrap.netlify.app/docs/forms/checks-radios
const Check = forwardRef(
	(
		{
			label = 'Missing label',
			type = 'checkbox', // checkbox or radio
			id,
			noWrap = false,
			...props
		},
		ref
	) => {
		const field = (
			<Form.Group>
				<StyledCheck ref={ref} label={label} id={id} {...props} />
			</Form.Group>
		);

		if (noWrap) return field;

		return <FieldWrapper>{field}</FieldWrapper>;
	}
);

export default Check;
