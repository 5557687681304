import styled from 'styled-components';
import { useMutation } from '@tanstack/react-query';

import Dropdown from 'react-bootstrap/Dropdown';

import {
	AiOutlineMore,
    AiFillDelete,
	AiOutlineCalculator
} from 'react-icons/ai';
import { deleteMasterFeatures } from '@utils/api';
import { useToast } from '@contexts/Toast.context';
import { useProject } from "@contexts/Project.context";

const DropdownToggle = styled(Dropdown.Toggle)`
    background: none;
    padding: 0;
    font-size: 1.2em;
    :after{
        display: none;
    }
`;

const ModelResultDropdown = ({ setHexagonColorFilterActive }) => {
    // project_uuid needs to be its own prop because the task object does not give the correct project_uuid for base models

    const iconClass = "mt-1 mb-2 me-2";
    const { addToast } = useToast();

	const {
		project,
		pickedTask,
		hexagonInfo,
		isDemo,
		dispatch,
    } = useProject();

	const project_uuid = project?.uuid;
	const hexagonsExist = !!hexagonInfo;


    const deleteMutation = useMutation({
		mutationFn: () => {
			return deleteMasterFeatures(project_uuid, pickedTask?.model_uuid);
		},
		onSuccess: (res) => {
            console.log(res);
			// @TODO:Make a prettier alert
            window.alert('Model result deleted successfully. The page will now reload.');
            window.location.reload();
		},
		onError: (error) => {
			console.error(error);
            addToast({
                id: `delete_model_result_error-${new Date().getTime()}`,
                title: "Error deleting model result",
                bg: "danger",
            });
		}
	});

    const handleDelete = async () => {
        if(window.confirm(`Are you sure you want to delete the model result for ${pickedTask?.description}?`)){
            deleteMutation.mutate();
        }
    };

    return (
        <>
            <Dropdown>
                <DropdownToggle variant="dark">
                    <AiOutlineMore />
                </DropdownToggle>
                <Dropdown.Menu variant="dark">
					{hexagonsExist && (
						<>
							<Dropdown.Item as="button" onClick={() => {
								setHexagonColorFilterActive(true);
								dispatch({
									type: 'setToolBarVisible',
									payload: false,
								});
							}}>
								<AiOutlineCalculator
									className={iconClass}
									aria-hidden="true"
								/>
								Color Filter
							</Dropdown.Item>

							<Dropdown.Divider />
						</>
					)}

                    <Dropdown.Item as="button" onClick={handleDelete} disabled={isDemo}>
                        <AiFillDelete
                            className={iconClass}
                            aria-hidden="true"
                        />
                        Delete Results
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </>
    );
};
export default ModelResultDropdown;
