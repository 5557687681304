import Container from "react-bootstrap/Container";
import styled from "styled-components";

const StyledToolbar = styled.div`
	background-color: ${(props) => props.theme.colors.modal};
	display: flex;
	justify-content: space-between;
	position: fixed;
	width: 100%;
	z-index: 2;
	bottom: 0;
	left: 0;
	width: 100%;
	.secondary {
		margin-right: 5px;
	}
  	.container-fluid:first-child {
		width: 100%;
		display: flex;
		justify-content: right;
	}
	.react-tooltip{
		z-index: 2000;
	}
`;
/**
 * Toolbar is a component that is used to display buttons in the bottom of the screen.
 */
const Toolbar = ({ children, alignment }) => {
  return (
    <StyledToolbar>
      <Container fluid>
        {children}
      </Container>
    </StyledToolbar>
  )
}

export default Toolbar;
