import { api } from './index.js';

export const setCookie = (name, value, days = 30) => {
	const expires = new Date(Date.now() + days * 864e5).toUTCString();
	document.cookie =
		name +
		'=' +
		encodeURIComponent(value) +
		'; expires=' +
		expires +
		'; path=/';
};

export const getCookie = name => {
	const value = '; ' + document.cookie;
	const parts = value.split('; ' + name + '=');
	if (parts.length === 2) return parts.pop().split(';').shift();
};

export const deleteCookie = name => {
	document.cookie =
		name +
		'=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
};

export const directoryExists = async directory => {
	try {
		const { data } = await api.get(`/upload/check_directory`, {
			params: { directory },
		});
		return data;
	} catch (err) {
		console.log(err);
		return false;
	}
};
