import { startOfMonth, endOfDay, endOfMonth, subMonths } from 'date-fns';

export const billingDateRange = () => {
	const currentDate = new Date();
	const lastMonthFirstDay = startOfMonth(subMonths(currentDate, 1));
	const lastMonthLastDay = endOfDay(endOfMonth(subMonths(currentDate, 1)));
	const dateRange = {
		from: lastMonthFirstDay,
		to: lastMonthLastDay,
	};

	return JSON.stringify(dateRange);
};
