import {
	PDFViewer,
	Document,
	Page,
	Text,
	Image,
	Circle,
	View,
	StyleSheet,
	Svg,
	Path,
	Polygon,
	G,
} from '@react-pdf/renderer';
import Modal from 'react-bootstrap/Modal';

import { StyledModal } from '@components/modal/ModalStyle';

const PDFModal = ({ show, onHide, content }) => {
	return (
		<StyledModal show={show} onHide={onHide} size="xl" fullscreen>
			<Modal.Header closeButton closeVariant="white">
				<Modal.Title>PDF</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<PDFViewer style={{ width: '100%', height: '100%' }}>
					<MapPDF content={content} />
				</PDFViewer>
			</Modal.Body>
		</StyledModal>
	);
};
export default PDFModal;

const styles = StyleSheet.create({
	page: {
		flexDirection: 'row',
		backgroundColor: '#E4E4E4',
		color: '#000000',
		paddingBottom: 30,
		paddingTop: 20,
		fontSize: 16,
	},
	section: {
		margin: 10,
		padding: 10,
		flexGrow: 1,
	},
	mapImageContainer: {
		flexDirection: 'row',
		justifyContent: 'center',
		alignItems: 'center',
		flexGrow: 1,
	},
	image: {
		width: '100%',
		height: 'auto',
	},
	title: {
		fontSize: 24,
		textAlign: 'center',
		paddingTop: 15,
	},
	smallParagraph: {
		padding: 10,
		paddingTop: 30,
		fontSize: 12,
	},
	table: {
		flexDirection: 'column',
		justifyContent: 'flex-start',
	},
	tableRow: {
		flexDirection: 'column',
		width: '100%',
		borderBottomColor: '#000',
		borderStyle: 'solid',
		borderBottomWidth: 1,
	},
	tableCol: {
		padding: 10,
	},
	tableRowHeader: {
		flexDirection: 'row',
		alignItems: 'center',
	},
	logo: {
		position: 'absolute',
		bottom: 20,
		right: 20,
	},
	pageNumber: {
		position: 'absolute',
		fontSize: 12,
		bottom: 20,
		left: 0,
		right: 0,
		textAlign: 'center',
		color: 'grey',
	},
});

const Logo = () => (
	<View style={styles.logo} fixed>
		<Svg
			style={{ width: 70 }}
			viewBox="0 0 500.6 148.9"
			preserveAspectRatio="xMidYMid meet">
			<G>
				<Polygon
					fill="none"
					stroke="#14743B"
					strokeWidth={5}
					strokeMiterlimit={10}
					points="130.4,142.6 90,146.3 66.6,113.2 83.6,76.4 123.9,72.7 147.3,105.7 		"
				/>
				<Polygon
					fill="none"
					stroke="#14743B"
					strokeWidth={5}
					strokeMiterlimit={10}
					points="66.7,113 26.3,116.8 2.9,83.7 19.8,46.8 60.2,43.1 83.6,76.2 		"
				/>
				<Polygon
					fill="none"
					stroke="#14743B"
					strokeWidth={5}
					strokeMiterlimit={10}
					points="124.6,72.5 84.2,76.3 60.8,43.2 77.8,6.4 118.1,2.6 141.5,35.7 		"
				/>
				<Polygon
					fill="#14743B"
					points="118.2,63.4 88.9,66.2 71.9,42.2 84.2,15.4 113.5,12.7 130.5,36.7 		"
				/>
				<Path
					fill="#000000"
					d="M211.9,64.7c3.3,3.3,5,7.4,5,12.1c0,4.7-1.7,8.8-5,12.1c-2.8,2.8-6.2,4.4-10.3,5h-17.2V43.3h15.8c3.2,0,5.9,1.1,8.1,3.3
					c2.2,2.2,3.3,4.9,3.3,8.1c0,3.1-0.9,5.6-2.8,7.5C209.4,62.2,210.4,63.1,211.9,64.7z M190.6,49.4v10.3h10.2
					c1.5-0.1,2.5-0.6,3.2-1.2c1-1,1.5-2.3,1.5-3.7S205,52,204,51c-1-1-2.3-1.6-3.7-1.6H190.6z M199.8,87.8c3,0,5.6-1.1,7.7-3.2
					c2.1-2.1,3.2-4.7,3.2-7.8c0-3-1.1-5.6-3.2-7.8c-2.1-2.2-4.7-3.2-7.7-3.2h-9.2v22H199.8z"
				/>
				<Path fill="#000000" d="M229.4,43.3v50.6h-6.1V43.3H229.4z" />
				<Path
					fill="#000000"
					d="M280.3,50.2c5.1,5.1,7.6,11.2,7.6,18.3c0,7.2-2.5,13.3-7.6,18.4c-5.1,5.1-11.2,7.6-18.3,7.6c-7.1,0-13.2-2.5-18.3-7.6
					c-5.1-5.1-7.6-11.2-7.6-18.4c0-7.1,2.5-13.2,7.6-18.3c5.1-5.1,11.2-7.6,18.3-7.6C269.1,42.6,275.2,45.2,280.3,50.2z M261.9,48.8
					c-5.4,0-10.1,1.9-14,5.8c-3.9,3.9-5.8,8.5-5.8,13.9c0,5.5,1.9,10.1,5.8,14c3.9,3.9,8.5,5.8,14,5.8c5.5,0,10.1-1.9,14-5.8
					c3.9-3.9,5.8-8.5,5.8-14c0-5.4-1.9-10.1-5.8-13.9S267.4,48.8,261.9,48.8z"
				/>
				<Path
					fill="#000000"
					d="M304.6,43.3c8.3,0,14.7,2.4,19.3,7c5,5.1,7.6,11.2,7.6,18.3c0,7.2-2.5,13.3-7.6,18.3c-4.6,4.6-11,6.9-19.3,7h-10.5V43.3
					H304.6z M300.3,49.5v38.2h4.3c6.3,0,11.3-1.7,14.8-5.2c3.8-3.8,5.8-8.5,5.8-13.9c0-5.4-1.9-10.1-5.8-13.9
					c-3.6-3.4-8.5-5.1-14.8-5.1H300.3z"
				/>
				<Path
					fill="#000000"
					d="M350.1,77.6l0.1,0h-6.3v16.3h-6.1V43.3H352c4.1,0.6,7.5,2.2,10.3,5c3.3,3.4,5,7.4,5,12.1c0,4.8-1.7,8.8-5,12.1
					c-2.3,2.3-4.2,3.7-5.6,4l11.3,17.4h-7.4L350.1,77.6z M343.9,49.5v21.9h6.3c3,0,5.6-1.1,7.7-3.2c2.1-2.1,3.2-4.7,3.2-7.8
					c0-3-1.1-5.6-3.2-7.7c-2.1-2.1-4.7-3.2-7.7-3.2H343.9z"
				/>
				<Path
					fill="#000000"
					d="M416.8,50.2c5.1,5.1,7.6,11.2,7.6,18.3c0,7.2-2.5,13.3-7.6,18.4c-5.1,5.1-11.2,7.6-18.3,7.6c-7.1,0-13.2-2.5-18.3-7.6
					c-5.1-5.1-7.6-11.2-7.6-18.4c0-7.1,2.5-13.2,7.6-18.3c5.1-5.1,11.2-7.6,18.3-7.6C405.6,42.6,411.8,45.2,416.8,50.2z M398.5,48.8
					c-5.4,0-10.1,1.9-14,5.8c-3.9,3.9-5.8,8.5-5.8,13.9c0,5.5,1.9,10.1,5.8,14c3.9,3.9,8.5,5.8,14,5.8c5.5,0,10.1-1.9,14-5.8
					c3.9-3.9,5.8-8.5,5.8-14c0-5.4-1.9-10.1-5.8-13.9S403.9,48.8,398.5,48.8z"
				/>
				<Path
					fill="#000000"
					d="M459.4,79.3v-36h6.1v55.2L436.7,58v35.9h-6.1v-55L459.4,79.3z"
				/>
				<Path
					fill="#000000"
					d="M500.6,43.3v6.1h-20.9v16.1h20.9v6.2h-20.9v16.1h20.9v6.1h-27V43.3H500.6z"
				/>
			</G>
		</Svg>
	</View>
);

const PageNumber = () => (
	<Text
		style={styles.pageNumber}
		render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
		fixed
	/>
);

const MapPDF = ({ content }) => {
	return (
		<Document title={content.title}>
			<Page size="A4" style={styles.page}>
				<View style={styles.section}>
					<Text style={styles.title}>{content.title}</Text>

					<View style={styles.mapImageContainer}>
						{content.mapImageUrl && (
							<Image
								src={content.mapImageUrl}
								style={styles.image}
							/>
						)}
					</View>
				</View>

				<PageNumber />
				<Logo />
			</Page>
			<Page size="A4" style={styles.page}>
				<View style={styles.section}>
					<Text style={styles.title}>Summary</Text>
					{content.filters && (
						<View style={styles.table}>
							{content.filters.map((filter, index) => {
								const color = filter.color;

								return (
									<View key={index} style={styles.tableRow}>
										<View style={styles.tableRowHeader}>
											<Svg
												viewBox="0 0 5 5"
												width="40"
												height="40">
												<Circle
													cx="2.5"
													cy="2.5"
													r="1.25"
													fill={`rgb(${color.r}, ${color.g}, ${color.b})`}
												/>
											</Svg>
											<Text>{filter.label}</Text>
										</View>
										<View style={styles.tableCol}>
											<Text>
												Area: {filter.totalArea}m²
											</Text>
											<Text>
												Calculation:{' '}
												{filter.calculation}
											</Text>
										</View>
									</View>
								);
							})}
						</View>
					)}
					<Text style={styles.smallParagraph}>
						Hexagon size: {content.hexagonSize}m²
					</Text>
				</View>

				<PageNumber />
				<Logo />
			</Page>
		</Document>
	);
};
