import { StyledModal } from './ModalStyle';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import { useProject } from '@contexts/Project.context';

const ConfirmModal = () => {
	const { confirmModalContent: modalContent, dispatch } = useProject();

	if (!modalContent) {
		return null;
	}

	const {
		title,
		message,
		onConfirm,
		confirmLabel = 'Yes',
		onCancel,
		cancelLabel = 'No',
	} = modalContent;

	const resetContent = () => {
		dispatch({ type: 'setConfirmModalContent', payload: null });
	};

	const handleConfirm = () => {
		resetContent();
		onConfirm && onConfirm();
	};

	const handleCancel = () => {
		resetContent();
		onCancel && onCancel();
	};

	return (
		<StyledModal
			show={true}
			onHide={handleCancel}
			backdrop="static"
			keyboard={false}>
			<Modal.Header closeButton closeVariant="white">
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>{message}</Modal.Body>
			<Modal.Footer>
				{onCancel && (
					<Button variant="secondary" onClick={handleCancel}>
						{cancelLabel}
					</Button>
				)}

				{onConfirm && (
					<Button variant="success" onClick={handleConfirm}>
						{confirmLabel}
					</Button>
				)}
			</Modal.Footer>
		</StyledModal>
	);
};

export default ConfirmModal;
