import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { BsArrowRightShort } from 'react-icons/bs';
import styled from 'styled-components';
import { useAuth } from '@contexts/User.context';

const StyledBanner = styled.div`
	margin: 0 0.5rem;
	@media (min-width: 992px) {
		margin-left: 2rem;
		margin-right: 2rem;
	}
	p {
		margin: 0;
	}
`;

const StyledLink = styled(Link)`
	display: block;
	background-color: ${props => props.theme.colors.green};
	font-size: 0.8rem;
	border-radius: 0.375rem;
	padding: 0.2rem 0.5rem;
	color: white;
	transition: all 0.2s ease-in-out;
	text-decoration: none;

	&:hover {
		color: white;
		background-color: #146c43;
	}
`;

const TrialBanner = () => {
	const { currentUser } = useAuth();

	const [timeRemaining, setTimeRemaining] = useState(null);

	const TrialLink = ({ text }) => (
		<StyledLink to="/subscribe">
			<span>
				{text}. Read more <BsArrowRightShort />
			</span>
		</StyledLink>
	);

	useEffect(() => {
		if (currentUser?.tier_trial && currentUser?.trial_expired) {
			setTimeRemaining(-1);
			return;
		}

		const currentDate = new Date();
		const trialExpiryDate = new Date(currentUser?.trial_expiry * 1000); // convert from seconds to milliseconds

		// Set the time part of both dates to midnight (00:00:00)
		currentDate.setHours(0, 0, 0, 0);
		trialExpiryDate.setHours(0, 0, 0, 0);

		if (currentDate <= trialExpiryDate) {
			const timeDifference = trialExpiryDate - currentDate;
			const daysRemaining = Math.floor(
				timeDifference / (1000 * 60 * 60 * 24)
			);
			setTimeRemaining(daysRemaining);
		} else {
			setTimeRemaining(-1);
		}

		return () => {
			setTimeRemaining(null);
		};
	}, [currentUser?.trial_expiry]);

	if (
		!currentUser?.tier_trial ||
		timeRemaining === null ||
		currentUser.active_org_id
	)
		return null;

	return (
		<StyledBanner>
			{timeRemaining >= 0 ? (
				<p>
					{timeRemaining === 0 ? (
						<TrialLink text={`Your trial expires today`} />
					) : (
						<TrialLink
							text={`Your trial expires in ${timeRemaining} ${
								timeRemaining === 1 ? 'day' : 'days'
							}`}
						/>
					)}
				</p>
			) : (
				<p>
					<TrialLink text="Your trial has expired" />
				</p>
			)}
		</StyledBanner>
	);
};
export default TrialBanner;
