import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';

const LoaderWrapper = styled.div`
	display: flex;
    flex-direction: column;
	justify-content: center;
    align-items: flex-start;
	padding: 20px 0 60px;

    ${props => props.$absolute && `
        position: absolute;
        top: 40px;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 100;
    `}

    ${props => props.$inline && `
        display: inline-flex;
        padding: 0;
    `}

    ${props => props.$cover && `
        top: 0;
        align-items: center;
        > div {
            background-color: rgba(0,0,0,0.5);
            width: 50%;
            height: 50%;
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    `}

    p {
        margin: 20px 0 0;
        color: white;
        font-size: 1.2rem;
    }
`;

export default function Loader({absolute = false, inline = false, cover = false, message, ...props}) {
    return (
        <LoaderWrapper $absolute={absolute} $inline={inline} $cover={cover}>
            <Spinner animation="border" role="status" {...props}>
                <span className="visually-hidden">Loading...</span>
            </Spinner>
            {message && <p>{message}</p>}
        </LoaderWrapper>
    );
}