import { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useInfiniteQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Button from 'react-bootstrap/Button';
import { FaMapLocation } from 'react-icons/fa6';
import { IoGrid } from 'react-icons/io5';
import { AiFillPlusCircle } from 'react-icons/ai';

import View from '@components/layout/views/View';
import Toolbar from '@components/toolbar/Toolbar';

import ProjectsGridView from './components/views/ProjectsGridView';
import ProjectsMapView from './components/views/ProjectsMapView/ProjectsMapView';
import SearchFilter from './components/filters/SearchFilter';
import TagsFilter from './components/filters/TagsFilter';

import { useAuth } from '@contexts/User.context';
import { getProjects } from '@api';

const StyledButtonGroup = styled(ButtonGroup)`
	margin-bottom: 1rem;
`;

const StyledButton = styled(Button)`
	display: flex;
	gap: 0.25rem;
	align-items: center;
	justify-content: center;
`;

const FilterWrapper = styled.div`
	display: grid;
	grid-template-columns: 1fr auto;
	align-items: center;
	gap: 40px;

	> div:first-child {
		padding-right: 20%;
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 20px;
	}

	@media screen and (max-width: 991.98px) {
		> div:first-child {
			padding-right: 0;
		}
	}

	@media screen and (max-width: 767.98px) {
		grid-template-columns: 1fr;
		gap: 0px;

		> div:first-child {
			grid-template-columns: 1fr;
			gap: 0;
		}
	}
`;

const ProjectCount = styled.p`
	font-size: 0.9rem;
	font-weight: bold;
	text-align: right;
	color: ${props => props.theme.colors.textAccent};
	margin: 0;
`;

const Projects = () => {
	const [searchParams] = useSearchParams();
	const tags = searchParams.getAll('tags');
	const search = searchParams.get('search');

	const { currentUser, tierPro, tierTrial, trialEnded, subscriptionActive } =
		useAuth();

	const [isNewUser, setIsNewUser] = useState(true);

	const [selectedView, setSelectedView] = useState(
		localStorage.getItem('selectedView') || 'projects'
	);

	const setView = value => {
		setSelectedView(value);
		localStorage.setItem('selectedView', value);
	};

	const fetchProjects = async ({ pageParam = 0 }) => {
		// If user is tierPro and subscription is not active, redirect to home
		if (tierPro && !subscriptionActive) {
			window.location.href = '/';
		}

		return await getProjects({
			tags,
			offset: pageParam ?? 0,
			limit: selectedView === 'projects' ? 12 : 100,
			search,
		});
	};

	const projectDataQuery = useInfiniteQuery({
		queryKey: [
			'user_projects',
			currentUser?.active_org_id,
			currentUser?.uuid,
			search,
			tags,
			selectedView,
		],
		queryFn: fetchProjects,
		getNextPageParam: (lastPage, allPages) => {
			if (Math.ceil(lastPage.total / lastPage.limit) > allPages.length) {
				return lastPage.offset + lastPage.projects?.length;
			}
			return undefined;
		},
		refetchOnWindowFocus: false,
		enabled: !!currentUser?.loggedIn,
		retry: 2,
	});

	const { error, data, isFetching } = projectDataQuery;

	const [projectsData, totalProjects] = useMemo(() => {
		// Flatten the pages to get all projects
		const projectsData = data?.pages?.flatMap(page => page.projects) ?? [];

		// Get the total number of projects from the last page
		const totalProjects = data?.pages?.[data.pages.length - 1]?.total ?? 0;

		// Determine if the user is new based on the presence of tags, search, projects data, and error
		setIsNewUser(
			tags.length === 0 && !search && projectsData.length === 0 && !error
		);

		return [projectsData, totalProjects];
	}, [data]);

	const tooltipContent = tierTrial
		? trialEnded
			? 'Your trial period has ended'
			: 'You can create one project during the trial period'
		: null;

	const newProjectButton = () => (
		<Button
			variant="success"
			className="mt-2 mb-2"
			size="sm"
			data-tooltip-id="tooltip-projects-root"
			data-tooltip-content={tooltipContent}
			data-tooltip-place="top"
			as={Link}
			to="/projects/new"
			disabled={tierTrial && trialEnded}>
			<AiFillPlusCircle className="m-1 mb-2" />
			<span>New Project</span>
		</Button>
	);

	return (
		<>
			<View
				title={`Your Biodrone projects${
					currentUser?.active_org_name
						? ` in ${currentUser?.active_org_name}`
						: ''
				}`}
				mapView={selectedView === 'map'}>
				{!error && !isNewUser && (
					<FilterWrapper>
						<div>
							<SearchFilter />
							<TagsFilter />
						</div>
						<StyledButtonGroup size="md">
							<StyledButton
								onClick={() => setView('projects')}
								active={selectedView == 'projects'}
								variant="dark">
								<IoGrid />
								Grid
							</StyledButton>
							<StyledButton
								onClick={() => setView('map')}
								active={selectedView == 'map'}
								disabled={isNewUser}
								variant="dark">
								<FaMapLocation />
								Map
							</StyledButton>
						</StyledButtonGroup>
					</FilterWrapper>
				)}

				{isNewUser && !isFetching && (
					<div className="d-flex align-content-center w-100 p-5">
						<div>
							<p>
								Start by creating your first project. You need
								images from a drone or an orthophoto.
							</p>
							{newProjectButton()}
						</div>
					</div>
				)}

				<ProjectCount>
					Showing {projectsData.length} of {totalProjects}
				</ProjectCount>

				{selectedView === 'projects' ? (
					<ProjectsGridView
						projectsData={projectsData}
						totalProjects={totalProjects}
						projectDataQuery={projectDataQuery}
						isNewUser={isNewUser}
					/>
				) : (
					<ProjectsMapView
						projectsData={projectsData}
						totalProjects={totalProjects}
						projectDataQuery={projectDataQuery}
					/>
				)}

				<Toolbar alignment="right">{newProjectButton()}</Toolbar>
			</View>
		</>
	);
};

export default Projects;
