import { startOfMonth, endOfDay, subMonths, endOfMonth } from 'date-fns';

/**
 * returns the job name based on the job type
 * @param {string} type The job type
 * @returns {string} Returns the job name
 */
export const getJobName = type => {
	switch (type) {
		case 'segmentation_train':
			return 'segmentation training';
		case 'segmentation_predict':
			return 'segmentation prediction';
		case 'object_detection_train':
			return 'object detection training';
		case 'object_detection_predict':
			return 'object detection prediction';
		case 'grid_tiling':
			return 'Grid tiling';
		case 'reproject_tif':
			return 'reproject TIF';
		case 'user_annotation_processing':
			return 'user annotation processing';
		case 'user_annotation_train':
			return 'user annotation training';

		default:
			return 'job in progress';
	}
};

export const cleanUserInput = text => {
	return text
		.replace(/[^a-zA-Z0-9 ]/g, '')
		.trim()
		.replace(/  +/g, ' ')
		.toLowerCase()
		.replace(/\b[a-z]/g, letter => letter.toUpperCase());
};

// Format number with commas as thousands separators
export const formatAreaNumber = (n, decimalPlaces = 2) => {
	if (!isNaN(parseFloat(n))) {
		// Round the number to the specified decimal places
		const roundedNumber = Number(Number(n).toFixed(decimalPlaces));

		// Use toLocaleString() to add commas as thousands separators
		return roundedNumber.toLocaleString();
	}
	return n;
};

export const recurringIntervalSwitch = interval => {
	switch (interval) {
		case 'day':
			return 'daily';
		case 'week':
			return 'weekly';
		case 'month':
			return 'monthly';
		case 'year':
			return 'yearly';
		default:
			return null;
	}
};
