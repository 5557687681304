import { api } from './index.js';

const routePath = '/filter_templates';

export const getFilterTemplates = async ({ limit = 50, search = '' }) => {
	const params = {};

	if (search) params.search = search;
	params.limit = limit;

	const { data } = await api.get(routePath, { params });
	return data;
};

export const createFilterTemplate = async template => {
	const { title, hexagonSize: hexagon_size, filterBoxes } = template;
	const res = await api.post(routePath, {
		title,
		hexagon_size,
		filter_boxes: filterBoxes,
	});

	return res.data;
};

export const updateFilterTemplate = async template => {
	const { uuid, title, hexagonSize: hexagon_size, filterBoxes } = template;
	const { data } = await api.put(`${routePath}/${uuid}/update`, {
		uuid,
		title,
		hexagon_size,
		filter_boxes: filterBoxes,
	});
	return data;
};

export const deleteFilterTemplate = async uuid => {
	const { data } = await api.delete(`${routePath}/${uuid}`);
	return data;
};
