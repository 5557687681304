import { zoomToContainFeatures, getFeatureType } from './helpers';

const handleMapClick = (map, mapRefCurrent) => {
	map.on('click', e => {
		// If no layerSource or features on layer -> Return
		if (
			map?.getLayers()?.getArray()[1]?.getSource()?.getFeatures()
				?.length === 0
		)
			return;

		// On Click Reset Cursor
		mapRefCurrent.style.cursor = 'default';

		const featureAtPixel = map.getFeaturesAtPixel(e.pixel)[0];
		// If Direct Click on Cluster -> Fit to view features within cluster
		if (getFeatureType(featureAtPixel) === 'cluster') {
			zoomToContainFeatures(
				map.getView(),
				featureAtPixel.get('features'),
				[50, 50, 50, 50],
				0
			);
		}
	});
};
export default handleMapClick;
