import SubscriptionsAvailableForUser from '@components/subscription/SubscriptionsAvailableForUser';
import { StyledModal } from './ModalStyle';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

const ProductsModal = ({ title, message, filterProductsBy, onClose }) => {
	return (
		<StyledModal show={true} onHide={onClose} centered size="xl">
			<Modal.Header closeButton closeVariant="white">
				<Modal.Title>{title}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p className="mt-3 mb-5">{message}</p>
				<SubscriptionsAvailableForUser
					filterProductsBy={filterProductsBy}
				/>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={onClose}>
					Cancel
				</Button>
			</Modal.Footer>
		</StyledModal>
	);
};
export default ProductsModal;
