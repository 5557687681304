import { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';

import FileItem from './FileItem';
import { useAuth } from '@contexts/User.context';

const UploadFieldWrap = styled.div`
	border: 1px solid rgba(${p => p.theme.colors.borderLightRgb}, 0.2);
	padding: 1em;
	border-radius: 0.375rem;
`;

const UploadFieldContainer = styled.div`
	display: flex;
	flex-direction: column;
	transition: background-image 0.2s ease-out;
	--border-size: 3px;
	--border-angle: 0turn;
	border-radius: 0.375rem;
	background-image: conic-gradient(
			from var(--border-angle),
			#171717,
			#171717 50%,
			#171717
		),
		conic-gradient(from var(--border-angle), transparent 20%, #fff, #198754);
	background-size: calc(100% - (var(--border-size) * 2))
			calc(100% - (var(--border-size) * 2)),
		cover;
	background-position: center center;
	background-repeat: ${p => (p.isDragActive ? 'no-repeat' : 'initial')};
	background-color: #171717;
	animation: bg-spin 3s linear infinite;
	animation-play-state: ${p => (p.isDragActive ? 'running' : 'paused')};

	@keyframes bg-spin {
		to {
			--border-angle: 1turn;
		}
	}
	@property --border-angle {
		syntax: '<angle>';
		inherits: true;
		initial-value: 0turn;
	}

	& > span {
		display: block;
		padding: 2rem 1rem;
		text-align: center;
	}
`;

const FileList = styled.div`
	margin: 3px;
	background-color: #171717;
	border-radius: 0.375rem;
	max-height: 300px;
	overflow-y: auto;
`;

/**
 * Component for uploading files to a project
 *
 * @param {array} files - array of files to upload
 * @param {function} setFiles - function to set files to upload
 * @param {boolean} setIsOrthophoto - function to set whether the files are orthophotos
 * @param {boolean} disabled - whether the upload field is disabled
 */
export default function UploadField({
	files,
	setFiles,
	isOrthophoto,
	isOrthophotoProject,
	setIsOrthophoto,
	disabled,
	imageLimit = 0,
}) {
	const { tierTrial } = useAuth();
	const fileTypeRef = useRef(null);

	// Remove file from files array
	const removeFile = file => {
		setFiles(files => {
			const filteredFiles = files.filter(f => f !== file);
			if (filteredFiles.length === 0) {
				setIsOrthophoto(false);
				fileTypeRef.current = null;
			}
			return filteredFiles;
		});
	};

	// Handles rejected files and displays an error message
	const handleFileRejection = fileRejection => {
		if (fileRejection.length > 0) {
			console.log(
				'Error uploading files: ',
				fileRejection[0].errors[0].code
			);
			switch (fileRejection[0].errors[0].code) {
				case 'too-many-files':
					window.alert(
						`Too many files. Maximum number of files is ${maxFiles}.`
					);
					break;
				case 'file-invalid-type':
					window.alert(
						`You tried to upload an unsupported file type. Only .jpg/jpeg ${
							isOrthophotoProject ? 'and .tif/tiff' : ''
						} files are supported.`
					);
					break;
				default:
					window.alert('Error uploading files.');
			}
		}
	};

	// Handles files added to the dropzone
	const onDrop = useCallback((acceptedFiles, fileRejection) => {
		// Handle rejected files
		handleFileRejection(fileRejection);

		// If no files were accepted, return
		if (acceptedFiles.length === 0) return;

		const uploadedFile = acceptedFiles[0];
		const fileName = uploadedFile.name;
		const fileType = uploadedFile.type;

		// Save the first file type to have a reference for the rest of the files
		if (!fileTypeRef.current) {
			fileTypeRef.current = fileType;
		}

		// Check if the file extension is ".tiff" or ".tif"
		if (
			isOrthophotoProject &&
			(fileType === 'image/tiff' ||
				fileName.endsWith('.tiff') ||
				fileName.endsWith('.tif'))
		) {
			console.log('Uploaded file is a TIFF or TIF file');

			// Check that all accepted files are tiff or tif
			if (fileTypeRef.current !== fileType) {
				window.alert(
					'You can not mix filetypes. Please upload only one type of files.'
				);
				return;
			}

			// Only accept one .tif file at a time
			if (acceptedFiles.length > 1) {
				window.alert('Only one .tif file can be uploaded at a time.');
				return;
			}

			// Trial account can only upload 200MB orthophoto
			const megabytes = uploadedFile.size / (1024 * 1024); // 1 MB = 1024 * 1024 bytes
			if (tierTrial && megabytes > 200) {
				window.alert(
					'File size is too large. Maximum file size for orthophoto is 200MB.'
				);
				return;
			}

			setIsOrthophoto(true);
		} else {
			console.log('Uploaded file is a JPG file');

			let sameFileType = acceptedFiles.every(
				file => file.type === fileTypeRef.current
			);
			if (!sameFileType) {
				window.alert(
					'You can not mix filetypes. Please upload only one type of files.'
				);
				return;
			}

			setIsOrthophoto(false);
		}

		setFiles(files => [...files, ...acceptedFiles]);
	}, []);

	useEffect(() => {
		// Check if all files are removed
		if (files.length === 0) {
			console.log('All files removed');
			setIsOrthophoto(false);
			fileTypeRef.current = null;
		}
	}, [isOrthophotoProject]);

	const acceptableImageTypesBasedOnProjectType = isOrthophotoProject =>
		isOrthophotoProject
			? {
					'image/jpg': ['.jpg', '.jpeg'],
					'image/tiff': ['.tif', '.tiff'],
			  }
			: { 'image/jpg': ['.jpg', '.jpeg'] };

	// Dropzone hook
	const maxFiles = tierTrial ? 100 : imageLimit;
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		maxFiles: maxFiles,
		disabled: disabled,
		accept: acceptableImageTypesBasedOnProjectType(isOrthophotoProject),
	});

	const limitText = () => {
		if (tierTrial) {
			return `Limit of ${maxFiles} images or 200MB orthophoto on trial account.`;
		} else if (imageLimit) {
			return `Limit of ${imageLimit} files for your subscription type.`;
		}

		return '';
	};

	return (
		<div>
			<p className="mb-1">
				Project files{' '}
				{isOrthophotoProject ? (
					<span className="small">(jpg or orthophoto tiff)</span>
				) : (
					<span className="small">(jpg)</span>
				)}
			</p>
			<UploadFieldWrap>
				{!(isOrthophoto && files.length === 1) && (
					<UploadFieldContainer
						{...getRootProps()}
						isDragActive={isDragActive}>
						<input {...getInputProps()} />
						{isDragActive ? (
							<span>
								Drop the files here ... <br />
								{limitText()}
							</span>
						) : (
							<span>
								Drag and drop some files here, or click to
								select files.
								<br />
								<small className="text-muted">
									{limitText()}
								</small>
							</span>
						)}
					</UploadFieldContainer>
				)}
				{files?.length > 0 && (
					<>
						<p className="mt-3 mb-1">Files:</p>
						<FileList>
							{files.map((f, i) => (
								<FileItem
									key={`${f.name}-${i}`}
									file={f}
									index={i}
									removeFile={removeFile}
									disabled={disabled}
								/>
							))}
						</FileList>
					</>
				)}
			</UploadFieldWrap>
		</div>
	);
}
