import { api } from './index.js';

export const postTileData = async (
	projectId,
	taskId,
	currentTileName,
	data
) => {
	const json = data; /*JSON.stringify(postData)*/

	try {
		const res = await api.post(
			`/training/annotations/${projectId}/${taskId}/${currentTileName}`,
			json
		);
		return res.data;
	} catch (err) {
		return err.response;
	}
};

export const initTraining = async (projectId, modelId) => {
	try {
		const res = await api.get(`/training/train/${projectId}/${modelId}`);
		return res;
	} catch (err) {
		console.log(err);
		return err.response;
	}
};
