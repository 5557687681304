import styled from 'styled-components';
import { useMutation } from '@tanstack/react-query';
import Dropdown from 'react-bootstrap/Dropdown';
import { useNavigate, Link } from 'react-router-dom';

import { AiOutlineEdit, AiOutlineMore, AiFillFolderOpen } from 'react-icons/ai';

import { archiveOrganization } from '@api';
import { useAuth } from '@contexts/User.context';
import { billingDateRange } from '@routes/adminRoutes/utils/helpers';

const DropdownToggle = styled(Dropdown.Toggle)`
	background: none;
	padding: 0;
	font-size: 1.2em;
	:after {
		display: none;
	}
`;

const DropdownMenu = ({ data }) => {
	const navigate = useNavigate();
	const { roleAdmin } = useAuth();

	const iconClass = 'mt-1 mb-2 me-2';

	const mutation = useMutation({
		mutationFn: () => archiveOrganization(data?.uuid),
		onError: () => {
			window.alert('Failed to archive organization');
		},
	});

	const handleArchive = async () => {
		if (
			window.confirm(
				`Are you sure you want to archive the organization ${data.name}?`
			)
		) {
			mutation.mutate();
		}
	};

	const handleEdit = () => {
		navigate(`/admin/organizations/${data.uuid}`);
	};

	return (
		<>
			<Dropdown>
				<DropdownToggle variant="dark">
					<AiOutlineMore />
				</DropdownToggle>
				<Dropdown.Menu variant="dark">
					{roleAdmin && (
						<Dropdown.Item
							as={Link}
							to={
								`/admin/projects/?search=${data.uuid}&showArchived=true&mode=billing&dateRange=` +
								billingDateRange()
							}>
							<AiFillFolderOpen
								className={iconClass}
								aria-hidden="true"
							/>
							Monthly billing
						</Dropdown.Item>
					)}
					<Dropdown.Item
						as={Link}
						to={`/admin/projects/?search=${data.uuid}`}>
						<AiFillFolderOpen
							className={iconClass}
							aria-hidden="true"
						/>
						All projects
					</Dropdown.Item>
					<Dropdown.Item as="button" onClick={handleEdit}>
						<AiOutlineEdit
							className={iconClass}
							aria-hidden="true"
						/>
						Edit
					</Dropdown.Item>
					<Dropdown.Divider className="border-secondary" />
					<Dropdown.Item as="button" onClick={handleArchive}>
						<AiFillFolderOpen
							className={iconClass}
							aria-hidden="true"
						/>
						Archive
					</Dropdown.Item>
				</Dropdown.Menu>
			</Dropdown>
		</>
	);
};
export default DropdownMenu;
