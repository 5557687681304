import { Link } from 'react-router-dom';
import {
	useReactTable,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
} from '@tanstack/react-table';
import styled from 'styled-components';

import {
	StyledTable,
	StyledTableHeader,
	StickyTableHeaderWrapper,
} from '@routes/adminRoutes/sharedComponents/SharedStyledComponents';
import CellContent from '@routes/adminRoutes/sharedComponents/CellContent';
import DropdownMenu from './modify/DropdownMenu';

const StyledLink = styled(Link)`
	display: inline-block;
	max-width: 300px;
	color: #fff;
`;

const Table = ({ data, loading }) => {
	// Define columns for the table
	const columns = [
		{
			header: 'Name',
			accessorKey: 'name',
			cell: ({ row, getValue }) => {
				// Return title with link to organization
				return (
					<StyledLink
						to={`/admin/organizations/${row.original.uuid}`}
						title="View organization">
						{getValue()}
					</StyledLink>
				);
			},
		},
		{
			header: 'Uuid',
			accessorKey: 'uuid',
			copy: true,
		},
		{
			header: 'Admin',
			accessorKey: 'admin_user_email',
			copy: true,
		},
		{
			header: 'Members',
			accessorKey: 'members',
			cell: ({ getValue }) => {
				const value = getValue();

				return <div>{value ? value.length : 0}</div>;
			},
		},
		{
			header: 'Projects',
			accessorKey: 'projects',
			cell: ({ getValue }) => {
				const value = getValue();

				return <div>{value ? value.length : 0}</div>;
			},
		},
	];

	const table = useReactTable({
		columns,
		data,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	});

	return (
		<StickyTableHeaderWrapper>
			<StyledTable
				borderless
				hover
				responsive
				$loading={loading}
				size="sm">
				<thead>
					{table.getHeaderGroups().map(headerGroup => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map(column => {
								if (column.id === 'deleted') return null;
								return (
									<th key={column.id}>
										<StyledTableHeader>
											{flexRender(
												column.column.columnDef.header,
												column.getContext()
											)}
										</StyledTableHeader>
									</th>
								);
							})}
							<th colSpan="1" role="columnheader">
								<span className="visually-hidden">
									Dropdownmenu
								</span>
							</th>
						</tr>
					))}
				</thead>
				<tbody>
					{table.getRowModel().rows.map(row => {
						return (
							<tr
								key={row.id}
								className={
									row.original.deleted ? 'archived' : ''
								}>
								{row.getVisibleCells().map(cell => {
									if (cell.column.id === 'deleted')
										return null;

									return (
										<td
											key={cell.id}
											className="custom-cell">
											<div className={cell.column.id}>
												<CellContent
													cell={cell}
													data={row.original}
												/>
											</div>
										</td>
									);
								})}
								<td role="cell" className="custom-cell">
									<DropdownMenu data={row.original} />
								</td>
							</tr>
						);
					})}
				</tbody>
			</StyledTable>
		</StickyTableHeaderWrapper>
	);
};
export default Table;
