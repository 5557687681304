import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import App from './App';
import { UserProvider } from './contexts/User.context';
import { ThemeProvider } from './contexts/Theme.context';

import 'bootstrap/dist/css/bootstrap.min.css';
import { ToastProvider } from './contexts/Toast.context';
import { TokenProvider } from './contexts/Token.context';

const root = ReactDOM.createRoot(document.getElementById('root'));

const queryClient = new QueryClient();

root.render(
	<React.StrictMode>
		<TokenProvider>
			<BrowserRouter>
				<ThemeProvider>
					<QueryClientProvider client={queryClient}>
						<UserProvider>
							<ToastProvider>
								<Routes>
									<Route path="/*" element={<App />} />
								</Routes>
							</ToastProvider>
						</UserProvider>
					</QueryClientProvider>
				</ThemeProvider>
			</BrowserRouter>
		</TokenProvider>
	</React.StrictMode>
);
