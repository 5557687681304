import { useProject } from '@contexts/Project.context';
import { convertToRgba } from '@utils/map/helpers';
import styled from 'styled-components';

const Legend = styled.div`
	position: absolute;
	top: 60px;
	right: 8px;
	background-color: ${props => props.theme.colors.background};

	padding: 20px;
    z-index: 1;
	max-width: 250px;

	h2{
		color: ${props => props.theme.colors.textColor};
		font-size: 1rem;
		opacity: 0.7;
		font-weight: 300;
		margin-bottom: 20px;
	}
`;

const LegendList = styled.ul`
	padding: 0;
	list-style: none;
	margin: 0;
	display: flex;
	flex-direction: column;
	gap: 10px;
`;

const LegendItem = styled.li`
	display: grid;
	grid-template-columns: 20px 1fr;
	gap: 10px;
	align-items: center;
    color: ${props => props.theme.colors.textColor};
	text-transform: capitalize;
`;

const ColorLine = styled.div`
	border-top: 3px solid ${props => props.color};
	width: 100%;
`;

const MapLegend = () => {
	const {
		pickedModelClasses,
		colorScheme,
	} = useProject();

	if(!pickedModelClasses?.length) return null;

	return (
		<Legend>
            <h2>Model Classes</h2>
			<LegendList>
				{pickedModelClasses.map((legendItem) => (
					<LegendItem key={legendItem.label}>
						<Color schemeColor={colorScheme[legendItem.id]} />
						<div>{legendItem.label}</div>
					</LegendItem>
				))}
			</LegendList>
		</Legend>
	);
};

export default MapLegend;

const Color = ({schemeColor}) => {
	const color = convertToRgba(schemeColor?.color);

	if(!color) return null;

	return (
		<ColorLine color={color} />
	);

};
