import { extend, isEmpty, createEmpty } from 'ol/extent';
import Feature from 'ol/Feature';
import { Point } from 'ol/geom';
import { easeOut } from 'ol/easing';

// ProjectsMap Helpers:

/**
 * Converts an array of project data into a filtered array of point geometry features.
 * (Filtered for invalid coordinates)
 *
 * @param {Array} projectsData An array of project data objects.
 * @returns {Array} An array of point features.
 */
export const getFeaturesFromProjectsData = projectsData => {
	// ProjectsData Array -> Features Array with ProjectData and Coordinates
	const features = projectsData
		?.map(project => {
			const { center_coordinate } = project;
			if (
				center_coordinate.length &&
				center_coordinate[0] > 0 &&
				center_coordinate[1] > 0
			)
				return new Feature({
					geometry: new Point(center_coordinate),
					data: project,
				});
		})
		.filter(project => !!project);
	return features;
};

/**
 * Retrieves a feature by its UUID from a specified layer on the map.
 *
 * @param {Object} map The map object containing layers.
 * @param {string} layerName The name of the layer to search within.
 * @param {string} uuid The UUID of the feature to find.
 * @returns {Object|undefined} The found feature object, or undefined if not found.
 */
export const getFeatureByUuid = (map, layerName, uuid) => {
	const clusterPinLayer = map
		.getLayers()
		.array_.find(layer => layer.get('name') === layerName);

	if (!clusterPinLayer) return;

	let foundFeature;
	clusterPinLayer
		.getSource()
		.getFeatures()
		.find(cluster => {
			const featureSearch = cluster
				.get('features')
				.find(feature => feature.get('data').uuid === uuid);
			if (featureSearch) foundFeature = featureSearch;
			return !!featureSearch;
		});
	return foundFeature;
};

/**
 * Determines the type of a feature based on its properties.
 *
 * @param {Object} feature The feature object to be evaluated.
 * @returns {string} The type of the feature, either 'single', 'cluster', or '' if the feature is undefined.
 */
export const getFeatureType = feature => {
	if (!feature) return '';
	if (feature.get('features')) {
		return feature.get('features').length > 1 ? 'cluster' : 'single';
	} else {
		return 'single';
	}
};

// Not Exported
const extendExtent = (extent, features) => {
	const hasUndefined = features.some(feature => feature === undefined);
	if (hasUndefined) {
		console.error('Undefined Features, Skipping Extend Extent');
		return;
	}
	if (features.length === 0) {
		return;
	} else if (features.length === 1) {
		extend(extent, features[0].get('data').extent);
	} else {
		features.forEach(feature => {
			extend(extent, feature.getGeometry().getExtent());
		});
	}
};

/**
 * Zooms the map view to contain the given features within the specified padding and duration.
 *
 * @param {Object} view The map view object to be adjusted.
 * @param {Array} features An array of features to be contained within the view.
 * @param {number} padding The padding around the features in the view.
 * @param {number} duration The duration of the zoom animation in milliseconds.
 */
export const zoomToContainFeatures = (view, features, padding, duration) => {
	const extent = createEmpty();
	extendExtent(extent, features);

	//Fit View to Extent
	if (!isEmpty(extent)) {
		view.fit(extent, {
			padding: padding,
			duration: duration,
			easing: easeOut,
		});
	}
};

/**
 * Truncates a string and appends a suffix if it exceeds the specified maximum length.
 *
 * @param {string} string The string to be truncated.
 * @param {number} maxLength The maximum length of the truncated string including the suffix.
 * @param {string} suffix The suffix to append if the string is truncated.
 * @returns {string} The truncated string with the suffix if applicable.
 */
export const truncateStringWithSuffix = (string, maxLength, suffix) => {
	return string.length > maxLength - suffix.length
		? string.slice(0, maxLength) + suffix
		: string;
};

/**
 * Calculates the radius of a circle based on the cluster amount and minimum radius.
 *
 * @param {number} clusterAmount The amount of features within the cluster.
 * @param {number} minRadius The minimum radius of the circle.
 * @returns {number} The calculated radius of the circle.
 */
export const calculateCircleRadius = (clusterAmount, minRadius) => {
	const stringLength = clusterAmount.toString().length;
	const circleRadius =
		stringLength < 3
			? minRadius
			: minRadius +
			  (stringLength % 2 === 0 ? stringLength : stringLength - 1);

	return circleRadius;
};

/**
 * Checks if a given position is outside the specified bounding box.
 *
 * @param {Object} boundingBox The bounding box with top, bottom, left, and right properties.
 * @param {Object} position The position with x and y coordinates.
 * @returns {boolean} Returns true if the position is outside the bounding box, otherwise false.
 */
export const isOutsideBoundingBox = (boundingBox, position) => {
	return (
		position.y < boundingBox.top ||
		position.y > boundingBox.bottom ||
		position.x < boundingBox.left ||
		position.x > boundingBox.right
	);
};
