import {
	flexRender,
} from '@tanstack/react-table';
import { format } from 'date-fns';

import CopyButton from "./CopyButton";
import BooleanValue from './BooleanValue';

/**
 * Function to render the content of a cell using react-table
 * 
 * @param {*} param0 
 * @returns 
 */
const CellContent = ({ cell, data }) => {

	const getFixedNumber = (n) => {
        if (!isNaN(parseFloat(n))) {
            n = parseFloat(n).toFixed(2).replace('.00', '');
        }
        return n;
    }

	// If cell.column.columnDef.copy is true, return a button that copies the cell value to the clipboard
	if (cell.column.columnDef.copy && cell.getValue()) {
		return (
			<CopyButton value={cell.getValue()}>
				<span>
					{flexRender(cell.column.columnDef.cell, cell.getContext())}
				</span>
			</CopyButton>
		);
	}

	// If cell.column.columnDef.dateFormat exist and is a string, return a date formatted with the given format	
	const dateFormat = cell.column.columnDef.dateFormat;
	if (dateFormat && typeof dateFormat === 'string' && cell.getValue()) {
		const dateFormatted = format(
			new Date(cell.getValue()),
			dateFormat
		);
		return (
			<div>
				{dateFormatted}
			</div>
		);
	}

	// If cell.column.columnDef.getFixedNumber is true, return a fixed number
	if (cell.column.columnDef.getFixedNumber && cell.getValue()) {
		return (
			<div>
				{getFixedNumber(cell.getValue())}
			</div>
		);
	}

	//If is boolean and columnDef wants too, return a checkmark or x
	if (cell.column.columnDef.boolean && typeof cell.getValue() === 'boolean') {
		return <BooleanValue value={cell.getValue()} />;
	}

	// Otherwise, just return the cell value
	return (
		<span>{flexRender(cell.column.columnDef.cell, cell.getContext())}</span>
	);
};
export default CellContent;