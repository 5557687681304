import HexagonSize from '@components/hexagonSize/HexagonSize';
import Select from '@components/form/Select';

export default function HexagonDataFields({
	hexagonSize,
	setHexagonSize,
	setFilterClasses,
	defaultClasses,
}) {
	return (
		<>
			<Select
				label="Objects"
				id="filter-box-classes"
				options={defaultClasses.map(key => {
					return {
						value: key,
						label: key,
					};
				})}
				defaultValue={defaultClasses.map(key => {
					return {
						value: key,
						label: key,
					};
				})}
				placeholder="Defaults to all. Select to filter."
				noOptionsMessage={() => 'All objects are selected'}
				onChange={vals => {
					setFilterClasses(vals.map(key => key.value));
				}}
				isMulti
				isClearable={false}
				isSearchable={false}
			/>

			<HexagonSize
				customHexagonSize={hexagonSize}
				setCustomHexagonSize={setHexagonSize}
			/>
		</>
	);
}
