import { Link, Navigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

import { useAuth } from '@contexts/User.context';

import { loginUser } from '@api';

import View from '@components/layout/views/View';
import Input from '@components/form/Input';
import Alert from '@components/alert/Alert';

export default function Login() {
	const { currentUser } = useAuth();

	if (currentUser?.loggedIn) {
		return <Navigate to="/projects" />;
	}

	return (
		<View maxWidth={550} title="Log in">
			<LoginForm />
		</View>
	);
}

function LoginForm() {
	const { refetchCurrentUser } = useAuth();

	const { control, handleSubmit } = useForm({
		defaultValues: {
			email: '',
			password: '',
		},
	});

	const loginMutation = useMutation({
		mutationFn: values => loginUser(values),
		onError: err => {
			console.log('err', err);
		},
		onSuccess: () => {
			refetchCurrentUser();
		},
	});

	const onSubmit = data => {
		const { email, password } = data;
		loginMutation.mutate({ email, password });
	};

	return (
		<>
			{loginMutation?.isError && (
				<Alert variant="danger">
					<p>{loginMutation?.error?.message}</p>
				</Alert>
			)}

			<Card bg="dark" border="dark" body className="p-2">
				<Form onSubmit={handleSubmit(onSubmit)}>
					<Controller
						name="email"
						control={control}
						render={({ field }) => (
							<Input
								{...field}
								type="email"
								label="Email address"
								autoComplete="username"
								required
							/>
						)}
					/>

					<Controller
						name="password"
						control={control}
						render={({ field }) => (
							<Input
								{...field}
								type="password"
								label="Password"
								autoComplete="password"
								required
							/>
						)}
					/>

					<Button
						disabled={loginMutation.isLoading}
						className="mt-3"
						variant="success"
						id="submitLogin"
						type="submit">
						Log in
					</Button>
				</Form>
			</Card>

			<div className="mt-5">
				<p>
					Don't have an account?{' '}
					<Link to="/register">Create an account</Link>
				</p>

				<p>
					Forgot your password?{' '}
					<Link to="/request-password-reset">
						Request a reset link
					</Link>
				</p>
			</div>
		</>
	);
}
