import useSetSearchParams from '@hooks/useSetSearchParams.hook';
import SearchField from '@components/filters/SearchField';
import SelectField from '@components/filters/selectField';
import { FilterWrapper } from '@routes/adminRoutes/sharedComponents/SharedStyledComponents';

import { ROLES, TIERS } from '@contexts/User.context';

const UsersFilter = () => {
	const customSetSearchParams = useSetSearchParams();

	return (
		<FilterWrapper>
			<SearchField
				setSearch={val => {
					customSetSearchParams({ search: val });
				}}
				label="Search by email or uuid"
				searchParamsId="search"
				noWrap
			/>

			<SelectField
				label="Filter by tier"
				setValue={val => {
					customSetSearchParams({ tier: val });
				}}
				searchParamsId="tier"
				options={Object.keys(TIERS).map(tier => {
					return { label: tier, value: TIERS[tier] };
				})}
				id="filter-tier"
			/>

			<SelectField
				label="Filter by role"
				setValue={val => {
					customSetSearchParams({ role: val });
				}}
				searchParamsId="role"
				options={Object.keys(ROLES).map(role => {
					return { label: role, value: ROLES[role] };
				})}
				id="filter-role"
			/>
		</FilterWrapper>
	);
};
export default UsersFilter;
