const erasorSvgData =
	'<svg width="28" height="34" viewBox="0 0 28 34" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20.4201 32.8041L13.2445 25.6284L10.0553 28.0203L14.8391 32.8041L18.8255 33.6014L20.4201 32.8041Z" fill="black"/><path d="M18.9284 18.7003C19.3769 18.2519 19.9852 18 20.6195 18C21.2537 18 21.862 18.2519 22.3105 18.7003L26.9496 23.3394C27.398 23.7879 27.6499 24.3962 27.6499 25.0304C27.6499 25.6647 27.398 26.273 26.9496 26.7215L20.3719 33.2992C19.9234 33.7478 19.3151 33.9999 18.6808 34H15.3812C14.7469 33.9999 14.1386 33.7478 13.6902 33.2992L10.7003 30.3093C10.2519 29.8608 10 29.2525 10 28.6183C10 27.984 10.2519 27.3757 10.7003 26.9272L18.9272 18.7003H18.9284ZM19.7177 32.2623L13.3876 25.9322L11.547 27.7727C11.3228 27.997 11.1969 28.3011 11.1969 28.6183C11.1969 28.9354 11.3228 29.2395 11.547 29.4638L14.5369 32.4536C14.7611 32.6779 15.0653 32.804 15.3824 32.8041H18.682C18.9992 32.804 19.3033 32.6779 19.5276 32.4536L19.7177 32.2623Z" fill="white"/><path d="M14.8951 24.0844L19.7616 19.8262C20.1387 19.4963 20.7016 19.4963 21.0786 19.8262L26.0459 24.1726C26.4616 24.5363 26.5037 25.168 26.14 25.5837L21.8811 30.451C21.5154 30.8689 20.8792 30.9088 20.4642 30.5399L14.8893 25.5844C14.4394 25.1846 14.4422 24.4808 14.8951 24.0844Z" fill="black"/><path d="M1 2L13 13L8 14L11 19.5L8 21L5 15L1 18V2Z" fill="black" stroke="white"/></svg>';
const createSvgCursor = svgData => {
	return `url("data:image/svg+xml;charset=utf-8,${encodeURIComponent(
		svgData
	)}") 0 0, auto`;
};

// Add trash can cursor
export const pointerMoveListener = (evt, mapReference) => {
	if (!evt.dragging) {
		mapReference.getTargetElement().style.cursor =
			mapReference.hasFeatureAtPixel(
				mapReference.getEventPixel(evt.originalEvent)
			)
				? createSvgCursor(erasorSvgData)
				: 'auto';
	}
};
