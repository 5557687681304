import PopoverPicker from '@components/popoverPicker/PopoverPicker';
import RangeSliders from './RangeSliders';
import Input from '@components/form/Input';

const FormInputs = ({
	data,
	setFilters,
	hexagonRanges,
	activeFilterTemplate,
	index,
}) => {
	const updateFilterBox = (key, value) => {
		setFilters(boxes => {
			const newBoxes = [...boxes];
			newBoxes[index][key] = value;
			return newBoxes;
		});
	};

	return (
		<div className="d-flex flex-column gap-3">
			<div className="d-flex gap-4 justify-content-between align-items-center">
				<div className="w-100 mt-2">
					<Input
						label="Label"
						placeholder="Label for the filter"
						type="text"
						value={data.label}
						onChange={val => {
							updateFilterBox('label', val.target.value);
						}}
						noWrap
					/>
				</div>

				<PopoverPicker
					color={data?.color}
					onChange={val => {
						updateFilterBox('color', val);
					}}
					popDirection="right"
				/>
			</div>

			<hr className="my-2" />

			<RangeSliders
				data={data}
				updateFilterBox={updateFilterBox}
				hexagonRanges={hexagonRanges}
				activeFilterTemplate={activeFilterTemplate}
			/>

			<Input
				label="Calculation multiplier"
				type="number"
				step="0.1"
				min="0"
				defaultValue={data.multiplier}
				id={`filter-box-multiplier-${index}`}
				helperText="Multiplies with total area, useful for calculations like pricing."
				onChange={val => {
					let value = parseFloat(val.target.value);
					// If value is a number and not nan
					if (!value) value = '';
					updateFilterBox('multiplier', value);
				}}
				noWrap
			/>
		</div>
	);
};

export default FormInputs;
