import { useCallback, useEffect } from 'react';

/**
 * Custom hook that listens for a keydown event and runs a callback function if the key matches the target key.
 *
 * @param {*} targetKey key that must be pressed for the callback to run
 * @param {*} condition condition that must be met for the callback to run
 * @param {*} callback callback function to run if the key matches the target key
 */
export default function useKeyboardShortcut(targetKey, condition, callback) {

    const handleKeyDown = useCallback((event) => {
        if (event.key?.toString().toLowerCase() === targetKey.toString().toLowerCase() && condition) {
            callback();
        }
    }, [targetKey, condition, callback]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleKeyDown]);
};
