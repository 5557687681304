import { Tooltip } from 'react-tooltip';

import Toolbar from '@components/toolbar/Toolbar';
import ModelPicker from './modelPicker/ModelPicker';

/**
 * The toolbar in the map editor page. It contains all functionality to interact with the map and the annotations on it on it.
 */

const MapToolbar = ({ children }) => {
	return (
		<Toolbar>
			<ModelPicker />
			<>{children}</>
			<Tooltip id="tooltip-toolbar-root" variant="light" />
		</Toolbar>
	);
};
export default MapToolbar;
