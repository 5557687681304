import styled from 'styled-components';
import { Button, Spinner } from 'react-bootstrap';
import { KeyboardShortcut } from '@routes/userRoutes/projects/singleProject/components/SharedStyles';

const StyledButton = styled(Button)`
	svg {
		margin-top: -4px;
		margin-right: 5px;
	}
`;

export default function UtilityButton({
	icon: Icon,
	label,
	tooltip, //{id, content, place}
	onClick,
	variant,
	disabled = false,
	loading,
	keyboardShortcutLabel,
}) {
	return (
		<StyledButton
			disabled={disabled}
			onClick={onClick}
			variant={variant}
			data-tooltip-id={tooltip?.id}
			data-tooltip-content={tooltip?.content}
			data-tooltip-place={tooltip?.place}>
			<span>
				{Icon && <Icon />}
				{label}
				{keyboardShortcutLabel && (
					<KeyboardShortcut>{keyboardShortcutLabel}</KeyboardShortcut>
				)}
				{loading && <Spinner size="sm" className="ms-2" />}
			</span>
		</StyledButton>
	);
}
