import styled from "styled-components";

import { Header, HeaderText } from "../sidebarElements/SidebarTemplate";
import {HexagonSummaryPreview} from "./HexagonSummarySidebar";

import { formatAreaNumber } from '@utils/helpers';

const Wrapper = styled.aside`
`;

const Content = styled.div`
    padding: 20px;
	display: flex;
	flex-direction: column;
	row-gap: 20px;
`;

const Details = styled.div`
	span{
		text-transform: capitalize;
    	color: ${p => p.theme.colors.textAccent};
	}
    p{
        margin-bottom: 10px;
        display: flex;
        flex-direction: column;
		font-size: 0.9em;
		font-weight: normal;
		line-height: 1.1;
        &:last-of-type{
            margin-bottom: 0;
        }
    }
`;

const SidebarSummary = ({project}) => {

	if (!project) return null;

	function getGSD(project) {
		if (project?.gsd && project.gsd >= 0) {
			const gsdInCentimeters = project.gsd * 100;
		  	return Math.round(gsdInCentimeters * 100) / 100;
		}
		return null;
	}

	const area = project?.area ? formatAreaNumber(project?.area) : null;
	const gsd = getGSD(project);

    return (

        <Wrapper>
            <Header>
                <HeaderText>Summary</HeaderText>
            </Header>

			<Content>
				{!!project?.area && (
					<Details>
						<p><span>Project Base Area</span> {area} m²</p>
						{gsd && <p><span>GSD</span> {gsd} cm</p>}
					</Details>
				)}

				<HexagonSummaryPreview />

			</Content>
        </Wrapper>);
}
export default SidebarSummary;
