import { useQuery } from '@tanstack/react-query';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { AiOutlineDownload } from 'react-icons/ai';

import { StyledModal } from '@components/modal/ModalStyle';
import { adminGetSagmakerFiles } from '@api';

const SagemakerDownloadModal = ({ project_uuid, show, setShow }) => {
	const { data, isLoading, isError, error } = useQuery({
		queryKey: ['details_modal', project_uuid],
		queryFn: () => {
			return adminGetSagmakerFiles(project_uuid);
		},
		enabled: !!project_uuid,
		refetchOnWindowFocus: false,
	});

	return (
		<StyledModal
			variant="dark"
			show={show}
			centered={true}
			onHide={() => {
				setShow(false);
			}}
			size="xl">
			<Modal.Header closeButton closeVariant="white">
				<Modal.Title>Available downloads from Sagemaker</Modal.Title>
			</Modal.Header>

			<Modal.Body>
				{isLoading && <Spinner animation="border" variant="white" />}

				{!isLoading && (
					<>
						{isError && (
							<p>
								Error:{' '}
								{error?.response?.data?.detail ?? 'Unknown'}
							</p>
						)}

						{!isError &&
							data &&
							(data.length > 0 ? (
								<ul className="list-group">
									{data.map(
										(file, index) =>
											file &&
											Object.keys(file).length > 0 && (
												<li
													key={`sagemaker-downloadfile-${index}`}
													className="list-group-item">
													{Object.keys(file).map(
														(key, keyIndex) => (
															<div key={keyIndex}>
																<AiOutlineDownload className="pe-2 h1 mb-0" />
																<a
																	href={
																		file[
																			key
																		]
																	}
																	target="_blank"
																	rel="noreferrer noopener">
																	{key}
																</a>
															</div>
														)
													)}
												</li>
											)
									)}
								</ul>
							) : (
								<p>Ingen filer funnet</p>
							))}
					</>
				)}
			</Modal.Body>

			<Modal.Footer>
				<Button variant="secondary" onClick={() => setShow(false)}>
					Close
				</Button>
			</Modal.Footer>
		</StyledModal>
	);
};
export default SagemakerDownloadModal;
