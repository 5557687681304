/**
 * Filters and sorts search parameters.
 *
 * @param {Map} searchParams - A Map object containing search parameters.
 * @returns {Object} - An object with filtered and sorted search parameters.
 */
export const filterAndSortSearchParams = searchParams => {
	// Return an empty object if searchParams is null or undefined
	if (!searchParams) return {};

	// Initialize an empty object to store sorted search parameters
	const sortedSearchParams = {};

	// Iterate over each key-value pair in the searchParams Map
	searchParams.forEach((value, key) => {
		// Add the key-value pair to sortedSearchParams if the key is not already present and the value is not an empty string
		if (!(key in sortedSearchParams) && value !== '') {
			sortedSearchParams[key] = value;
		}
	});

	// Return the object containing filtered and sorted search parameters
	return sortedSearchParams;
};
