import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { useForm, Controller } from 'react-hook-form';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { registerUser } from '@api';

import Alert from '@components/alert/Alert';
import Input from '@components/form/Input';
import Check from '@components/form/Check';

export default function RegisterForm({
	onSuccess,
	tier = 'pro',
	submitText = 'Register',
}) {
	const { control, handleSubmit } = useForm({
		defaultValues: {
			name: '',
			email: '',
			password: '',
			passwordConfirm: '',
			eula: false,
			terms: false,
		},
	});

	const [errorMessage, setErrorMessage] = useState();

	const registerMutation = useMutation({
		mutationFn: values => registerUser(values),
		onError: err => {
			console.error('err', err);
			setErrorMessage(err?.response?.data?.detail ?? 'Unknown error');
		},
		onSuccess: () => {
			setErrorMessage(null);
			onSuccess();
		},
	});

	const onSubmit = data => {
		setErrorMessage(null);

		const { name, email, password, passwordConfirm, eula, terms } = data;

		if (eula && terms) {
			registerMutation.mutate({
				name,
				email,
				password,
				passwordConfirm,
				tier,
			});
		}
	};

	return (
		<>
			{errorMessage && (
				<Alert variant="danger">
					<p>{errorMessage}</p>
				</Alert>
			)}

			<Form onSubmit={handleSubmit(onSubmit)}>
				<Controller
					name="name"
					control={control}
					render={({ field }) => (
						<Input
							{...field}
							type="text"
							placeholder="Enter name"
							label="Name"
							autoComplete="name"
							required
						/>
					)}
				/>

				<Controller
					name="email"
					control={control}
					render={({ field }) => (
						<Input
							{...field}
							type="email"
							placeholder="Enter email"
							label="Email address"
							autoComplete="username"
							required
						/>
					)}
				/>

				<Controller
					name="password"
					control={control}
					render={({ field }) => (
						<Input
							{...field}
							type="password"
							placeholder="Password"
							label="Password"
							autoComplete="new-password"
							required
						/>
					)}
				/>

				<Controller
					name="passwordConfirm"
					control={control}
					render={({ field }) => (
						<Input
							{...field}
							type="password"
							placeholder="Confirm Password"
							label="Confirm Password"
							autoComplete="new-password"
							required
						/>
					)}
				/>

				<Controller
					name="eula"
					control={control}
					render={({ field }) => (
						<Check
							{...field}
							type="checkbox"
							label={
								<>
									Yes, I agree to the{' '}
									<Link
										to="/eula"
										target="_blank"
										className="inherit">
										Biodrone Portal User Agreement (EULA)
									</Link>
								</>
							}
							id="eula"
							required
						/>
					)}
				/>

				<Controller
					name="terms"
					control={control}
					render={({ field }) => (
						<Check
							{...field}
							type="checkbox"
							label={
								<>
									Yes, I agree to{' '}
									<Link
										to="/privacy-policy"
										target="_blank"
										className="inherit">
										Biodrones Privacy Policy
									</Link>
								</>
							}
							id="terms"
							required
						/>
					)}
				/>

				<Button
					variant="success"
					type="submit"
					className="mt-3"
					disabled={registerMutation.isLoading}>
					{submitText}
				</Button>
			</Form>
		</>
	);
}
