import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { filterAndSortSearchParams } from './helpers';
import SearchField from '@components/filters/SearchField';

const SearchFilter = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const sortedSearchParams = filterAndSortSearchParams(searchParams);

	const timeoutRef = useRef(null);
	const onChange = value => {
		clearTimeout(timeoutRef.current);
		timeoutRef.current = setTimeout(() => {
			if (value === null) return;
			if (value !== '') {
				sortedSearchParams.search = value;
			} else {
				delete sortedSearchParams.search;
			}

			setSearchParams(sortedSearchParams);
		}, 500);
	};

	return (
		<SearchField
			searchParamsId="search"
			setSearch={onChange}
			label="Search project name"
			width="auto"
		/>
	);
};

export default SearchFilter;
