import styled from 'styled-components';

export const CardTitle = styled.h2`
	color: ${props => props.theme.colors.textColor};

	font-size: 1.25rem;
	font-weight: 500;
	line-height: 1.2;

	margin-bottom: 0.5rem;
`;

const StyledProjectDate = styled.p`
	color: ${props => props.theme.colors.textAccent};

	font-size: 1rem;
	font-weight: 500;
	line-height: 1.2;
	font-style: italic;

	margin-bottom: 0;
`;

export const CardDate = ({ created_at, ...props }) => {
	const date = new Date(created_at).toLocaleDateString('nb-NO', {
		day: 'numeric',
		month: 'short',
		year: 'numeric',
	});
	return <StyledProjectDate {...props}>{date}</StyledProjectDate>;
};