import ProgressBar from 'react-bootstrap/ProgressBar';

export default function StorageStatusProgress({
	usedStorage,
	projectFilesSize,
	totalStorage,
}) {
	if (usedStorage + projectFilesSize > totalStorage) {
		return (
			<ProgressBar>
				<ProgressBar now={100} variant="danger" />
			</ProgressBar>
		);
	}

	const usedStoragePercent = Math.round((usedStorage / totalStorage) * 100);
	const projectStoragePercent = Math.round(
		(projectFilesSize / totalStorage) * 100
	);

	return (
		<ProgressBar>
			<ProgressBar now={usedStoragePercent} variant="success" key="2" />

			{projectFilesSize > 0 && (
				<ProgressBar
					now={projectStoragePercent}
					variant="warning"
					key="1"
					striped
				/>
			)}
		</ProgressBar>
	);
}
