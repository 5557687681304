import axios from 'axios';
import { api, baseURL } from './index.js';
import { setRefreshToken, setToken } from '@contexts/Token.context.jsx';

export const registerUser = async fields => {
	const url = baseURL + '/auth/register';

	const {
		name,
		email,
		password,
		passwordConfirm: password_confirm,
		tier,
	} = fields;

	const user = {
		name,
		username: email,
		email,
		password,
		password_confirm,
		tier,
	};

	return await axios.post(url, user);
};

export const requestPasswordReset = async email => {
	const url = baseURL + '/auth/request_reset_password';

	const passwordResetRequest = {
		email,
	};

	const { data } = await axios.post(url, passwordResetRequest);
	return data;
};

export const resetPassword = async fields => {
	const url = baseURL + '/auth/reset_password';

	const {
		email,
		token,
		password,
		passwordConfirm: password_confirm,
	} = fields;
	const passwordResetData = {
		token,
		email,
		password,
		password_confirm,
	};

	const { data } = await axios.post(url, passwordResetData);
	return data;
};

export const verifyUserEmail = async (email, token) => {
	try {
		const url = `${baseURL}/auth/verifyemail?token=${token}&email=${email}`;

		const res = await axios.get(url);

		if (res.status !== 200 && !res.data) {
			throw new Error('Something went wrong');
		}

		return res.data;
	} catch (err) {
		console.error('Error verifying user email', err);
		throw new Error(err);
	}
};

export const loginUser = async fields => {
	try {
		const url = baseURL + '/auth/login';

		const { email, password } = fields;
		const user = {
			email,
			password,
		};

		const res = await axios.post(url, user, {
			withCredentials: false,
			headers: {
				'Content-Type': 'application/json',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Methods':
					'GET,PUT,POST,DELETE,PATCH,OPTIONS',
			},
		});

		if (res.status !== 200) {
			console.error('Server request failed', res);
			throw new Error('Server request failed');
		}
		setToken(res.data.access_token);
		setRefreshToken(res.data.refresh_token);

		return res.data;
	} catch (err) {
		console.error("Couldn't log in user", err);
		throw new Error(err?.response?.data?.detail ?? 'Unknown error');
	}
};

export const setActiveOrg = async (org_id = '') => {
	const url = baseURL + '/auth/users/set_active_org';

	const { data } = await api.put(url, null, { params: { org_id } });
	return data;
};
