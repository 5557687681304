import { useState, useEffect, useContext } from 'react';
import styled, {keyframes, css} from 'styled-components';

import { Button } from 'react-bootstrap';
import {AiFillBell} from 'react-icons/ai';
import Spinner from 'react-bootstrap/Spinner';

import { UserContext } from '../../contexts/User.context';
import Notification from './notification.component';

import useNotify from './notify';

const Backdrop = styled.div`
    display: ${p => p.show ? 'block' : 'none'};
    position: fixed;
    z-index: 98;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
`;
const StyledNotificationsWrapper = styled.aside`
    position: fixed;
    z-index: 99;
    right: 0;
    top: 70px;
    width: 25vw;
    min-width: 426px;
    max-width: 680px;
    max-height: calc(100vh - 70px);
    overflow-y: auto;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    background-color: ${p => p.theme.colors.background};
    box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
    display: ${p => p.show ? 'block' : 'none'};
    
`;
const StyledNotifications = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

const Ring = keyframes`
    0% { transform: rotate(0); }
    1% { transform: rotate(30deg); }
    3% { transform: rotate(-28deg); }
    5% { transform: rotate(34deg); }
    7% { transform: rotate(-32deg); }
    9% { transform: rotate(30deg); }
    11% { transform: rotate(-28deg); }
    13% { transform: rotate(26deg); }
    15% { transform: rotate(-24deg); }
    17% { transform: rotate(22deg); }
    19% { transform: rotate(-20deg); }
    21% { transform: rotate(18deg); }
    23% { transform: rotate(-16deg); }
    25% { transform: rotate(14deg); }
    27% { transform: rotate(-12deg); }
    29% { transform: rotate(10deg); }
    31% { transform: rotate(-8deg); }
    33% { transform: rotate(6deg); }
    35% { transform: rotate(-4deg); }
    37% { transform: rotate(2deg); }
    39% { transform: rotate(-1deg); }
    41% { transform: rotate(1deg); }
    43% { transform: rotate(0); }
    100% { transform: rotate(0); }
`;

const IconWrapper = styled.span`
    position: relative;
    display: block;

    .notifications-loader{
        position: absolute;
        top: 0px;
        right: -6px;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        border: 2px solid ${p => p.theme.colors.background};
        .spinner-border{
            display: block;
        }
    }

    ${p => p.$hasnew && css`
        animation: ${Ring} 4s ease-in-out;
        &:after{
            content: '';
            position: absolute;
            top: 5px;
            right: -1px;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: ${p.theme.colors.green};
            border: 2px solid ${p.theme.colors.background};
        }
        `
    }
`;

const StyledButton = styled(Button)`
    font-size: 1.25rem;
    padding-top: 3px;
    padding-bottom: 3px;
    
    &:hover, &[aria-pressed="true"]{
        background-color: var(--bs-btn-hover-bg);
        ${IconWrapper}:after{
            border-color: var(--bs-btn-hover-bg);
        }
    }
`;

const Loader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 20px;
    font-size: 12px;
    color: ${p => p.theme.colors.textAccent};
`;

const Notifications = () => {
    const { currentUser, notifications, loading: userLoading } = useContext(UserContext);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [hasNew, setHasNew] = useState(false);

    const { checkForJobs } = useNotify();

    /**
     * @description Toggle show state and set hasNew to false if show is true
     */
    const handleShow = () => {
        // User has seen notifications, so set hasNew to false
        if(!!show && hasNew){
            setHasNew(false);
        }

        // Toggle show
        setShow(!show);
    };

    useEffect(() => {
        // Make sure user is logged in and notifications are not loading
        if(!userLoading && !!currentUser?.uuid && !loading ) {
            setLoading(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userLoading]);

    useEffect(() => {
        if(loading) {
            // Check for existing running jobs
            checkForJobs(setLoading(false));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    useEffect(() => {
        if(!!notifications.length && !loading && !!currentUser?.uuid){
            setHasNew(true);
        }
    }, [notifications, loading, currentUser]);

    
    return (
        <>  
            <StyledButton 
                onClick={() => {handleShow()}} 
                type="button" 
                aria-pressed={show} 
                variant="dark"
                title={show ? 'Close notifications' : 'Open notifications'}
                $hasnew={hasNew}
            >
                <IconWrapper className="icon-wrapper" $hasnew={hasNew}>
                    <AiFillBell className="text-light" />
                   
                    {loading && 
                        <div className="notifications-loader">
                            <Spinner animation="border" variant="light" size="sm" role="status">
                                <span className="visually-hidden">Checking for notifications</span>
                            </Spinner>
                        </div>
                    }
                </IconWrapper>
                <span className="visually-hidden">{show ? 'Close notifications' : 'Open notifications'}</span>
            </StyledButton>
            
            <Backdrop show={show} onClick={() => {handleShow()}} />
            <StyledNotificationsWrapper className="p-3 text-white" show={show}>
                {loading && 
                    <Loader>
                        <Spinner animation="border" variant="light" />
                        <p>Checking for notifications</p>
                    </Loader>
                }

                {!loading && (
                    <>
                        {notifications.length > 0 ? (
                            <StyledNotifications>
                                {notifications.map((notification) => {
                                    return (
                                        <li key={notification.uuid}>
                                            <Notification notification={notification} setShow={handleShow} />
                                        </li>
                                    )
                                })}
                            </StyledNotifications>
                        ) : (
                            <p className="text-center">No notifications</p>
                        ) }
                    </>
                )}
            </StyledNotificationsWrapper>
        </>
    );
}

export default Notifications;