const AlertWarningContent = ({ warning, warningResponse }) => {
	if (!warning) return null;

	return (
		<>
			{warningResponse && <p>Error: {warningResponse}</p>}
			<p>
				<small>{warning}</small>
			</p>
			<p>
				<small>
					Despite warnings, you can start your project, but consider
					their implications first.
				</small>
			</p>
		</>
	);
};
export default AlertWarningContent;
