import { getFeatureType, isOutsideBoundingBox } from './helpers';

const handleMapMouseMove = (map, mapRefCurrent, overlay, updateOverlayData) => {
	const handleOverlay = ePixel => {
		let cursorPixelPosition = {
			x: ePixel[0],
			y: ePixel[1],
		};
		cursorPixelPosition = {
			x: cursorPixelPosition.x + mapRefCurrent.offsetLeft,
			y: cursorPixelPosition.y + mapRefCurrent.offsetTop,
		};

		const overlayBoundingBox = overlay
			.get('element')
			.getBoundingClientRect();

		if (isOutsideBoundingBox(overlayBoundingBox, cursorPixelPosition)) {
			overlay.setPosition(undefined);
			updateOverlayData(undefined);
		}
	};

	const handleClusterPin = feature => {
		const closestFeatureData = feature.get('data');
		updateOverlayData(closestFeatureData);
		overlay.setPosition(closestFeatureData.center_coordinate);
	};

	const key = map.on('pointermove', e => {
		// If Overlay Is Visible -> Handle Overlay and return;
		if (overlay?.get('position')) {
			handleOverlay(e.pixel);
			return;
		}

		const featureAtPixel = map.getFeaturesAtPixel(e.pixel)[0];
		if (!featureAtPixel) return;

		switch (getFeatureType(featureAtPixel)) {
			// If feature at pixel is cluster with 1 feature or single feature
			case 'single':
				handleClusterPin(featureAtPixel.get('features')[0]);
				break;

			// If feature at pixel is cluster with more than 1 feature
			case 'cluster':
				mapRefCurrent.style.cursor = 'zoom-in';
				break;

			// If feature at pixel is not a cluster or single feature
			default:
				mapRefCurrent.style.cursor = 'default';
				break;
		}
	});
	return key;
};
export default handleMapMouseMove;
