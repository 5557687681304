import styled from "styled-components";

const Button = styled.button`
    padding: 0;
    width: 100%;
    margin: 0;
    appearance: none;
    background: none;
    display: block;
    border: 0;
    font-weight: 600;
    font-size: 1rem;
    color: inherit;
    text-align: left;

    path{
        stroke: inherit;
    }
`;

const LabelButton = ({label, handleClick, children, ...props}) => {
    return (
        <Button type="button" onClick={handleClick} {...props}>
            {label}
            {children}
        </Button> 
    )
}

export default LabelButton;