// React and React DOM imports
import { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

// OpenLayers imports
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import ImageLayer from 'ol/layer/Image';
import Static from 'ol/source/ImageStatic';
import { Select } from 'ol/interaction';
import { click } from 'ol/events/condition';
import { getCenter, boundingExtent, getTopRight } from 'ol/extent';
import { Polygon } from 'ol/geom';
import { unByKey } from 'ol/Observable';
import { Fill, Stroke, Style, Text } from 'ol/style';

// Utility functions
import {
	convertToRgba,
	imagePositionsToMapCoordinates,
	preventClickOnOverlay,
} from '@utils/map/helpers';
import { createTooltip } from '@utils/map/tooltip.overlay';

// Component imports
import { SelectedImageOverlayTooltip } from './OverlayTooltip';
import MapLoader from '@routes/userRoutes/projects/singleProject/components/MapLoader';

// Context imports
import { useProject } from '@contexts/Project.context';

const setPolygonStyle = (feature, colorScheme) => {
	const color = convertToRgba(colorScheme[feature.get('classId')]?.color);
	feature.setStyle(
		new Style({
			stroke: new Stroke({
				color: color,
				width: 3,
			}),
			fill: new Fill({
				color: 'rgba(255, 255, 255, 0.2)',
			}),
			text: new Text({
				text: `${(feature.get('confidence') * 100).toFixed(1)}%`,
				font: '12px Calibri,sans-serif',
				fill: new Fill({
					color: '#ffffff',
				}),
				stroke: new Stroke({
					color: '#000000',
					width: 3,
				}),
				placement: 'line',
				textAlign: 'left',
				textBaseline: 'bottom',
				overflow: true,
			}),
		})
	);
};

const SelectedSingleImageLayer = ({
	singleImageLayer,
	singleImageLayerTooltipRef,
}) => {
	const {
		colorScheme,
		mapObject,
		defaultProjection,
		selectedSingleImage,
		toolBarVisible,
		dispatch,
	} = useProject();

	const selectInteractionKey = useRef(null);
	const selectedActive = useRef(false);

	const imageLayer = useRef(null);
	const polygonLayer = useRef(null);

	const overlay = useRef(null);
	const overlayRef = useRef(null);

	const [loading, setLoading] = useState(false);
	const [overlayContent, setOverlayContent] = useState(null);

	const updateImage = selectedFeature => {
		// Hide the image layer initially
		imageLayer.current?.setOpacity(0);

		// Hide the single image layer tooltip
		singleImageLayerTooltipRef?.setPosition(undefined);

		// Clear any existing detections
		polygonLayer.current?.setVisible(false);
		polygonLayer.current?.getSource()?.clear();

		// Hide overlay
		overlay.current?.setPosition(undefined);

		// There was a click outside of an image, reset and return
		if (!selectedFeature && selectedActive.current) {
			// Hide the image layer
			imageLayer.current.setVisible(false);
			// Show the single photos layer
			singleImageLayer.setVisible(true);
			// Mark that no feature is selected
			selectedActive.current = false;

			// Show the toolbar
			dispatch({ type: 'setToolBarVisible', payload: true });

			// Stop loading if it was in progress
			if (loading) setLoading(false);

			if (overlayContent) setOverlayContent(null);

			return;
		}

		// Get properties from the selected feature
		const imageValues = selectedFeature?.getProperties();

		// Get the image URL
		const imageUrl = imageValues?.image;
		// If no image, return
		if (!imageUrl) return;

		// Set loading state
		setLoading(true);

		// Hide the toolbar if it is visible
		if (toolBarVisible) {
			dispatch({ type: 'setToolBarVisible', payload: false });
		}

		// Mark that a feature is selected
		selectedActive.current = true;

		// Hide the single photos layer
		singleImageLayer.setVisible(false);

		// Get the extent of the geometry
		const polygonCoordinates = imageValues.polygons;
		const selectedExtent = boundingExtent(polygonCoordinates);

		// Zoom to the selected image
		const view = mapObject.getView();
		const size = mapObject.getSize();
		const resolution = view.getResolutionForExtent(selectedExtent, size);
		const zoom = view.getZoomForResolution(resolution) - 1;
		view.setCenter(getCenter(selectedExtent));
		view.setZoom(zoom);

		// Create the source for the image layer
		const source = new Static({
			url: imageUrl,
			visible: true,
			projection: defaultProjection,
			imageExtent: selectedExtent,
		});
		imageLayer.current.setSource(source);

		// Add HTML content to overlay
		const { name, center, rotation } = imageValues;
		setOverlayContent({
			name,
			center,
			rotation,
		});

		// Check if the selected image has detections
		const detections = imageValues.detections;
		if (detections?.features?.length > 0) {
			// Get the source and clear it
			const polygonVectorSource = polygonLayer.current.getSource();
			polygonVectorSource.clear();

			// Add the detections to the source
			detections.features.forEach(detection => {
				const polygonPositionTranslatedToMapCoordinates =
					imagePositionsToMapCoordinates(
						detection.geometry.coordinates,
						selectedExtent
					);

				const polygon = new Polygon(
					polygonPositionTranslatedToMapCoordinates
				);
				const polygonFeature = new Feature({
					geometry: polygon,
					data: detection.properties,
					classId: detection.properties.classid,
					className: detection.properties.classname,
					confidence: detection.properties.confidence,
				});
				setPolygonStyle(polygonFeature, colorScheme);
				polygonVectorSource.addFeature(polygonFeature);
			});
		}

		// Function to handle image load events
		const handleImageLoad = event => {
			const { type } = event;

			if (type === 'imageloadend') {
				// Set the image layer opacity to fully visible
				imageLayer.current.setOpacity(1);
				if (detections?.features?.length > 0) {
					// Make the polygon layer visible if there are detections
					polygonLayer.current.setVisible(true);
				}

				// Position overlay over the image
				const topRight = getTopRight(selectedExtent);
				overlay.current.setPosition(topRight);
			}

			// Stop loading state
			setLoading(false);
		};

		// Attach event listeners to the source
		source.on('imageloadend', handleImageLoad);
		source.on('imageloaderror', handleImageLoad);

		// Make the image layer visible
		imageLayer.current.setVisible(true);
	};

	const initializeLayers = useCallback(() => {
		// Create the necessary layers if they don't exist
		if (!imageLayer.current) {
			imageLayer.current = new ImageLayer({
				source: null,
				zIndex: 21,
				visible: false,
				image: {
					rotateWithView: true,
				},
			});
			mapObject.addLayer(imageLayer.current);

			console.log('added selected image layer');
		}

		if (!polygonLayer.current) {
			polygonLayer.current = new VectorLayer({
				source: new VectorSource(),
				zIndex: 24,
				visible: false,
				name: 'Detection results',
				properties: {
					customLayerId: 'polygonResultsLayer',
				},
			});
			mapObject.addLayer(polygonLayer.current);
		}

		// If the select interaction is not set, set it
		if (!selectInteractionKey.current) {
			// Custom condition function
			const customCondition = mapBrowserEvent => {
				// Prevent selection if the click happened on an overlay
				if (preventClickOnOverlay(mapBrowserEvent)) return false;

				// Default click condition
				return click(mapBrowserEvent);
			};

			const selectInteraction = new Select({
				layers: [singleImageLayer],
				condition: customCondition,
			});
			mapObject.addInteraction(selectInteraction);

			const interactionKey = selectInteraction.on('select', e => {
				if (e.selected.length > 0) {
					// If a feature is already selected, return
					if (selectedActive.current) return;

					// A feature was clicked, show the image
					const selectedFeature = e.selected[0];
					dispatch({
						type: 'setSelectedSingleImage',
						payload: selectedFeature,
					});
				} else {
					dispatch({ type: 'setSelectedSingleImage', payload: null });
				}
			});

			selectInteractionKey.current = interactionKey;
		}

		// Create the overlay for the tooltip
		if (!overlay.current && overlayRef.current) {
			overlay.current = createTooltip({
				mapRef: mapObject,
				tooltipRef: overlayRef.current,
				offset: [0, 0],
				positioning: 'bottom-right',
				id: 'selected-image-overlay',
			});
		}
	}, [singleImageLayer]);

	const updatePolygonStyles = useCallback(() => {
		const polygonVectorSource = polygonLayer.current?.getSource();

		if (!polygonVectorSource || !colorScheme) return;
		polygonVectorSource.getFeatures()?.forEach(feature => {
			setPolygonStyle(feature, colorScheme);
		});
	});

	useEffect(() => {
		if (singleImageLayer) {
			initializeLayers();
		}
	}, [initializeLayers]);

	useEffect(() => {
		updatePolygonStyles(colorScheme);
	}, [colorScheme, polygonLayer.current]);

	useEffect(() => {
		updateImage(selectedSingleImage);
	}, [selectedSingleImage]);

	useEffect(() => {
		return () => {
			// Clean up on unmount

			console.log('cleaning up selected image layer');

			// Reset all refs
			selectedActive.current = false;
			if (imageLayer.current) {
				mapObject.removeLayer(imageLayer.current);
				imageLayer.current = null;
			}
			if (polygonLayer.current) {
				mapObject.removeLayer(polygonLayer.current);
				polygonLayer.current = null;
			}
			if (singleImageLayer) {
				singleImageLayer.setVisible(true);
			}

			if (selectInteractionKey.current) {
				unByKey(selectInteractionKey.current);
				selectInteractionKey.current = null;
			}

			if (overlay.current) {
				mapObject.removeOverlay(overlay.current);
				overlay.current = null;
				setOverlayContent(null);
			}
		};
	}, []);

	return (
		<>
			{loading && (
				<MapLoader>
					<p className="mt-4 mb-0">
						Loading image.. <br />
						Load time depends on image size
					</p>
				</MapLoader>
			)}

			<div ref={overlayRef}>
				<SelectedImageOverlayTooltip overlayContent={overlayContent} />
			</div>
		</>
	);
};
export default SelectedSingleImageLayer;
