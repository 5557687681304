import { useSearchParams } from 'react-router-dom';

const useSetSearchParams = () => {
    const [searchParams, setSearchParams] = useSearchParams();
  
    const setSearchParamsWithoutOverwrite = (newParams) => {
      const existingParams = Object.fromEntries(searchParams);
      const updatedParams = { ...existingParams, ...newParams };
      setSearchParams(updatedParams);
    };
  
    return setSearchParamsWithoutOverwrite;
  };
  
  export default useSetSearchParams;