import { Link } from "react-router-dom";

const Footer = () => {
    return (
        <footer className="pt-5 pb-2 w-100 text-center">
            <nav className="d-flex w-100 gap-3 align-items-center justify-content-center">
                <Link to="/contact" className="text-muted small">
                    Contact Us
                </Link>
                <span>|</span>
                <Link to="/privacy-policy" className="text-muted small">
                    Privacy Policy
                </Link>
                <span>|</span>
                <Link to="/eula" className="text-muted small">
                    EULA
                </Link>
            </nav>
        </footer>
    );
}

export default Footer;