import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import { CloseIcon } from '../sidebars/sidebarElements/SidebarTemplate';
import { useProject } from '@contexts/Project.context';
import {
	Container,
	OuterContainer,
	StyledCheckbox,
	StyledHeader,
} from './SliderTool';

const SortSingleImage = ({ tools, setTools }) => {
	const { filteredImages, pickedModelClasses, dispatch } = useProject();

	const DETECTIONS_ASCENDING = 'Detections ascending';
	const DETECTIONS_DESCENDING = 'Detections descending';

	const [sortingRun, setSortingRun] = useState(0);

	const [sortOptions, setSortOptions] = useState([
		{ name: DETECTIONS_ASCENDING, isActive: false },
		{ name: DETECTIONS_DESCENDING, isActive: false },
	]);

	useEffect(() => {
		if (sortOptions.some(option => option.isActive) && sortingRun === 0) {
			const activeSortingOption = sortOptions.filter(
				option => option.isActive
			)[0];
			dispatch({
				type: 'setFilteredImages',
				payload: sortImages(activeSortingOption.name),
			});
			setSortingRun(1);
		} else {
			setSortingRun(0);
		}
	}, [filteredImages]);

	useEffect(() => {
		if (pickedModelClasses?.length > 0) {
			setSortOptions(
				sortOptions.map(sortOption => ({
					...sortOption,
					isActive: false,
				}))
			);
		}
	}, [pickedModelClasses]);

	const closeSort = () =>
		setTools(tools.map(tool => ({ ...tool, isActive: false })));

	const handleSortImages = e => {
		const sortOptionChosen = e.target.value;
		dispatch({
			type: 'setFilteredImages',
			payload: sortImages(sortOptionChosen),
		});
		setSortOptions(
			sortOptions.map(sortOption =>
				sortOption.name === sortOptionChosen
					? { ...sortOption, isActive: !sortOption.isActive }
					: { ...sortOption, isActive: false }
			)
		);
	};

	const sortImages = sortOption => {
		return filteredImages.toSorted((a, b) => {
			if (
				(!a.get('detections') && !b.get('detections')) ||
				a.get('detections')?.features.length ===
					b.get('detections')?.features.length
			) {
				return 0;
			} else if (!a.get('detections')) {
				return 1;
			} else if (!b.get('detections')) {
				return -1;
			} else {
				switch (sortOption) {
					case DETECTIONS_ASCENDING:
						return a.get('detections')?.features.length >
							b.get('detections')?.features.length
							? 1
							: -1;
					case DETECTIONS_DESCENDING:
						return a.get('detections')?.features.length >
							b.get('detections')?.features.length
							? -1
							: 1;
					default:
						break;
				}
			}
		});
	};

	if (!tools.filter(tool => tool.name === 'sorting')[0]?.isActive) {
		return null;
	}

	return (
		<OuterContainer>
			<Container>
				<StyledHeader>
					<h2>Sort Images</h2>
					<CloseIcon onClick={closeSort} role="button" />
				</StyledHeader>
				<Form>
					{sortOptions?.map((sortOption, index) => (
						<StyledCheckbox
							type="radio"
							name="sortGroup"
							key={sortOption.name}
							defaultChecked={sortOption.isActive}
							label={sortOption.name}
							value={sortOption.name}
							onChange={handleSortImages}
							id={`sort-type-${index}`}
						/>
					))}
				</Form>
			</Container>
		</OuterContainer>
	);
};

export default SortSingleImage;
