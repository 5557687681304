import pLimit from 'p-limit';
import { Fill, Stroke, Style, Icon, Circle as CircleStyle } from 'ol/style';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { fromLonLat } from 'ol/proj';
import arrowIcon from '@assets/arrow.svg';

import { fetchUrl } from '@api/files';

const limit = pLimit(5); // Limit to 5 concurrent requests

/**
 * Fetches an image from a compressed URL, and falls back to a full URL if the compressed image is not found.
 * @param {string} compressedUrl - The URL of the compressed image.
 * @param {string} fullUrl - The URL of the full image.
 * @param {AbortSignal} signal - The signal to abort the fetch request.
 * @returns {Promise<Object|null>} - The fetched image object or null if both fetches fail.
 */
const fetchImageWithFallback = async (compressedUrl, fullUrl, signal) => {
	try {
		// Attempt to fetch the compressed image
		const image = await fetchUrl(compressedUrl, signal);

		// Check if the fetched image has a URL
		if (!image?.url) {
			throw new Error('No image found');
		}

		// Return the fetched compressed image
		return image;
	} catch (error) {
		// Log a warning if fetching the compressed image fails
		console.warn(
			'Error fetching compressed image, trying full image:',
			error
		);

		try {
			// Attempt to fetch the full image as a fallback
			return await fetchUrl(fullUrl, signal);
		} catch (error) {
			// Log an error if fetching the full image also fails
			console.error('Error fetching full image:', error);

			// Return null if both fetch attempts fail
			return null;
		}
	}
};

const constructUrl = (projectUuid, path, imageName) => {
	return `filelink?key=${projectUuid}/${path}/${imageName}`;
};

/**
 * Fetches image data for a list of grid features.
 * @param {Array} gridFeatures - The list of grid features.
 * @param {string} projectUuid - The UUID of the project.
 * @param {AbortSignal} signal - The signal to abort the fetch requests.
 * @returns {Promise<Array>} - A promise that resolves to an array of grid features with image data.
 */
export const fetchFeaturesImageData = async ({
	gridFeatures,
	projectUuid,
	signal,
	updateLoadedImages,
	updateImagesNotReady,
}) => {
	let notLoadedImages = 0;

	// Map over the grid features and fetch image data for each feature
	const promises = [];

	for (const feature of gridFeatures) {
		promises.push(
			limit(async () => {
				// Extract the image name from the feature properties
				const imageName = feature.properties.tile_name;

				// Construct the URL for the thumbnail image
				const thumbnailUrl = constructUrl(
					projectUuid,
					'images/thumbnails',
					imageName
				);

				let thumbnail;
				try {
					// Fetch the thumbnail image
					thumbnail = await fetchUrl(thumbnailUrl, signal);
				} catch (error) {
					console.error('Error fetching thumbnail image:', error);
				}

				if (!thumbnail?.url) {
					notLoadedImages++;

					// If many images are not reachable, the project is propably not ready yet
					if (notLoadedImages >= 10) {
						limit.clearQueue();
						updateImagesNotReady();
					}

					return null;
				}

				// Construct the URLs for the compressed and full images
				const compressedUrl = constructUrl(
					projectUuid,
					'images/compressed',
					imageName
				);
				const fullUrl = constructUrl(projectUuid, 'images', imageName);

				// Fetch the image with fallback to the full image if the compressed image is not found
				const image = await fetchImageWithFallback(
					compressedUrl,
					fullUrl,
					signal
				);

				if (!image?.url) {
					notLoadedImages++;
					return null;
				}

				updateLoadedImages();

				// Return the feature with the fetched thumbnail and image URLs
				return {
					...feature,
					thumbnail: thumbnail.url,
					image: image.url,
				};
			})
		);
	}

	// Wait for all promises to resolve and return the results
	return Promise.all(promises);
};

/**
 * Generates styles for map features based on their properties and the map's zoom level.
 * @param {Object} mapObject - The map object.
 * @returns {Function} - A function that takes a feature and returns an array of styles.
 */
export const styleFunction = mapObject => feature => {
	// Determine if the feature has detections
	const isDetect = !!feature.get('detections');

	// Base style with a circle, green if detections exist, yellow otherwise
	const styles = [
		new Style({
			image: new CircleStyle({
				radius: 8,
				fill: new Fill({
					color: isDetect ? 'green' : 'yellow',
				}),
				stroke: new Stroke({ color: 'black', width: 1 }),
			}),
			zIndex: 14,
		}),
	];

	// Get the current zoom level of the map
	const view = mapObject.getView();
	const zoom = view.getZoom();
	const minZoom = 18; // Minimum zoom level to display the arrow icon

	// Add an arrow icon if the zoom level is greater than the minimum zoom level
	if (zoom > minZoom) {
		const rotation = feature.get('rotation');

		styles.push(
			new Style({
				image: new Icon({
					src: arrowIcon,
					scale: 1.2,
					anchor: [0.5, 1],
					anchorXUnits: 'fraction',
					anchorYUnits: 'fraction',
					rotation: rotation * (Math.PI / 180), // Convert rotation to radians
				}),
				zIndex: 15,
			})
		);
	}

	// Return the array of styles
	return styles;
};

/**
 * Creates a new feature for the single image layer.
 *
 * @param {Object} data - The data for the feature.
 * @param {string} defaultProjection - The default projection for the map.
 * @returns {Feature} - The created feature.
 */
export const createFeature = (data, defaultProjection) => {
	// Destructure the data object to extract necessary properties
	const { image, thumbnail, properties, geometry } = data;
	const {
		tile_name: imageName,
		center_longitude,
		center_latitude,
		gimbal_yaw_degrees,
		id,
	} = properties;
	const { coordinates } = geometry;

	// Create a new feature with the provided data
	const feature = new Feature({
		geometry: new Point(
			// Convert the longitude and latitude to the default projection
			fromLonLat([center_longitude, center_latitude], defaultProjection)
		),
		image: image,
		thumbnail: thumbnail,
		polygons: coordinates[0],
		name: imageName,
		rotation: gimbal_yaw_degrees,
		uuid: id,
		center: [center_latitude, center_longitude],
	});

	return feature; // Return the created feature
};
