import {
	useReactTable,
	flexRender,
	getCoreRowModel,
	getSortedRowModel,
} from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
	StyledTable,
	StyledTableHeader,
	StickyTableHeaderWrapper,
} from '../../sharedComponents/SharedStyledComponents';
import CopyButton from '../../sharedComponents/CopyButton';
import DropdownMenu from './modify/DropdownMenu';
import CellContent from '../../sharedComponents/CellContent';
import ProjectThumbnail from '@components/projectComponents/ProjectThumbnail';

const StyledProjectThumbnail = styled(ProjectThumbnail)`
	width: 3.125rem;
	height: 3.125rem;

	& > .fallback {
		padding-inline: 0.2rem;
	}
`;

const SmallText = styled.span`
	font-size: 0.8em;
	color: ${props => props.theme.colors.textAccent};
`;

const ProjectsTable = ({ data, loading }) => {
	// Define columns for the table
	const columns = [
		{
			header: 'Title',
			accessorKey: 'title',
			cell: ({ row, getValue }) => {
				// If title return link with tiltle and thumbnail
				return (
					<>
						<StyledProjectThumbnail uuid={row.original.uuid} />

						<Link
							to={`/projects/${row.original.uuid}`}
							title="View on map"
							className="inherit">
							{getValue()}
						</Link>
					</>
				);
			},
		},
		{
			header: 'Uuid',
			accessorKey: 'uuid',
			copy: true,
		},
		{
			header: 'Owner',
			accessorKey: 'owner',
			cell: ({ getValue }) => {
				// If is owner, return a button that copies the cell value to the clipboard
				const value = getValue();
				if (!value) return null;

				return (
					<div className="uuid">
						{value?.user_name}{' '}
						{value?.org_name && <> - ({value?.org_name})</>}
						<br />
						<CopyButton value={value?.user_uuid}>
							<SmallText>User id: {value?.user_uuid}</SmallText>
						</CopyButton>
						{value?.org_uuid && (
							<CopyButton value={value?.org_uuid}>
								<SmallText>
									Org uuid: {value?.org_uuid}
								</SmallText>
							</CopyButton>
						)}
					</div>
				);
			},
		},
		{
			header: 'Created',
			accessorKey: 'created_at',
			dateFormat: 'LLL d, yy',
		},
		{
			header: 'Area',
			accessorKey: 'area',
			getFixedNumber: true,
		},
		{
			header: 'Archived',
			accessorKey: 'deleted',
		},
	];

	const table = useReactTable({
		columns,
		data,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
	});

	return (
		<StickyTableHeaderWrapper>
			<StyledTable
				borderless
				hover
				responsive
				$loading={loading}
				size="sm">
				<thead>
					{table.getHeaderGroups().map(headerGroup => (
						<tr key={headerGroup.id}>
							{headerGroup.headers.map(column => {
								if (column.id === 'deleted') return null;
								return (
									<th key={column.id}>
										<StyledTableHeader>
											{flexRender(
												column.column.columnDef.header,
												column.getContext()
											)}
										</StyledTableHeader>
									</th>
								);
							})}
							<th colSpan="1" role="columnheader">
								<span className="visually-hidden">
									Dropdownmenu
								</span>
							</th>
						</tr>
					))}
				</thead>
				<tbody>
					{table.getRowModel().rows.map(row => {
						return (
							<tr
								key={row.id}
								className={
									row.original.deleted ? 'archived' : ''
								}>
								{row.getVisibleCells().map(cell => {
									if (cell.column.id === 'deleted')
										return null;

									return (
										<td
											key={cell.id}
											className="custom-cell">
											<div className={cell.column.id}>
												<CellContent
													cell={cell}
													data={row.original}
												/>
											</div>
										</td>
									);
								})}
								<td role="cell" className="custom-cell">
									<DropdownMenu data={row.original} />
								</td>
							</tr>
						);
					})}
				</tbody>
			</StyledTable>
		</StickyTableHeaderWrapper>
	);
};
export default ProjectsTable;
