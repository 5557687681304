import { useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import styled from 'styled-components';

import { AiOutlineDelete } from 'react-icons/ai';

import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Input from '@components/form/Input';
import Select from '@components/form/Select';

import View from '@components/layout/views/View';

import ArchiveProjectDialog from '@components/archiveProject/ArchiveProject';

import { getProject, updateProject, getUserTags } from '@utils/api';
import { useAuth } from '@contexts/User.context';
import Loader from '@components/common/Loader';

const StyledActionsWrapper = styled.div`
	margin-top: calc(var(--bs-gutter-x) * 2);
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const EditProject = () => {
	const { tierTrial } = useAuth();

	const { project_uuid } = useParams();
	const navigate = useNavigate();

	const [showArchiveModal, setShowArchiveModal] = useState(false);

	const [alertMessage, setAlertMessage] = useState(null);

	const {
		data: project,
		isLoading,
		refetch,
		isError,
	} = useQuery({
		queryKey: ['edit_project', project_uuid],
		queryFn: async () => await getProject(project_uuid),
		enabled: !!project_uuid,
	});

	const { data: userTags } = useQuery({
		queryKey: ['user_tags'],
		queryFn: async () => await getUserTags(),
		enabled: !!project,
		retry: false,
	});

	const availableTags = userTags?.tags ?? []; // All the tags that the user has
	const allowCreateTags = userTags?.allowCreate; // If the user is allowed to create new tags

	const mutation = useMutation({
		mutationFn: data => updateProject({ uuid: project_uuid, ...data }),
		onSuccess: () => {
			setTimeout(() => {
				setAlertMessage({
					variant: 'success',
					text: 'Project was updated',
				});
				refetch();
			}, 500);
		},
		onError: () => {
			setTimeout(() => {
				setAlertMessage({
					variant: 'danger',
					text: 'Project was not updated. Try again later.',
				});
			}, 500);
		},
	});

	const { control, handleSubmit } = useForm({
		defaultValues: {
			title: project?.title ?? '',
			description: project?.description ?? '',
			tags: project?.tags || [],
		},
		values: project,
	});

	const onSubmit = data => {
		setAlertMessage(null);

		mutation.mutate(data);
	};

	if (isLoading) {
		return (
			<View>
				<Loader message="Loading project" />
			</View>
		);
	}

	return (
		<View
			maxWidth={550}
			title={
				project
					? `Edit project details for ${project.title}`
					: 'Error loading project'
			}>
			{!isError && (
				<>
					{alertMessage && (
						<Alert variant={alertMessage.variant}>
							{alertMessage.text}
						</Alert>
					)}
					<Form onSubmit={handleSubmit(onSubmit)}>
						<Controller
							name="title"
							control={control}
							render={({ field }) => (
								<Input
									{...field}
									value={field.value || ''}
									type="text"
									label="Project Name"
									required
								/>
							)}
						/>

						<Controller
							name="description"
							control={control}
							render={({ field }) => (
								<Input
									{...field}
									value={field.value || ''}
									type="text"
									label="Project Description"
								/>
							)}
						/>
						<Controller
							name="tags"
							control={control}
							render={({ field }) => (
								<Select
									{...field}
									value={field.value?.map(t => ({
										value: t,
										label: t,
									}))}
									onChange={e => {
										field.onChange(e.map(tag => tag.label));
									}}
									id="project-tags"
									label="Project tags"
									options={availableTags
										?.filter(
											tag => !field.value?.includes(tag)
										)
										?.map((tag, index) => {
											return {
												value: `${tag}-${index}`,
												label: tag,
											};
										})}
									noOptionsMessage={() =>
										allowCreateTags
											? 'Start typing to add a tag'
											: 'No tags found'
									}
									isMulti
									creatable={allowCreateTags}
								/>
							)}
						/>

						<StyledActionsWrapper>
							<div>
								<Button
									type="button"
									variant="danger"
									size="sm"
									className="d-flex align-items-center"
									disabled={tierTrial}
									onClick={() => setShowArchiveModal(true)}>
									<AiOutlineDelete
										className="me-1"
										aria-hidden="true"
									/>
									<span>Delete project</span>
								</Button>
							</div>

							<div>
								<Button
									type="button"
									variant="secondary"
									className="m-1"
									onClick={() => navigate(-1)}>
									Back
								</Button>
								<Button
									type="submit"
									variant="success"
									className="m-1"
									disabled={mutation.isLoading}>
									{mutation.isLoading ? 'Saving...' : 'Save'}
								</Button>
							</div>
						</StyledActionsWrapper>
					</Form>
				</>
			)}

			{showArchiveModal && (
				<ArchiveProjectDialog
					callBack={success => {
						setShowArchiveModal(false);
						if (success) navigate(-1);
					}}
					project={{
						id: project_uuid,
						title: project?.title,
					}}
				/>
			)}
		</View>
	);
};

export default EditProject;
