import RangeSlider from '@components/rangeSlider/RangeSlider';

const RangeSliders = ({
	data,
	updateFilterBox,
	hexagonRanges,
	activeFilterTemplate,
}) => {
	const heightRange = hexagonRanges?.height;
	const countRange = hexagonRanges?.count;

	return (
		<>
			{heightRange?.min != null && heightRange?.max != null && (
				<RangeSlider
					label={
						<>
							Mean Height{' '}
							<span className="small text-muted">(m)</span>
						</>
					}
					step={0.01}
					analasysRangeLimit={heightRange}
					boxData={data.height}
					activeFilterTemplate={activeFilterTemplate?.height}
					onChange={values => {
						// Update the height values in the filter box
						updateFilterBox('height', {
							min: values[0],
							max: values[1],
						});
					}}
				/>
			)}

			{countRange.min != null && countRange.max != null && (
				<RangeSlider
					label="Count"
					step={1}
					analasysRangeLimit={countRange}
					boxData={data.count}
					activeFilterTemplate={activeFilterTemplate?.count}
					onChange={values => {
						updateFilterBox('count', {
							min: values[0],
							max: values[1],
						});
					}}
				/>
			)}
		</>
	);
};

export default RangeSliders;
