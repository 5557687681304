import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useQuery, useMutation } from '@tanstack/react-query';

import { TbPhotoPin, TbMapPins } from 'react-icons/tb';

import Button from 'react-bootstrap/Button';
import ProgressBar from 'react-bootstrap/ProgressBar';

import {
	createProject,
	archiveProject,
	bulkUploadImagesToS3,
	updateProjectWithS3Usage,
	startPostProcessing,
	getImageStatistics,
	getUserStorage,
	getMonthlyProjectsLimit,
} from '@api';

import { getExifData } from './utils/exif';

import useNotify from '@components/notifictions/notify';

import View from '@components/layout/views/View';
import Loader from '@components/common/Loader';

import { FormInputs, FormActions } from './components/Form';
import { ProjectLimitWarning } from './components/ProjectLimit';
import LimitInfo from '@components/subscription/LimitInfo';

import Storage from './components/Storage';
import Area from './components/Area';
import DatasetQuality from './components/DatasetQuality';
import SingleImageFootprint from './components/SingleImageFootprint';

import { useAuth } from '@contexts/User.context';
import { ProjectMode } from '@contexts/Project.context';

const Row = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 40px;
	position: relative;
`;

const LeftCol = styled.div`
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	align-self: stretch;
	gap: 0.25rem;
`;

const RightCol = styled.div`
	min-height: 100%;
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	gap: 20px;
`;

const ProjectChoice = styled.div`
	margin-bottom: 2em;
	width: 100%;
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 1em;

	button {
		transition: all 0.4s;
		position: relative;
		padding-top: 1rem;
		padding-bottom: 4rem;
		svg {
			transition: all 0.4s;
			width: 60px;
			height: 60px;
			margin-bottom: 10px;
			color: ${p => p.theme.colors.textAccent};
		}
		h3 {
			margin: 0;
			transition: all 0.4s;
			.small {
				font-size: 0.7em;
			}
		}

		p {
			transition: all 0.2s;
			position: absolute;
			width: 50%;
			left: 25%;
			@media screen and (max-width: 1399px) {
				width: 80%;
				left: 10%;
			}
		}
	}

	.project-choise-title {
		display: flex;
		flex-direction: row;
		width: 100%;
		align-items: center;
		justify-content: center;
		gap: 0.5em;
	}

	${p =>
		p.modeselected &&
		`
		button{
			padding-top: 0.5rem;

			&.active{
				background-color: ${p.theme.colors.green};
				svg{
					color: #ffffff;
				}
			}
			&:hover{
				p{
					opacity: 1;
					transition: all 0.8s;
				}
			}
			&:not(:hover){
				padding-bottom: 0rem;

				h3{
					transform: scale(0.7) translateX(-1.5rem);
				}
				svg{
					transform: scale(0.7) translateX(1.5rem);
				}
				p{
					opacity: 0;
					margin-top: -1.5em;
					margin-bottom: 0;
				}
			}
		}
	`}
`;

const StepHeading = styled.h4`
	color: ${p => p.theme.colors.green};
	span {
		color: ${p => p.theme.colors.textAccent};
		font-weight: 300;
	}
`;

/**
 *
 * Route for creating a new project
 */
export default function NewProject() {
	const { checkForJobs } = useNotify();

	const { isEnterprise, tierPro, currentUser, subscription } = useAuth();

	const navigate = useNavigate();

	// Form fields that needs a state
	const [files, setFiles] = useState([]);
	const totalFilesSize = useMemo(
		() => files.reduce((acc, file) => acc + file.size, 0),
		[files.length]
	);
	const [progress, setProgress] = useState(-1);
	const [isOrthophoto, setIsOrthophoto] = useState(false);
	const [mode, setMode] = useState(null); // orthophoto or single photo
	const [exifData, setExifData] = useState(null);

	// States that are used for disabling buttons and showing loading
	const [submitting, setSubmitting] = useState(false);
	const [calculatingArea, setCalculatingArea] = useState(false);

	// State for the exif footprint
	const [exifFootprint, setExifFootprint] = useState(null);

	const storageQuery = useQuery({
		queryKey: ['userStorage'],
		queryFn: getUserStorage,
		enabled: tierPro,
		refetchOnWindowFocus: false,
	});

	const { data: monthlyProjectsLimit, isLoading: projectLimitLoading } =
		useQuery({
			queryKey: ['userMonthlyProjectsLimit'],
			queryFn: getMonthlyProjectsLimit,
			enabled: tierPro,
			refetchOnWindowFocus: false,
		});
	const limitReached =
		monthlyProjectsLimit?.limit <= monthlyProjectsLimit?.monthly_projects;
	// Determine the image limit based on the project mode
	const imageLimit =
		mode === ProjectMode.ORTHOPHOTO
			? monthlyProjectsLimit?.image_limit || 0 // Use the monthly project limit if available, default to 0 if not
			: 1000; // Single image projects have a limit of 1000 images

	// Query for creating a project
	const createProjectMutation = useMutation({
		mutationFn: createProject,
	});

	// Mutation for archiving a project
	const archiveProjectMutation = useMutation({
		mutationFn: projectId => {
			return archiveProject(projectId);
		},
		onError: async err => {
			console.error(err);
		},
	});

	const selectMode = mode => {
		setMode(mode);
		// Reset states when changing mode
		setFiles([]);
		setExifFootprint(null);
		setExifData(null);
		setIsOrthophoto(false);
	};

	// Submit the project
	const submitProject = async e => {
		e.preventDefault();

		// Prevent submitting if the button is disabled and someone tries to submit
		if (isDisabled()) return;

		// Get the form data
		const formData = new FormData(e.target);
		const projectName = formData.get('projectName');
		const projectDescription = formData.get('projectDescription');
		const dsm = null; //formData.get('dsm') === 'on'; Use this later
		const dtm = null; //formData.get('dsm') === 'on'; Use this later
		const fastOrtophoto = formData.get('fastOrtophoto') === 'on';
		const resolution = formData.get('resolution');
		const ignoreGsd = null; //formData.get('ignoreGsd') === 'on'; Use this later
		const tiles = null; //formData.get('tiles') === 'on'; Use this later
		const meshSize = formData.get('meshSize');

		// If no files exist then return
		if (files.length === 0) return;

		const isOrthophotoProject = mode === ProjectMode.ORTHOPHOTO;
		const isSinglePhotoProject = mode === ProjectMode.SINGLE_IMAGE;

		try {
			// If not orthophoto and files is less than 25 then alert and return. Applicable only for orthophoto procect
			if (isOrthophotoProject && !isOrthophoto && files.length < 25) {
				throw new Error(
					'You need to upload at least 25 images to create a project.'
				);
			} else if (isSinglePhotoProject && files.length < 1) {
				throw new Error(
					'You need to upload at least 1 image to create a project.'
				);
			}

			setSubmitting(true);

			// Create project
			console.log('creating project');

			//@TODO: remove the only Allskog part later
			const wms_publish =
				isEnterprise && currentUser.active_org_name === 'Allskog'; // Only publish WMS for enterprise accounts

			const { uuid: projectId } = await createProjectMutation.mutateAsync(
				{
					title: projectName,
					description: projectDescription,
					file_storage: totalFilesSize,
					wms_publish,
					orthophoto_project: isOrthophotoProject,
				}
			);

			if (!projectId) {
				throw new Error('Failed to create project. Please try again.');
			}

			try {
				console.log('uploading images');
				await bulkUploadImagesToS3(
					files,
					projectId,
					setProgress,
					isOrthophoto
				);
				console.log('images uploaded');

				console.log('update project with storage usage from S3');
				await updateProjectWithS3Usage(projectId, isOrthophoto);
				console.log('project updated with storage usage from S3');
			} catch (error) {
				console.error('Error uploading images', error);
				const err = new Error(
					'Failed to upload images. Please try again.'
				);
				err.projectId = projectId;
				throw err;
			}

			try {
				console.log('starting project post processing');
				await startPostProcessing({
					project_id: projectId,
					image_count: files.length,
					fastOrtophoto,
					isOrthophotoProject,
					dsm,
					dtm,
					resolution,
					ignoreGsd,
					meshSize,
					tiles,
					uploadPreprocessedPhoto: isOrthophoto,
					ortophotoSize: isOrthophoto ? files[0].size : null,
					isSinglePhotoProject,
					exifData,
				});
				console.log('project post processing started');
			} catch (error) {
				console.error('Error starting project post processing', error);
				const err = new Error(
					'Failed to start project post processing. Please try again.'
				);
				err.projectId = projectId;
				throw err;
			}

			console.log('checking for jobs');
			checkForJobs();
			console.log('jobs checked');

			navigate(`/projects`);
		} catch (error) {
			console.error('Project creation error', error);

			// Makes sure that an unfinished project creation is archived if the upload fails
			// projectId is set in the error object because it is not possible to pass it to the catch block otherwise
			if (error.projectId) {
				console.log(
					'Archiving project due to error in project creation'
				);
				archiveProjectMutation.mutate(error.projectId);
			}

			window.alert(
				error?.response?.data?.detail ||
					error.message ||
					'Failed to create project. Please try again.'
			);
		} finally {
			setSubmitting(false);
			setProgress(-1);
		}
	};

	// Check if the submit button should be disabled
	const isDisabled = () => {
		if (submitting || calculatingArea || isNotEnoughStorage) {
			return true;
		}

		return false;
	};

	// Check if the user has enough storage
	const usedStorage = storageQuery?.data?.used ?? null;
	const totalStorage = subscription?.product_metadata?.storage_limit ?? 0;
	const isNotEnoughStorage = useMemo(() => {
		if (tierPro) {
			return usedStorage + totalFilesSize > totalStorage;
		}
		return false;
	}, [totalFilesSize]);

	useEffect(() => {
		if (files.length > 0) {
			setCalculatingArea(true);

			getExifData(files)
				.then(async exifData => {
					console.log('Output from getExifData:', exifData); // Log the output
					setExifData(exifData);
					const imageMode =
						mode === ProjectMode.SINGLE_IMAGE
							? 'single_image'
							: 'orthophoto';

					await getImageStatistics({
						exifData,
						isOrthophoto,
						imageMode,
					}).then(footprint => {
						if (footprint?.footprint_area) {
							setExifFootprint(footprint);
						}
					});
				})
				.catch(err => {
					console.error(err);

					if (isOrthophoto) {
						console.log(
							'Orthophoto exif could not be processed. Making a manual estimate.'
						);
						const bytesToGB = files[0].size / Math.pow(1024, 3);
						const estimatedArea = bytesToGB * 100000;
						setExifFootprint({
							footprint_area: estimatedArea,
							warning:
								'We could not preprocess the orthophoto. The area is estimated based on the file size. The actual area will be calculated after the project is processed.',
						});

						return;
					}

					let errMessage =
						err?.response?.data?.detail ||
						err?.response?.data?.message ||
						err?.message ||
						err;

					if (typeof errMessage !== 'string') {
						errMessage = 'Unknown error';
					}

					setExifFootprint({
						footprint_area: null,
						warning:
							'Files could not be pre processed, possibly due to issues in your dataset. This could cause the project to fail.',
						warning_response: errMessage,
					});
				})
				.finally(() => {
					setCalculatingArea(false);
				});
		} else {
			// Reset exif footprint if no files are selected
			if (exifFootprint) {
				setExifFootprint(null);
			}
		}
	}, [files]);

	if (tierPro && projectLimitLoading) {
		return (
			<View title="Create new project">
				<Loader />
			</View>
		);
	}

	if (!projectLimitLoading && limitReached) {
		return (
			<View title="Create new project">
				<ProjectLimitWarning
					monthlyProjectsLimit={monthlyProjectsLimit}
				/>
			</View>
		);
	}

	return (
		<View
			title={
				isEnterprise
					? `Create new project for ${currentUser.active_org_name}`
					: 'Create new project'
			}>
			<StepHeading className="my-4">
				Step 1:
				<span> Choose Project Type</span>
			</StepHeading>
			<ProjectChoice
				aria-label="Select project type"
				modeselected={!!mode}>
				<Button
					variant="dark"
					active={mode === ProjectMode.ORTHOPHOTO}
					onClick={() => {
						selectMode(ProjectMode.ORTHOPHOTO);
					}}>
					<div className="project-choise-title">
						<TbPhotoPin strokeWidth={1} />
						<h3>Orthophoto</h3>
					</div>
					<p>
						Use an existing orthophoto or stitch together JPGs to
						make a new one
					</p>
				</Button>
				<Button
					variant="dark"
					active={mode === ProjectMode.SINGLE_IMAGE}
					onClick={() => {
						selectMode(ProjectMode.SINGLE_IMAGE);
					}}>
					<div className="project-choise-title">
						<TbMapPins strokeWidth={1} />
						<h3>Single Images</h3>
					</div>
					<p>Positions individual images as markers on the map</p>
				</Button>
			</ProjectChoice>

			<hr className="my-5" />

			<StepHeading className="my-4">
				Step 2:
				<span>
					{' '}
					Add
					{mode === ProjectMode.ORTHOPHOTO ? (
						<> Orthophoto Project Details</>
					) : mode === ProjectMode.SINGLE_IMAGE ? (
						<> Single Images Project Details</>
					) : (
						<> Project Details</>
					)}
				</span>
			</StepHeading>

			{!!mode && (
				<form onSubmit={submitProject}>
					<Row>
						<LeftCol>
							<FormInputs
								submitting={submitting}
								files={files}
								setFiles={setFiles}
								isOrthophoto={isOrthophoto}
								isOrthophotoProject={
									mode === ProjectMode.ORTHOPHOTO
								}
								setIsOrthophoto={setIsOrthophoto}
								imageLimit={imageLimit}
							/>
						</LeftCol>

						<RightCol>
							{currentUser?.subscription_id && tierPro && (
								<Storage
									projectFilesSize={totalFilesSize}
									userStorageQuery={storageQuery}
									isNotEnoughStorage={isNotEnoughStorage}
								/>
							)}

							{mode === ProjectMode.ORTHOPHOTO && (
								<>
									<Area
										exifFootprint={exifFootprint}
										calculatingArea={calculatingArea}
									/>

									{exifFootprint && !exifFootprint.error && (
										<DatasetQuality
											exifFootprint={exifFootprint}
										/>
									)}
								</>
							)}

							{mode === ProjectMode.SINGLE_IMAGE && (
								<SingleImageFootprint
									disabled={isNotEnoughStorage}
									exifFootprint={exifFootprint}
									calculatingArea={calculatingArea}
								/>
							)}

							{progress >= 0 && (
								<ProgressBar now={progress} variant="success" />
							)}

							{isOrthophoto && submitting && (
								<p className="alert alert-warning mb-4">
									<strong>Upload process has started.</strong>
									<br />
									This can take some time and it's important
									that you do not close this window.
								</p>
							)}

							<FormActions
								submitting={submitting}
								isDisabled={isDisabled}
							/>

							{monthlyProjectsLimit && (
								<>
									<LimitInfo
										className="text-end"
										limit={monthlyProjectsLimit.limit}
										used={
											monthlyProjectsLimit.monthly_projects
										}
										singularName="project"
										pluralName="projects"
										actionPastTense="created"
										actionPresentTense="Creating"
									/>
								</>
							)}
						</RightCol>
					</Row>
				</form>
			)}
		</View>
	);
}
