import { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { AiOutlineSearch } from 'react-icons/ai';

import Input from '@components/form/Input';

const Wrapper = styled.div`
	max-width: 100%;
	width: ${({ $width }) => $width};
	position: relative;

	> div {
		position: relative;
		> svg {
			position: absolute;
			right: 16px;
			top: 50%;
			transform: translateY(-50%);
			z-index: 1;
		}
	}
`;

const StyledSearchInput = styled(Input)`
	&[type='search'] {
		padding-right: 40px;
	}
`;

const SearchField = ({
	label = 'Search',
	placeholder = 'Search...',
	setSearch,
	searchParamsId = null,
	width = '400px',
	...props
}) => {
	const [searchParams] = useSearchParams();
	const timeoutRef = useRef(null);

	const handleSearch = e => {
		const value = e.target.value;

		clearTimeout(timeoutRef.current);
		timeoutRef.current = setTimeout(() => {
			// Send search request after 500ms
			setSearch(value);
		}, 500);
	};

	return (
		<Wrapper $width={width}>
			<div>
				<AiOutlineSearch />
				<StyledSearchInput
					type="search"
					label={label}
					placeholder={placeholder}
					aria-label={placeholder}
					name="search"
					onChange={handleSearch}
					autoComplete="off"
					defaultValue={
						searchParamsId ? searchParams.get(searchParamsId) : null
					}
					{...props}
				/>
			</div>
		</Wrapper>
	);
};

export default SearchField;
