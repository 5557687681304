import styled from 'styled-components';

import ProjectTags from './ProjectTags'; // should projectsTags be moved?

const TagsWrapper = styled.div`
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	padding: 0.2rem 0.3rem;
	width: 100%;
	position: absolute;
	top: 0;
	right: 0;
	z-index: 1;
`;

const ProjectTagsWrapper = ({ uuid, tags, ...props }) => {
	return (
		<>
			{tags?.length > 0 && (
				<TagsWrapper {...props}>
					<ProjectTags projectID={uuid} tags={tags} />
				</TagsWrapper>
			)}
		</>
	);
};

export default ProjectTagsWrapper;
