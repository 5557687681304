import { createPortal } from 'react-dom';
import styled from 'styled-components';

import Loader from '@components/common/Loader';

const LoadingContainer = styled.div`
	display: ${props => (props.isLoading ? 'flex' : 'none')};
	flex-direction: column;
	justify-content: center;
	align-items: center;
	position: fixed; /* Ensure it is positioned relative to the viewport */
	top: 50%;
	left: 50%;
	padding: 30px 20px;
	transform: translate(-50%, -50%);
	background-color: rgba(0, 0, 0, 0.8);
	color: white;
	z-index: 9999; /* Ensure it is on top of other elements */

	p {
		text-align: center;
	}
`;

const MapLoader = ({ showLoader = true, children }) => {
	return (
		<>
			{createPortal(
				<LoadingContainer isLoading={true}>
					{showLoader && <Loader inline />}
					{children}
				</LoadingContainer>,
				document.body
			)}
		</>
	);
};
export default MapLoader;
