// React imports
import { useCallback, useEffect, useRef, useState } from 'react';

// React Query import
import { useQuery } from '@tanstack/react-query';

// OpenLayers imports
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { unByKey } from 'ol/Observable';

// API and context imports
import { getUnrotatedGrid, getMasterFeatures } from '@api';
import { useProject } from '@contexts/Project.context';

// Utility imports
import { deleteLayerByCustomId, getLayerByCustomId } from '@utils/map/helpers';
import { createTooltip } from '@utils/map/tooltip.overlay';
import {
	styleFunction,
	fetchFeaturesImageData,
	createFeature,
} from '@utils/map/singleImage.layer';

// Component imports
import Checkbox from '@routes/userRoutes/projects/singleProject/components/sidebars/sidebarElements/Checkbox';
import { SingleImageOverlayTooltip } from './OverlayTooltip';
import SelectedSingleImageLayer from './SelectedSingleImageLayer';
import MapLoader from '@routes/userRoutes/projects/singleProject/components/MapLoader';

const SingleImageLayer = () => {
	const {
		mapObject,
		defaultProjection,
		project,
		pickedTask,
		colorScheme,
		toolBarVisible,
		dispatch,
	} = useProject();

	const adding = useRef(false);
	const pointermovePopupKey = useRef(null);

	const tooltipRef = useRef(null);
	const tooltipContainerRef = useRef(null);
	const [tooltipContent, setTooltipContent] = useState(null);

	const [singleImageLayer, setSingleImageLayer] = useState(null);
	const singleImageLayerId = 'singleImageLayer';

	const [loading, setLoading] = useState(true);
	const [imagesNotReady, setImagesNotReady] = useState(false);
	const loadedImages = useRef(0);
	const loadedImagesContainer = useRef(null);

	// Fetch grid data
	const {
		data: gridData,
		isError: dataLoadError,
		error,
	} = useQuery({
		queryKey: ['grid_tiles', project.uuid],
		queryFn: () => getUnrotatedGrid(project.uuid),
		enabled: !!project?.uuid && project?.image_mode === 'single_image',
		refetchOnWindowFocus: false,
		retry: false,
	});

	const gridFeatures = gridData?.features;

	// Fetch detections data
	const { data: detectionsData } = useQuery({
		queryKey: [
			'single_photo_detections',
			project.uuid,
			pickedTask?.model_uuid,
		],
		queryFn: () =>
			getMasterFeatures(
				project.uuid,
				pickedTask.model_uuid,
				'normalized_predictions'
			),
		enabled: !!gridFeatures && !!pickedTask?.model_uuid,
		refetchOnWindowFocus: false,
		retry: false,
	});

	const updateLoadedImages = () => {
		// Increment the count of loaded images
		loadedImages.current += 1;

		// Check if the loaded images container DOM element is available
		if (loadedImagesContainer.current) {
			// Update the text content of the DOM element with the current count of loaded images
			loadedImagesContainer.current.textContent = loadedImages.current;
		}
	};

	const updateImagesNotReady = () => {
		setImagesNotReady(true);
		setLoading(false);
		console.log('Images not ready');
	};

	// Fetch features image data
	const { data: featuresData, isLoading: featuresLoading } = useQuery({
		queryKey: ['single_photo_features', project.uuid],
		queryFn: ({ signal }) =>
			fetchFeaturesImageData({
				gridFeatures,
				projectUuid: project.uuid,
				signal,
				updateLoadedImages,
				updateImagesNotReady,
			}),
		enabled: !!gridFeatures,
		refetchOnWindowFocus: false,
		refetchOnMount: false,
		refetchInterval: 3000000, // Set refetch interval to 50 minutes (3000000 milliseconds)
	});

	// Function to create tooltip content for a feature
	const createTooltipContent = feature => {
		// Check if the toolbar is visible
		if (!toolBarVisible) return;

		// Make mouse cursor a pointer to indicate interactivity
		mapObject.getTargetElement().style.cursor = 'pointer';

		// Retrieve the thumbnail URL from the feature
		const thumb = feature.get('thumbnail');
		if (!thumb) return; // Exit if no thumbnail is available

		// Get the coordinates of the feature's geometry
		const coordinates = feature.getGeometry().getCoordinates();

		// Set the tooltip position to the feature's coordinates
		tooltipRef.current.setPosition(coordinates);

		// Update the tooltip content with the thumbnail URL and detection classes
		setTooltipContent({
			imageUrl: thumb,
			detectionClasses: feature.get('detections')?.classes || [], // Default to an empty array if no detections
		});
	};

	// Handler for pointermove event
	const pointerMoveHandler = useCallback(
		event => {
			// Ensure the map object is available
			if (!mapObject) return;

			// Retrieve the feature at the pixel where the pointer event occurred
			const feature = mapObject.forEachFeatureAtPixel(
				event.pixel,
				feature => feature
			);

			if (feature) {
				// If a feature is found, create tooltip content for it
				createTooltipContent(feature);
			} else {
				// If no feature is found, hide the tooltip
				tooltipRef.current.setPosition(undefined);
			}
		},
		[tooltipRef, colorScheme]
	);

	// Function to update the single photo layer
	const updateSinglePhotoLayer = useCallback(async () => {
		// Check if the layer is currently being added or if features are loading
		if (!adding.current && !featuresLoading && featuresData?.length) {
			// Set the adding flag to true to prevent concurrent additions
			adding.current = true;

			// Check if the single image layer already exists. If it does, add it to state and exit early
			const existingSingleImageLayer = getLayerByCustomId(
				mapObject,
				singleImageLayerId
			);
			if (existingSingleImageLayer) {
				setSingleImageLayer(existingSingleImageLayer);

				console.log(
					'Single image layer already exists. Adding it to state.'
				);

				adding.current = false;
				setLoading(false);
				return;
			}

			console.log('Creating single image layer');

			// Create a new vector source for the single image layer
			const singleImageSource = new VectorSource({
				features: [],
			});

			// Create a new vector layer with the source and additional properties
			const layer = new VectorLayer({
				source: singleImageSource,
				visible: true,
				defaultProjection: defaultProjection,
				zIndex: 24,
				properties: {
					customLayerId: singleImageLayerId,
				},
				style: styleFunction(mapObject),
			});

			// Add the new layer to the map
			mapObject.addLayer(layer);

			// Map the features data to create new features for the layer
			const singleImageFeatures = featuresData.map(data => {
				const feature = createFeature(data, defaultProjection);
				singleImageSource.addFeature(feature);

				return feature;
			});

			// If features were added make sure the map is centered on the project center
			if (singleImageFeatures?.length > 0) {
				const extent = singleImageSource?.getExtent();
				if (extent) {
					// Center the map based on the layer's extent
					mapObject.getView().fit(extent, {
						size: mapObject.getSize(),
						maxZoom: 17, // Optional: Set a maximum zoom level
					});
				}
			} else {
				// Log a warning if no features were added
				console.warn(
					'No features were added to the single image layer.'
				);
			}

			// Set the new layer to the state
			setSingleImageLayer(layer);
			// Reset the adding flag and loading state
			adding.current = false;
			setLoading(false);
		}
	}, [featuresLoading]);

	// Function to update detections on the single image layer
	const updateDetections = useCallback(() => {
		// Early return if singleImageLayer is not defined
		if (!singleImageLayer) return;

		// Get all features from the single image layer
		const singleImageFeatures = singleImageLayer
			?.getSource()
			?.getFeatures();

		// Remove any existing detections from the features
		singleImageFeatures?.forEach(feature => {
			feature.set('detections', null);
		});

		// Check if detectionsData contains features
		if (detectionsData?.features) {
			// Iterate over each feature in singleImageFeatures
			singleImageFeatures.forEach(feature => {
				// Get the filename associated with the current feature
				const filename = feature.get('name');

				// Filter detections that match the current feature's filename
				const matchingDetections = detectionsData.features.filter(
					detection => detection.properties.filename === filename
				);

				// If there are any matching detections, process them
				if (matchingDetections.length > 0) {
					// Reduce detections to accumulate class information
					const classes = matchingDetections.reduce(
						(acc, detection) => {
							const { classname: name, classid: id } =
								detection.properties;

							// Check if the class already exists in the accumulator
							const existingClass = acc.find(
								item => item.name === name && item.id === id
							);

							// If the class exists, increment its count
							if (existingClass) {
								existingClass.count += 1;
							} else {
								// If the class does not exist, add it to the accumulator
								acc.push({ name, id, count: 1 });
							}

							return acc;
						},
						[]
					);

					// Set the detections and classes information on the feature
					feature.set('detections', {
						features: matchingDetections,
						classes,
					});
				}
			});
		}

		// Dispatch actions to update the state with the new features
		dispatch({
			type: 'setSingleImageFeatures',
			payload: singleImageFeatures,
		});
		dispatch({
			type: 'setFilteredImages',
			payload: singleImageFeatures,
		});

		console.log('Detections updated');
	}, [detectionsData, singleImageLayer]);

	useEffect(() => {
		// Check if there was an error loading the data
		if (dataLoadError) {
			// Log a warning message to the console
			console.warn('Could not fetch single photo', error);

			// If the map object and single image layer exist, delete the layer
			if (mapObject && singleImageLayer) {
				// Delete the single image layer from the map using its custom ID
				deleteLayerByCustomId(mapObject, singleImageLayerId);

				// Set the single image layer state to null
				setSingleImageLayer(null);
			}

			setLoading(false);
			alert('Something went wrong. Please try again later.');

			// Exit the effect early since there was an error
			return;
		}

		// If there was no error, update the single photo layer
		updateSinglePhotoLayer();
	}, [updateSinglePhotoLayer, dataLoadError]);

	useEffect(() => {
		// If the single image layer is not available or the tooltip already exists, exit early
		if (!singleImageLayer || tooltipRef.current) return;

		// Create Tooltip
		tooltipRef.current = createTooltip({
			mapRef: mapObject,
			tooltipRef: tooltipContainerRef.current,
			id: 'single-image-tooltip',
		});

		// Add pointermove event listener to the map
		const pointermoveKey = mapObject.on('pointermove', pointerMoveHandler);
		pointermovePopupKey.current = pointermoveKey;

		// Cleanup function to remove the event listener when the component unmounts or dependencies change
		return () => {
			if (pointermovePopupKey.current) {
				unByKey(pointermovePopupKey.current);
				pointermovePopupKey.current = null;
			}
		};
	}, [singleImageLayer, mapObject]);

	useEffect(() => {
		updateDetections();
	}, [updateDetections]);

	useEffect(() => {
		return () => {
			// Clean up on unmount

			// If the layer is currently being added, skip cleanup
			if (adding.current) return;

			console.log('Cleaning up single image layer');

			// Remove the pointer move popup event listener if it exists
			if (pointermovePopupKey.current) {
				unByKey(pointermovePopupKey.current);
				pointermovePopupKey.current = null;
			}

			// Delete the single image layer from the map
			deleteLayerByCustomId(mapObject, singleImageLayerId);
			setSingleImageLayer(null);

			// Remove the tooltip overlay from the map if it exists
			if (mapObject && tooltipRef.current) {
				mapObject.removeOverlay(tooltipRef.current);
				tooltipRef.current = null;
			}

			// Reset the loaded images count
			loadedImages.current = 0;
			setImagesNotReady(false);
		};
	}, []);

	return (
		<div>
			<Checkbox
				label="Single Image"
				canEdit={false}
				defaultState={true}
				handleCheck={() => singleImageLayer?.setVisible(true)}
				handleUncheck={() => singleImageLayer?.setVisible(false)}
				disabled={!singleImageLayer}
			/>

			{loading && (
				<MapLoader>
					{gridFeatures?.length ? (
						<p style={{ minWidth: '250px' }} className="mt-4 mb-0">
							Loading
							<br />
							<span ref={loadedImagesContainer}>
								{loadedImages.current}
							</span>{' '}
							/ {gridFeatures.length} images
						</p>
					) : (
						<p className="mt-4 mb-0">Loading images...</p>
					)}
				</MapLoader>
			)}

			{imagesNotReady && (
				<MapLoader showLoader={false}>
					<p className="mb-0">
						The images are not ready. Please check back later.
					</p>
				</MapLoader>
			)}

			{singleImageLayer && (
				<>
					<SelectedSingleImageLayer
						singleImageLayer={singleImageLayer}
						singleImageLayerTooltipRef={tooltipRef.current}
					/>
					<div ref={tooltipContainerRef}>
						<SingleImageOverlayTooltip
							{...tooltipContent}
							colorScheme={colorScheme}
						/>
					</div>
				</>
			)}
		</div>
	);
};
export default SingleImageLayer;
