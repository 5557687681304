import View from '../../components/layout/views/View';

const Contact = () => {
	return (
		<View title="Contact">
			<div className="pb-4">
				<p>
					If you have have any questions or need support, don't
					hesitate to{' '}
					<a href="mailto:post@biodrone.no?subject=Subscription Inquiry Biodrone Portal">
						shoot us an email at post@biodrone.no
					</a>
					.
				</p>

				<p>
					We're here to assist and are thrilled that you're interested
					in what we have to offer!
				</p>
			</div>
		</View>
	);
};

export default Contact;
