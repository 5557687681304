import { useEffect, useState } from 'react';
import { Range } from 'react-range';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';

const RangeSliderWithHistogram = ({
	step = 1,
	min = null,
	max = null,
	data = [],
	labels = [],
	onChange = () => {},
}) => {
	const [value, setValue] = useState([min, max]);

	useEffect(() => {
		setValue([min, max]);
	}, [min, max]);

	if (
		data?.length === 0 ||
		min === null ||
		max === null ||
		value[0] === null ||
		value[1] === null ||
		min === max
	)
		return null;

	return (
		<div>
			<div style={{ marginBottom: '20px' }}></div>
			<div style={{ maxHeight: '100px' }}>
				<Bar
					data={{
						labels,
						datasets: [
							{
								label: 'Detections',
								data, // Array of data values corresponding to each label
								backgroundColor: '#cacaca', // Background color for the bars
							},
						],
					}}
					options={{
						maintainAspectRatio: false,
						plugins: {
							legend: {
								display: false,
							},
						},
					}}
				/>
			</div>
			<Range
				step={step}
				min={min}
				max={max}
				values={value}
				onChange={val => {
					setValue(val);
					onChange(val);
				}}
				renderTrack={({ props, children }) => (
					<div
						{...props}
						style={{
							...props.style,
							height: '6px',
							backgroundColor: '#8c8b8b',
						}}>
						{children}
					</div>
				)}
				renderThumb={({ props }) => (
					<div
						{...props}
						style={{
							...props.style,
							height: '16px',
							width: '16px',
							borderRadius: '50%',
							backgroundColor: '#198754',
							boxShadow: '0px 2px 6px #1c1c1c',
						}}
					/>
				)}
			/>

			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					padding: '10px 0',
				}}>
				<div>{value[0]}</div>
				<div>{value[1]}</div>
			</div>
		</div>
	);
};

export default RangeSliderWithHistogram;
