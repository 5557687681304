import { Style, Icon, Circle, Fill, Stroke, Text } from 'ol/style';
import { Vector as VectorLayer } from 'ol/layer';
import { Tile as TileLayer } from 'ol/layer';
import { OSM } from 'ol/source';
import Overlay from 'ol/Overlay.js';

import {
	getFeatureType,
	calculateCircleRadius,
	truncateStringWithSuffix,
} from './helpers';
import mapPinIconSource from '@assets/map_pin_icon.svg';

// Creates the Cluster Circle Layer
export const createClusterCircleLayer = ({ name, zIndex }) => {
	// Cluster Layer Cluster Size Styling
	const clusterCircleStyle = feature => {
		if (getFeatureType(feature) === 'single') return null;
		const clusterAmount = feature.get('features').length;

		return new Style({
			image: new Circle({
				radius: calculateCircleRadius(clusterAmount, 18),

				fill: new Fill({
					color: '#171717',
				}),

				stroke: new Stroke({
					color: '#239442',
					width: 1,
				}),
			}),

			text: new Text({
				text: clusterAmount.toString(),
				font: 'bold .85rem montserrat, sans-serif',
				fill: new Fill({
					color: '#cfcfcf',
				}),
			}),
		});
	};

	// Cluster Size Icon / Title Layer
	const clusterCircle = new VectorLayer({
		name: name,
		zIndex: zIndex,

		style: clusterCircleStyle,
	});
	return clusterCircle;
};

// Creates the Pin Layer
export const createPinLayer = ({ name, zIndex }) => {
	// Cluster Layer Pin Styling
	const clusterPinStyle = feature => {
		if (getFeatureType(feature) === 'cluster') return null;

		const pinFeature = feature.get('features')[0];
		const pinFeatureTitle = pinFeature.get('data')?.title;

		const title = truncateStringWithSuffix(pinFeatureTitle, 22, '..');

		return new Style({
			image: new Icon({
				src: mapPinIconSource,
				width: 25,
				height: 25,
			}),

			text: new Text({
				text: title,
				font: '600 .85rem montserrat, sans-serif',
				offsetY: 22,

				fill: new Fill({
					color: '#000000',
				}),

				stroke: new Stroke({
					color: '#ffffffcc',
					width: 3,
				}),
			}),
		});
	};

	// Cluster Pin Layer
	const clusterPinLayer = new VectorLayer({
		name: name,
		zIndex: zIndex,
		style: clusterPinStyle,
	});

	return clusterPinLayer;
};

// Creates the Map Layer
export const createMapLayer = ({ name, zIndex }) => {
	const mapLayer = new TileLayer({
		source: new OSM(),
		name: name,
		zIndex: zIndex,
	});

	return mapLayer;
};

// Creates the Overlay Layer
export const createOverlayLayer = (map, elementRef) => {
	const overlay = new Overlay({
		element: elementRef,
		positioning: 'center-center',
		autoPan: {
			animation: {
				duration: 1000,
			},
		},
	});

	map.addOverlay(overlay);
	return overlay;
};
