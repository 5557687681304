import { Select } from 'ol/interaction';
import Draw, { createBox } from 'ol/interaction/Draw';
import { Vector as VectorSource } from 'ol/source';
import { Vector as VectorLayer } from 'ol/layer';
import { pointerMoveListener } from './erasorPointer';
import { containsExtent } from 'ol/extent';
import { Feature } from 'ol/index';
import { Polygon } from 'ol/geom';

import { polygonPositionsToMapCoordinates } from './helpers';

import { setTranslateInteraction } from './annotation/translate.interaction';
import { setModifyInteraction } from './annotation/modify.interaction';
import { annotationStyles } from './annotation/annotation.interaction';
import { ModelType } from '@contexts/Project.context';

/**
 * Adds a drawing layer to the map with specified drawing interactions and styles.
 *
 * @param {Object} map - The OpenLayers map instance.
 * @param {string} drawType - The type of drawing interaction (e.g., 'Point', 'LineString', 'Polygon').
 * @param {string} drawTool - The drawing tool to be used.
 * @param {Object} colorOptions - The color options for the drawing style.
 * @returns {Object} - An object containing references to the layer, source, draw interaction, select interaction, translate interaction, transform interaction and pointer move event handler.
 */
export const addDrawLayer = ({ map, drawType, drawTool, colorOptions }) => {
	// Create a new vector source for the drawing layer
	const source = new VectorSource({ wrapX: false });

	// Create a new vector layer with the specified source and style function
	const layer = new VectorLayer({
		source: source,
		renderBuffer: 500,
		zIndex: 25,
		name: 'drawLayer',
		style: feature => annotationStyles(feature, colorOptions),
		//style: styleFunction,
	});
	map.addLayer(layer);

	// Initialize the draw interaction variable
	let draw = null;

	// Set up the draw interaction with the specified parameters
	draw = setDrawInteraction({ map, draw, source, drawType, drawTool });

	// Create a select interaction for the drawing layer
	const select = new Select({
		layers: [layer],
		style: function (feature) {
			// Return the original style of the feature
			return feature.getStyle();
		},
	});
	map.addInteraction(select);
	select.setActive(false); // Initially deactivate the select interaction

	const translate = setTranslateInteraction({
		layer: layer,
		updateModifiedObjects: () => {}, // not needed for single image annotation
	});
	map.addInteraction(translate);

	const modify = setModifyInteraction({
		source: source,
		updateModifiedObjects: () => {}, // not needed for single image annotation
	});
	map.addInteraction(modify);

	// Create a pointermove event listener to change the cursor to a trash can
	const pointerMoveRef = evt => {
		pointerMoveListener(evt, map);
	};

	// Return an object containing the references
	return {
		layer,
		source,
		draw,
		select,
		translate,
		modify,
		pointerMoveRef,
	};
};

/**
 * Sets up a draw interaction on the map.
 *
 * @param {Object} map - The OpenLayers map instance.
 * @param {Object} draw - The existing draw interaction, if any.
 * @param {Object} source - The vector source for the draw interaction.
 * @param {string} drawType - The type of geometry to be drawn (e.g., 'Point', 'LineString', 'Polygon', 'Circle').
 * @param {string} drawTool - The tool to be used for drawing (e.g., 'Box').
 * @returns {Object} - The new draw interaction.
 */
export const setDrawInteraction = ({
	map,
	draw,
	source,
	drawType,
	drawTool,
}) => {
	// If there is an existing draw interaction, dispose of it and remove it from the map
	if (draw) {
		draw.dispose();
		map.removeInteraction(draw);
	}

	// Create a new draw interaction with the specified parameters
	draw = new Draw({
		source: source, // The vector source where the drawn features will be stored
		type: drawType, // The type of geometry to be drawn
		freehand: drawTool === 'Freehand',
		geometryFunction:
			drawType === 'Circle' && drawTool === 'Box' ? createBox() : null, // Use a custom geometry function if drawing a box
	});

	// Add the new draw interaction to the map
	map.addInteraction(draw);

	// Return the new draw interaction
	return draw;
};

/**
 * Removes the draw layer and associated interactions from the map.
 *
 * @param {Object} map - The OpenLayers map instance.
 * @param {Object} drawRef - An object containing references to the draw layer and interactions.
 */
export const removeDrawLayer = (map, drawRef) => {
	// Destructure the drawRef object to get the layer, draw, transform, translate, select, and pointerMoveRef
	const { layer, draw, select, translate, transform, pointerMoveRef } =
		drawRef || {};

	// Helper function to dispose and remove interactions
	const disposeAndRemoveInteraction = interaction => {
		if (interaction) {
			interaction.dispose();
			map.removeInteraction(interaction);
		}
	};

	// If a layer exists, dispose of it and remove it from the map
	if (layer) {
		layer.dispose();
		map.removeLayer(layer);
	}

	// Dispose and remove interactions
	disposeAndRemoveInteraction(draw);
	disposeAndRemoveInteraction(translate);
	disposeAndRemoveInteraction(transform);
	disposeAndRemoveInteraction(select);

	// If a pointer move event handler exists, remove it from the map
	if (pointerMoveRef) {
		map.un('pointermove', pointerMoveRef);
	}
};

/**
 * Handles the start of a drawing interaction.
 *
 * @param {Object} e - The event object from the drawing interaction.
 * @param {Object} layer - The layer on which the drawing is happening.
 * @param {Object} draw - The draw interaction instance.
 * @param {number} classId - The class ID to be assigned to the feature.
 */
export const drawStartInteraction = ({
	e,
	layer,
	draw,
	classId,
	activateInteractions,
}) => {
	// Get the feature being drawn from the event object
	const feature = e.feature;

	// Check if the feature starts inside the image extent
	const startsInside = isFeatureInsideExtent({ feature, layer });
	if (!startsInside) {
		// If the feature does not start inside the image extent, remove the last point and prevent the default action
		draw.removeLastPoint();
		e.preventDefault();

		return;
	}

	activateInteractions(false);

	// Set the classId property on the feature to add the correct styling based on class
	feature.setProperties({
		classId: classId,
		isInside: true, // isInside is always true for single image annotation
	});
};

/**
 * Handles the end of a drawing interaction and assigns the class ID and annotation type to the feature.
 *
 * @param {Object} e - The event object from the drawing interaction.
 * @param {number} classId - The class ID to be assigned to the feature.
 * @param {string} modelType - The type of annotation being drawn.
 */
export const drawEndInteraction = ({
	e,
	classId,
	modelType,
	activateInteractions,
}) => {
	// Get the feature that was drawn from the event object
	const feature = e.feature;

	// Get the type of the geometry
	const geometry = feature.getGeometry();
	let geometryType = geometry.getType().toLowerCase();

	// If the geometry type is a polygon and the annotation type is object detection,
	// convert the geometry type to bbox
	if (
		geometryType === 'polygon' &&
		modelType === ModelType.OBJECT_DETECTION
	) {
		geometryType = 'bbox';
	}

	// Set the classId and annotation_type properties on the feature
	feature.setProperties({
		classid: classId,
		annotation_type: geometryType,
		isInside: true,
	});

	activateInteractions(true);
};

/**
 * Parses a feature into an annotation object
 *
 * @param {Array} currentImageExtent - The extent of the current image.
 * @param {Object} feature - The feature being annotated.
 * @param {string} tile_name - The name of the tile where the annotation is being added.
 * @param {string} taskId - The ID of the task associated with the annotation.
 * @returns {Object} - The new annotation object.
 */
export const parseFeatureToAnnotation = ({
	currentImageExtent,
	feature,
	tile_name,
	taskId,
}) => {
	// If tile_name or feature is not provided, exit the function
	if (!tile_name && !feature) return;

	const {
		uuid = 'unknown',
		annotation_type,
		classid,
	} = feature.getProperties();

	// Initialize a new annotation object with default properties
	let newAnnotation = {
		crs_coordinates: false,
		task_uuid: taskId,
		machine_annotation: uuid === 'unknown' ? false : true,
		deprecated: false,
		human_input: true,
		annotation_type,
		classid,
		tile_name,
		uuid,
	};

	// Handle circle annotations
	if (annotation_type === 'circle') {
		// Get the center of the circle
		const center = feature.getGeometry().getCenter();
		// Normalize the center coordinates based on the current image extent
		const pixelCoordinateReversed = [
			(center[0] - currentImageExtent[0]) /
				(currentImageExtent[2] - currentImageExtent[0]),
			1 -
				(center[1] - currentImageExtent[1]) /
					(currentImageExtent[3] - currentImageExtent[1]),
		];

		// Values can not be less than 0 or greater than 1
		const coordinatesClamped = pixelCoordinateReversed.map(coord =>
			Math.max(0, Math.min(1, coord))
		);

		// Update the new annotation object with circle-specific properties
		newAnnotation = {
			...newAnnotation,
			x_center: coordinatesClamped[0],
			y_center: coordinatesClamped[1],
			radius:
				feature.getGeometry().getRadius() /
				(currentImageExtent[2] - currentImageExtent[0]),
		};
	} else if (annotation_type === 'polygon' || annotation_type === 'bbox') {
		// Handle polygon annotations
		// Get the coordinates of the polygon
		const coordinates = feature.getGeometry().getCoordinates()[0];
		// Normalize the coordinates based on the current image extent
		const normalizedCoordinates = coordinates.map(coord => {
			const normalizedX =
				(coord[0] - currentImageExtent[0]) /
				(currentImageExtent[2] - currentImageExtent[0]);
			const normalizedY =
				1 -
				(coord[1] - currentImageExtent[1]) /
					(currentImageExtent[3] - currentImageExtent[1]);

			// Values can not be less than 0 or greater than 1
			const clampedX = Math.max(0, Math.min(1, normalizedX));
			const clampedY = Math.max(0, Math.min(1, normalizedY));

			return [clampedX, clampedY];
		});

		// Update the new annotation object with polygon-specific properties
		newAnnotation = {
			...newAnnotation,
			coordinates: normalizedCoordinates,
		};
	}

	return newAnnotation;
};

/**
 * Deletes an annotation feature from the source
 *
 * @param {Object} feature - The feature to be deleted.
 * @param {Object} source - The vector source from which the feature will be removed.
 */
export const deleteAnnotation = ({ feature, source }) => {
	console.log('removed annotation');

	// Remove the feature from the vector source
	source.removeFeature(feature);
};

/**
 * Adds existing annotations to the source
 *
 * @param {Object} source - The vector source to which the features will be added.
 * @param {Array} annotations - The array of annotations to be added.
 * @param {Array} imageExtent - The extent of the current image.
 * @param {string} modelType - The type of annotation being added.
 */
export const addExistingAnnotations = ({
	source,
	annotations,
	imageExtent,
	modelType,
}) => {
	// If annotations or source is not provided, exit the function
	if (!annotations || !source) return;

	const annotation_type =
		modelType === ModelType.OBJECT_DETECTION ? 'bbox' : 'circle';

	// Iterate over each annotation in the annotations array
	annotations.forEach(annotation => {
		try {
			// Create a feature from the annotation
			const coordinates = annotation?.geometry?.coordinates;
			const { classid, annotation_uuid } = annotation?.properties;

			const polygonPositionTranslatedToMapCoordinates =
				polygonPositionsToMapCoordinates(coordinates, imageExtent);

			const feature = new Feature({
				geometry: new Polygon(
					polygonPositionTranslatedToMapCoordinates
				),
				uuid: annotation_uuid,
				classid,
				annotation_type,
				isInside: true,
			});

			// If the feature was successfully created, add it to the source
			if (feature) {
				// Add the feature to the vector source
				source.addFeature(feature);
			}
		} catch (e) {
			console.log('Could not add annotation to map', annotation, e);
		}
	});
};

/**
 * Checks if a feature's geometry is inside the current image extent.
 *
 * @param {Object} feature - The feature whose geometry is being checked.
 * @param {Object} layer - The layer containing the current image extent.
 * @returns {boolean} - True if the feature's geometry is inside the current image extent, false otherwise.
 */
const isFeatureInsideExtent = ({ feature, layer }) => {
	// Get the current image extent from the layer
	const currentImageExtent = getCurrentImageExtent(layer);

	// Get the geometry of the feature
	const geometry = feature.getGeometry();
	// Check if the geometry's extent is contained within the current image extent
	const isInside = containsExtent(currentImageExtent, geometry.getExtent());

	// Return the result of the containment check
	return isInside;
};

/**
 * Retrieves the current image extent from the given layer.
 *
 * @param {Object} layer - The layer from which to get the current image extent.
 * @returns {Array} - The extent of the current image.
 */
const getCurrentImageExtent = layer => {
	// Get the source of the current image from the layer
	const currentImageSource = layer.getSource();

	// Get the extent of the current image from the source
	const currentImageExtent = currentImageSource?.getImageExtent();

	// Return the current image extent
	return currentImageExtent;
};
