import { Route, Routes } from 'react-router-dom';

import { ProjectProvider } from '@contexts/Project.context';
import { ROLES, TIERS } from '@contexts/User.context';

import Chatbot from '@components/chatbot/Chatbot';

import RequireAuth from '@components/RequireAuth';
import Root from '@components/layout/Root';

import Home from '@routes/unprotectedRoutes/home/Home';
import Login from '@routes/unprotectedRoutes/Login';
import PrivacyPolicy from '@routes/unprotectedRoutes/PrivacyPolicy';
import BiodroneEULA from '@routes/unprotectedRoutes/BiodroneEULA';
import Contact from '@routes/unprotectedRoutes/Contact';
import Unauthorized from '@routes/unprotectedRoutes/Unauthorized';
import Missing404 from '@routes/unprotectedRoutes/Missing404';

import RegisterAccount from '@routes/unprotectedRoutes/RegisterAccount';
import RegisterTrialAccount from '@routes/unprotectedRoutes/RegisterTrialAccount';
import VerifyUser from '@routes/unprotectedRoutes/Verify';

import RequestPasswordReset from '@routes/unprotectedRoutes/RequestPasswordReset';
import PasswordReset from '@routes/unprotectedRoutes/resetPassword/components/ResetPassword';

import Checkout from '@routes/userRoutes/Checkout';
import Subscription from '@routes/userRoutes/Subscription';
import UserProjects from '@routes/userRoutes/projects/userProjects/Projects';
import Profile from '@routes/userRoutes/Profile';
import Subscribe from '@routes/userRoutes/Subscribe';

import ProjectMapView from '@routes/userRoutes/projects/singleProject/ProjectMapView';
import NewProject from '@routes/userRoutes/projects/newProject/NewProject';
import EditProject from '@routes/userRoutes/projects/EditProject';

import OrganizationProfile from '@routes/userRoutes/OrganizationProfile';
import OrganizationProjects from '@routes/userRoutes/OrganizationProjects';

import Users from './routes/adminRoutes/users/Users';
import Organizations from './routes/adminRoutes/organizations/Organizations';
import Organization from './routes/adminRoutes/organizations/Organization';
import AdminProjects from './routes/adminRoutes/projects/ProjectsView';
import AdminDemoProjects from './routes/adminRoutes/projects/DemoProjects';

function App() {
	return (
		<>
			<Routes>
				<Route path="/" element={<Root />}>
					<Route index element={<Home />} />

					<Route path="login" element={<Login />} />
					<Route path="privacy-policy" element={<PrivacyPolicy />} />
					<Route path="eula" element={<BiodroneEULA />} />
					<Route path="contact" element={<Contact />} />
					<Route path="register" element={<RegisterAccount />} />
					<Route
						path="register-trial"
						element={<RegisterTrialAccount />}
					/>
					<Route path="verify" element={<VerifyUser />} />
					<Route
						path="request-password-reset"
						element={<RequestPasswordReset />}
					/>
					<Route path="password-reset" element={<PasswordReset />} />

					<Route path="unauthorized" element={<Unauthorized />} />

					<Route
						element={
							<RequireAuth
								allowedRoles={[ROLES.Admin, ROLES.User]}
							/>
						}>
						<Route path="/projects">
							<Route index element={<UserProjects />} />

							<Route path="new" element={<NewProject />} />

							<Route
								path=":project_uuid"
								element={
									<ProjectProvider>
										<ProjectMapView />
									</ProjectProvider>
								}
							/>
							<Route
								path=":project_uuid/edit"
								element={<EditProject />}
							/>
						</Route>

						<Route path="/subscribe" element={<Subscribe />} />

						<Route path="/profile" element={<Profile />} />

						<Route
							path="/organization/:organization_uuid/profile"
							element={<OrganizationProfile />}
						/>
						<Route
							path="/organization/:organization_uuid/projects"
							element={<OrganizationProjects />}
						/>

						<Route
							element={
								<RequireAuth allowedTiers={[TIERS.Pro]} />
							}>
							<Route path="/checkout" element={<Checkout />} />

							<Route
								path="/subscription"
								element={<Subscription />}
							/>
						</Route>
					</Route>

					<Route
						path="/admin"
						element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
						<Route path="users" element={<Users />} />
						<Route path="projects" element={<AdminProjects />} />
						<Route
							path="demo-projects"
							element={<AdminDemoProjects />}
						/>
						<Route
							path="organizations/:organization_uuid"
							element={<Organization />}
						/>

						<Route
							path="organizations/create"
							element={<Organization />}
						/>

						<Route
							path="organizations"
							element={<Organizations />}
						/>
					</Route>

					{/* catch all */}
					<Route path="*" element={<Missing404 />} />
				</Route>
			</Routes>
			<Chatbot />
		</>
	);
}

export default App;
