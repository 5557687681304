import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import styled from 'styled-components';
import Dropdown from 'react-bootstrap/Dropdown';

import { useAuth} from '@contexts/User.context';
import { getOrganizationsByMember, setActiveOrg } from '@api';
import Loader from '../common/Loader';

const Container = styled.div`
	position: relative;
`;

const StyledDropdownToggle = styled(Dropdown.Toggle)`
	border-radius: 0.375rem;
	display: flex;
	flex-direction: column;
	gap: 3px;
	padding: 4px 6px;
	line-height: 1em;
	background: none;
	&.btn.btn-dark {
		font-weight: normal;
		border: 1px solid rgba(255, 255, 255, 0.1);
	}
	&:after {
		display: none;
	}
`;

const StyledDropdownMenu = styled(Dropdown.Menu)`
	background-color: ${props => props.theme.colors.backgroundAccent};
`;

const DropdownTitle = styled.span`
	font-size: 0.6rem;
	padding: 6px 16px;
	opacity: 0.7;
	font-weight: 300;
	display: block;
	transform: translateY(-3px);
	letter-spacing: 0.1em;
`;

const Label = styled.span`
	color: rgba(255, 255, 255, 0.5);
	font-size: 0.8rem;
	@media (max-width: 390px) {
		font-size: 0.7rem;
	}

	@media (max-width: 340px) {
		font-size: 0.6rem;
	}
`;

const User = styled.span`
	color: white;
	display: block;
	@media (max-width: 390px) {
		font-size: 0.8rem;
	}

	@media (max-width: 340px) {
		font-size: 0.7rem;
	}
`;

/**
 * A dropdown menu that allows the user to switch between organizations or back to their user account
 */
const OrganizationSwitch = () => {
	const { project_uuid } = useParams();

	const {
		currentUser,
		refetchCurrentUser,
		loading: userLoading,
	 } = useAuth();

	const [loading, setLoading] = useState(userLoading);

	const {data: orgData} = useQuery({
		queryKey: ["user_organization", currentUser.uuid],
		queryFn: () => getOrganizationsByMember(currentUser.uuid),
		enabled: !!currentUser.uuid,
		refetchOnWindowFocus: false,
	});
	const organizations = orgData?.organizations ?? null;

	const setOrgMutation = useMutation({
		mutationFn: (org_uuid) => setActiveOrg(org_uuid),
	});

	const handleClick = async org => {
		setLoading(true);

		// Ensure the loading state is kept for at least 500ms, only for visual purposes
		const delay = new Promise(resolve => {
			setTimeout(resolve, 300);
		});

		// Perform the organization switch immediately
		//const switchOrgPromise = setActiveOrg(org.uuid);
		const switchOrgPromise = setOrgMutation.mutateAsync(org.uuid);

		// Wait for the minimum loading time to pass
		await delay;

		// Now, update the state if the organization switch was successful
		switchOrgPromise.then(async res => {
			await refetchCurrentUser();
		}).catch(err => {
			console.error(err);
			window.alert(
				'Something went wrong. Could not switch organization.'
			);
		}).finally(() => {
			setLoading(false);
		});
	};

	if (userLoading || !organizations || organizations?.length === 0) return null;

	return (
		<Container>
			<Dropdown align="start">
				<StyledDropdownToggle
					id="dropdown-org-switch"
					className="btn-dark"
					disabled={loading || project_uuid}>
					<Label>Logged in as</Label>

					{loading ? (
						<Loader inline={true} size="sm" />
					) : (
						<User>
							{currentUser.active_org_name ?? currentUser.email}
						</User>
					)}
				</StyledDropdownToggle>

				<StyledDropdownMenu variant="dark">
					<DropdownTitle>Organizations</DropdownTitle>
					{organizations.map(org => (
						<Dropdown.Item
							key={org.uuid}
							as="button"
							className="btn-dark"
							disabled={org.uuid === currentUser.active_org_id}
							onClick={() => {
								handleClick(org);
							}}>
							{org.name}
						</Dropdown.Item>
					))}

					<Dropdown.Divider />
					<DropdownTitle>Personal User</DropdownTitle>
					<Dropdown.Item
						as="button"
						className="btn-dark"
						disabled={!currentUser.active_org_id}
						onClick={() => {
							handleClick({ name: null, uuid: null });
						}}>
						{currentUser.email}
					</Dropdown.Item>
				</StyledDropdownMenu>
			</Dropdown>
		</Container>
	);
};
export default OrganizationSwitch;
