import { useState } from 'react';
import BooleanValue from '@routes/adminRoutes/sharedComponents/BooleanValue';
import { VerifyButton } from '@routes/adminRoutes/sharedComponents/SharedStyledComponents';
import { adminApproveUser } from '@api';

const ApproveUser = ({ cellValue, email }) => {
	const [approve, setApproved] = useState(cellValue);
	const handleApprove = async () => {
		if (window.confirm(`Are you sure you want to approve ${email}?`)) {
			await adminApproveUser(email)
				.then(response => {
					console.log('response', response);
					if (response?.status === 'success') {
						setApproved(true);
					} else {
						window.alert('Failed to approve user');
					}
				})
				.catch(err => {
					console.error(err);
					window.alert('Failed to approve user');
				});
		}
	};

	return (
		<>
			<BooleanValue value={approve} />

			{!approve && (
				<VerifyButton size="sm" onClick={handleApprove}>
					Aprove
				</VerifyButton>
			)}
		</>
	);
};

export default ApproveUser;
