import { forwardRef } from 'react';
import Form from 'react-bootstrap/Form';
import styled, { css } from 'styled-components';

import { FieldWrapper } from './CommonStyles';
import HelperText from './HelperText';

const StyledCheck = styled(Form.Check)`
	label:hover,
	input:hover {
		cursor: pointer;
	}
	${p =>
		p.variant === 'dark' &&
		css`
			.form-check-input {
				background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba(255, 255, 255, 0.5)'/%3e%3c/svg%3e");
			}
		`}
`;

// https://react-bootstrap.github.io/docs/forms/checks-radios
const Toggle = forwardRef(
	(
		{
			type = 'checkbox',
			inline = false,
			helperText = null,
			id = null,
			...props
		},
		ref
	) => {
		const field = (
			<>
				<StyledCheck
					type={type}
					ref={ref}
					aria-describedby={helperText && id ? `help-${id}` : null}
					id={id}
					{...props}
				/>
				{helperText && id && (
					<HelperText id={`help-${id}`}>{helperText}</HelperText>
				)}
			</>
		);

		if (helperText && !id) {
			console.warn(
				'Toggle component: helperText prop is set, but id prop is not. Please set id prop.'
			);
		}

		if (inline) return field;

		return <FieldWrapper>{field}</FieldWrapper>;
	}
);

export default Toggle;
