import styled from 'styled-components';
import Accordion from 'react-bootstrap/Accordion';
import { formatAreaNumber } from '@utils/helpers';

const AccordionItem = styled(Accordion.Item)`
	border-color: ${props => props.theme.colors.border};
`;
const AccordionHeader = styled(Accordion.Header)`
	.accordion-button {
		background-color: ${props => props.theme.colors.modal};
		color: ${props => props.theme.colors.textColor};
		margin-bottom: 0;
		box-shadow: none;

		&:after {
			background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
		}
	}
`;
const AccordionBody = styled(Accordion.Body)`
	padding: 0;
	margin: 0;
	background-color: ${props => props.theme.colors.background};
`;
const DetectionsDetail = styled.div`
	font-weight: normal;
	line-height: 1.2;
	color: ${p => p.theme.colors.textColor};

	padding: 20px;
	p {
		margin-bottom: 20px;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
`;
const Name = styled.span`
	text-transform: capitalize;
	color: ${p => p.theme.colors.textAccent};
	color: ${props => props.theme.colors.textColor};
	font-size: 0.9em;
	font-weight: 700;
`;

const Details = styled.div`
	padding: 0 20px 20px;
	span {
		text-transform: capitalize;
		color: ${p => p.theme.colors.textAccent};
	}
	p {
		margin-bottom: 10px;
		display: flex;
		flex-direction: column;
		font-size: 0.9em;
		font-weight: normal;
		line-height: 1.1;
		&:last-of-type {
			margin-bottom: 0;
		}
	}
`;

const getLabel = key => {
	const labels = {
		count: { name: 'Count', unit: '' },
		mean_height: { name: 'Mean Height of Objects', unit: 'm' },
		mean_confidence: { name: 'Mean Confidence', unit: '' },
		height_std: { name: 'Height STD', unit: 'm' },
		min_height: { name: 'Min Height', unit: 'm' },
		max_height: { name: 'Max Height', unit: 'm' },
		mean_object_area: { name: 'Mean Object Area', unit: 'm²' },
		sum_object_area: { name: 'Sum Object Area', unit: 'm²' },
	};

	let result = '';

	if (labels.hasOwnProperty(key)) {
		result = { name: labels[key].name, unit: labels[key].unit };
	} else {
		result = { name: key, unit: '' }; // If a label is not found, use the original key as the label
	}

	return result;
};

const HexagonDetails = ({ count, area, meanHeight }) => (
	<Details>
		<p>
			<span>Objects in Total</span> {formatAreaNumber(count, 0)}
		</p>
		<p>
			<span>Area</span> {formatAreaNumber(area)} m²
		</p>
		{meanHeight ? (
			<p>
				<span>Mean Height of Objects</span>{' '}
				{meanHeight?.toFixed(2) ?? '-'} m
			</p>
		) : null}
	</Details>
);

const DetectionItem = ({ id, itemKey, detections }) => (
	<AccordionItem
		eventKey={`hexagon-detections-item-event-${itemKey}`}
		key={`hexagon-detections-item-${itemKey}`}>
		<AccordionHeader>
			{itemKey} ({formatAreaNumber(detections[itemKey].count, 0)})
		</AccordionHeader>
		<AccordionBody>
			<DetectionsDetail>
				{Object.keys(detections[itemKey]).map(detailKey => {
					const label = getLabel(detailKey);
					let value = detections[itemKey][detailKey];

					switch (detailKey) {
						case 'count':
							value = formatAreaNumber(value, 0);
							break;
						case 'mean_confidence':
							value = `${Math.round(value * 100)}%`;
							break;
					}

					return (
						<p
							key={
								id
									? `key-${id}-${itemKey}-${detailKey}`
									: `key-total-${itemKey}-${detailKey}`
							}>
							<Name>{label?.name}</Name>
							<span>
								{value != 0 ? (
									<>
										{value} {label?.unit}
									</>
								) : (
									<>-</>
								)}
							</span>
						</p>
					);
				})}
			</DetectionsDetail>
		</AccordionBody>
	</AccordionItem>
);

export const HexagonAccordion = ({ hexagonData }) => {
	if (!hexagonData?.detections) return null;

	const { detections, metadata: { count, area, mean_height } = {} } =
		hexagonData || {
			detections: {},
			metadata: {
				count: 0,
				area: 0,
				mean_height: 0,
			},
		};

	return (
		<>
			<HexagonDetails
				count={count}
				area={area}
				meanHeight={mean_height}
			/>
			<Accordion flush>
				{Object.keys(detections).map(key => (
					<DetectionItem
						key={`hexagon-detections-${key}`}
						id={hexagonData.id}
						itemKey={key}
						detections={detections}
					/>
				))}
			</Accordion>
		</>
	);
};

export default HexagonAccordion;
