/**
 * Converts area to specified unit
 * @param {*} area Area in square meters
 * @param {*} unit Desired unit (da or ha)
 * @returns
 */
export const convertArea = (area, unit = 'ha') => {
	// If are is not a number, return undefined
	if (area === null || isNaN(area)) {
		console.error('Invalid area. Please provide a valid number.');
		return undefined;
	}

	let result = 0;
	if (unit === 'da') {
		// 1 decares = 100 square meters
		result = area / 100;
	} else if (unit === 'ha') {
		// 1 hectare = 10,000 square meters
		result = area / 10000;
	} else {
		console.error(
			"Invalid unit. Please use 'da' for decares or 'ha' for hectares."
		);
		return undefined;
	}

	return parseFloat(result.toFixed(2));
};
