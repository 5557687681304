import { useState } from 'react';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

import { StyledModal } from '@components/modal/ModalStyle';
import { startPostProcessing } from '@api';
import Checkbox from '@components/form/Toggle';
import Input from '@components/form/Input';

const handleChangeNumber = (setValue, e) => {
	// if match number of . then return
	if (e.target.value.match(/^[0-9]*[.]?[0-9]*$/)) {
		//if (e.target.value.match(/^[0-9]*$/)) {
		setValue(e.target.value);
	}
};

const PostprocessModal = ({ project_uuid, show, setShow }) => {
	const [dsm, setDsm] = useState(true);
	const [fastOrtophoto, setFastOrtophoto] = useState(true);
	const [resolution, setResolution] = useState('0.1');
	const [ignoreGsd, setIgnoreGsd] = useState(false);
	const [meshSize, setMeshSize] = useState('200000');
	const [tiles, setTiles] = useState(true);
	const [imageCount, setImageCount] = useState('0');

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(false);

	return (
		<StyledModal
			variant="dark"
			show={show}
			centered={true}
			onHide={() => {
				setShow(false);
			}}>
			<Modal.Header closeButton closeVariant="white">
				<Modal.Title>ODM Postprocess</Modal.Title>
			</Modal.Header>

			<form
				onSubmit={async e => {
					e.preventDefault();
					setLoading(true);

					try {
						await startPostProcessing({
							project_id: project_uuid,
							image_count: imageCount,
							fastOrtophoto,
							dsm,
							resolution,
							ignoreGsd,
							meshSize,
							tiles,
							uploadPreprocessedPhoto: false,
						});

						setSuccess(true);
						setLoading(false);
					} catch (err) {
						console.error(err);
						setError(err.message);
						setLoading(false);
					}
				}}>
				<Modal.Body>
					{loading && (
						<div className="d-flex p-2 justify-content-center">
							<Spinner animation="border" variant="white" />
						</div>
					)}

					{success && <p>Process started successfully</p>}

					{error && <p>Error: {error}</p>}

					{!loading && !(success || error) && (
						<>
							<Input
								label="Image count"
								type="text"
								value={imageCount}
								onChange={e => setImageCount(e.target.value)}
							/>

							<br />

							<Checkbox
								value={dsm}
								onChange={() => setDsm(!dsm)}
								label="DSM">
								DSM
							</Checkbox>

							<br />

							<Checkbox
								value={fastOrtophoto}
								onChange={() =>
									setFastOrtophoto(!fastOrtophoto)
								}
								label="Fast Ortophoto">
								Fast Ortophoto
							</Checkbox>

							<br />

							<Input
								label="Resolution"
								step="0.1"
								min="0.01"
								max="10"
								tooltip="Ground resolution surface model and ortophoto"
								type="text"
								placeholder="0.1"
								value={resolution}
								onChange={e =>
									handleChangeNumber(setResolution, e)
								}
							/>

							<br />

							<Checkbox
								value={ignoreGsd}
								onChange={() => setIgnoreGsd(!ignoreGsd)}
								label="Ignore GSD">
								Ignore GSD
							</Checkbox>

							<br />

							<Input
								label="Mesh Size"
								type="text"
								placeholder="Mesh Size"
								value={meshSize}
								onChange={e =>
									handleChangeNumber(setMeshSize, e)
								}
							/>

							<br />

							<Checkbox
								value={tiles}
								onChange={() => setTiles(!tiles)}
								label="Tiles">
								Tiles
							</Checkbox>
						</>
					)}
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={() => setShow(false)}>
						Close
					</Button>
					<Button
						variant="success"
						type="submit"
						disabled={loading || success || error}>
						Run
					</Button>
				</Modal.Footer>
			</form>
		</StyledModal>
	);
};
export default PostprocessModal;
