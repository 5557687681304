import { useState } from 'react';
import BooleanValue from '@routes/adminRoutes/sharedComponents/BooleanValue';
import { VerifyButton } from '@routes/adminRoutes/sharedComponents/SharedStyledComponents';
import { adminVerifyEmail } from '@api';

const EmailVerify = ({ cellValue, email }) => {
	const [verified, setVerified] = useState(cellValue);
	const handleVerify = async () => {
		if (window.confirm(`Are you sure you want to verify ${email}?`)) {
			await adminVerifyEmail(email)
				.then(response => {
					console.log('response', response);
					if (response?.status === 'success') {
						setVerified(true);
					} else {
						window.alert('Failed to verify email');
					}
				})
				.catch(err => {
					console.error(err);
					window.alert('Failed to verify email');
				});
		}
	};

	return (
		<>
			<BooleanValue value={verified} />

			{!verified && (
				<VerifyButton size="sm" onClick={handleVerify}>
					Verify
				</VerifyButton>
			)}
		</>
	);
};

export default EmailVerify;
