import { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';

import { useProject } from '@contexts/Project.context';

import Input from '@components/form/Input';

const StyledForm = styled(Form)`
	display: grid;
	grid-template-columns: 3fr 80px;
	margin-bottom: 1rem;
	> div{
		margin-bottom: 0;
	}
`;

const HexagonSize = ({
	customHexagonSize = null,
	setCustomHexagonSize = null
}) => {

	const { hexagonSize, dispatch, } = useProject();
	const [inputValue, setInputValue] = useState(customHexagonSize || hexagonSize);

	const hexagonSizeChange = (e) => {
		e.preventDefault();

		if(setCustomHexagonSize) {
			setCustomHexagonSize(inputValue);
			return;
		}

		dispatch({ type: 'setHexagonSize', payload: inputValue });
	}

	useEffect(() => {

		if(customHexagonSize) {

			if(customHexagonSize !== inputValue) {
				setInputValue(customHexagonSize);
			}

			return;
		}

		if(hexagonSize && hexagonSize !== inputValue) {
			setInputValue(hexagonSize);
		}

	}, [customHexagonSize, hexagonSize ]);

  	return (
		<StyledForm onSubmit={hexagonSizeChange}>
			<Input
				type="number"
				label="Hexagon size (m²)"
				name="hexagonSize"
				value={inputValue}
				onChange={(event) => setInputValue(event.target.value)}
				step="1"
			/>

			<Button type="submit" variant="dark" size="sm">Apply</Button>
		</StyledForm>
  	);
}

export default HexagonSize;
