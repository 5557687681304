import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';

export const VerifyButton = styled(Button)`
	&.btn.btn-primary {
		background: none;
		border: 1px solid ${props => props.theme.colors.textColor};
		font-weight: 300;
		margin-left: 10px;
		font-size: 0.7em;
		padding: 0.2em 0.5em;
		&:hover {
			background: ${props => props.theme.colors.green};
			border-color: ${props => props.theme.colors.green};
			color: #fff;
		}
	}
`;

export const StickyTableHeaderWrapper = styled.div`
	.table-responsive {
		overflow: auto;
		max-height: 55vh;
		margin-bottom: 20px;
	}

	thead {
		position: sticky;
		top: 0;
		background-color: ${props => props.theme.colors.background};
	}
`;

export const StyledTable = styled(Table)`
	background: none;
	color: ${props => props.theme.colors.textColor};
	min-height: 300px;
	opacity: ${props => (props.$loading ? 0.3 : 1)};
	transition: all 0.3s ease-in-out;

	.uuid,
	.subscription_id {
		button span {
			max-width: 120px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			display: block;
		}
	}

	tbody tr {
		border-bottom: 1px solid
			rgba(${props => props.theme.colors.borderLightRgb}, 0.3);
		.custom-cell {
			padding: 16px 8px;
		}

		&:hover * {
			color: ${props => props.theme.colors.textColor};
		}
	}

	.archived {
		border-color: ${props => props.theme.colors.background};
		td {
			background-color: rgba(255, 0, 0, 0.1);
		}
	}
`;

export const StyledTableHeader = styled.span`
	font-weight: 500;
	color: ${props => props.theme.colors.textAccent};
	font-size: 0.9em;
	opacity: 0.7;
`;

export const FilterWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: end;
	gap: 20px;
	margin-bottom: 40px;
	background-color: ${props => props.theme.colors.modal};
	border: ${props => `1px solid ${props.theme.colors.border}`};
	padding: 1.5rem;
`;

export const FilterButton = styled(Button)`
	display: block;
	background: none;
	min-height: 38px;
	text-align: left;
	color: ${props => props.theme.colors.textColor};
	min-width: 156px;
	&.btn {
		border: 1px solid hsl(0, 0%, 30%) !important;
	}
	span {
		display: block;
		font-weight: normal;
	}
`;

export const FooterText = styled.p`
	font-size: 0.9rem;
	text-align: right;
	span {
		font-weight: bold;
	}
`;
