import { api } from './index.js';

export const createOrganization = async org => {
	const { data } = await api.post(`/organizations/`, org);
	return data;
};

export const getOrganizations = async ({
	offset = 0,
	limit = 100,
	search = '',
	archived = false,
} = {}) => {
	const params = {
		deleted: archived,
	};
	if (search) params.search = search;
	if (offset) params.offset = offset;
	params.limit = limit;

	const { data } = await api.get('/organizations/', { params });
	return data;
};

export const getOrganizationsByMember = async user_uuid => {
	const { data } = await api.get(`/organizations/member/${user_uuid}`);
	return data;
};

export const getOrganization = async organization_uuid => {
	const { data } = await api.get(`/organizations/${organization_uuid}`);
	return data;
};

export const updateOrganization = async ({ uuid, ...organization }) => {
	const { data } = await api.put(`/organizations/${uuid}`, organization);
	return data;
};

export const archiveOrganization = async organization_uuid => {
	const { data } = await api.delete(`/organizations/${organization_uuid}`);
	return data;
};

export const getOrganizationsProjects = async ({
	offset = 0,
	limit = 100,
	search = '',
	archived = false,
	dateRange,
	organization_uuid,
}) => {
	const params = {
		deleted: archived,
		search,
		offset,
		limit,
	};

	if (dateRange?.from) params.start_date = dateRange.from;
	if (dateRange?.to) params.end_date = dateRange.to;

	const { data } = await api.get(
		`/organizations/${organization_uuid}/projects`,
		{ params }
	);
	return data;
};
