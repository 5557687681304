import {useEffect, useRef, useState} from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { getProjectProgress } from "../../utils/api";
import useNotify from './notify';

import CircularProgress from "./circularProgress.component";
import { getJobProgressStream } from "../../utils/api";
import { getJobName } from "../../utils/helpers";

const StyledNotification = styled.div`
    border-radius: 6px;
    background-color: ${p => p.theme.colors.backgroundAccent};
`;

const StyledNotificationHeading = styled.div`
    
`;
const StyledNotificationDescription = styled.div`
    font-size: 12px;
    color: ${p => p.theme.colors.textAccent};
    p{
        margin: 0;
    }
`;
const StyledNotificationBody = styled.div`
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 10px;
    position: relative;
`;

const StyledLink = styled(Link)`
    color: #ffffff;
    &:hover{
        color: #ffffff;
    }
`;

const Notification = ({notification: n, setShow}) => {
    const { notifyUpdate } = useNotify();
    const [progress, setProgress] = useState(null);
    const [notification, setNotification] = useState(n);
    const timer = useRef(null);

    /**
     * 
     * @param progress Current progress
     * 
     * @description Updates the notification progress and notifies the notification context
     */
    const updateNotifyProgress = (progress) => {
        const copy = {...notification};
        copy.progress = progress;
        setProgress(progress);
        notifyUpdate(copy);
    }

    /**
     * 
     * @param apiCall API call to check progress 
     * @param uuid UUID of the project or job
     * 
     * @description Checks the progress of the project or job 
     */
    const check = async (apiCall, uuid) => {
        apiCall(uuid).then((res) => {
            if(res && res.hasOwnProperty('progress') && res.progress !== null){
                // If progress is less than 100, check again in 2 seconds
                if(res.progress <= 99 ){
                    setProgress(res.progress);
                    if(!timer.current) {
                        timer.current = setInterval(() => {
                            //check(apiCall, uuid);
                        }, 2000);
                    }
                }  else if(res.progress === 100 ){
                    updateNotifyProgress(res.progress);
                    clearInterval(timer.current);
                }
                
            } else {
                updateNotifyProgress(null);
                clearInterval(timer.current);
            }
        }).catch((err) => {
            updateNotifyProgress(null);
            console.error(err);
        });
    }

    useEffect(() => {
        let eventSource;

        // If notification is not null, check the progress
        if(notification){

            if(notification.progress !== null){
                setProgress(notification.progress);

                // If progress is 100, return
                if(notification.progress === 100 || notification.job_status === 'finished') return;
            }

            if(notification.type === 'odm') {
                // If notification is of type odm, check the project progress
                check(getProjectProgress, notification.project?.uuid);

            } else if(notification.type === 'job') {
                // If notification is of type job, start stream to check the job progress
                try{
                    eventSource = getJobProgressStream(notification.uuid);

                    eventSource.onmessage = (event) => {
                        const data = JSON.parse(event.data);

                        if(data.progress <= 99 ){
                            setProgress(data.progress);
                        }  else if(data.progress === 100 ){
                            eventSource.close();
                            updateNotifyProgress(data.progress);
                        }
                    };

                    eventSource.onerror = (error) => {
                        console.error(error);
                        updateNotifyProgress(null);
                    };
                } catch (e) {
                    console.error(e);
                }

            }
        }

        return () => {
            clearInterval(timer.current);
            if(eventSource) eventSource.close();
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notification]);

    useEffect(() => {
        if(!notification){
            setNotification(n);
        }
    }, [n, notification]);


    if(!notification) return null;

    return (
        <StyledNotification className="text-white p-3" role="status">
            <StyledNotificationBody className="text-white">
                <div>
                    <StyledNotificationHeading role="heading">
                        {notification.project && notification.project?.title && notification.project?.uuid ? 
                            (<StyledLink to={'/projects/' + notification.project.uuid} onClick={() => {setShow();}} reloadDocument>{notification.project.title}</StyledLink>) 
                            : 
                            (<>Unknown project title</>)}
                    </StyledNotificationHeading>
                    
                    <StyledNotificationDescription>
                        {notification.type === 'odm' && (
                            <p>Images processing</p>
                        )}
                        {notification.type === 'job' && (
                            <p>{getJobName(notification.job_type)}</p>
                        )}
                        {notification.created_at && (
                            <p>{new Date(notification.created_at).toLocaleDateString('nb-NO', 
                            {hour: 'numeric', minute: 'numeric', day: 'numeric', month: 'short', year: 'numeric'})}</p>
                        )}
                    </StyledNotificationDescription>
                </div>

                {progress !== null && (
                    <CircularProgress size={40} strokeWidth={3} progress={Math.ceil(progress)} status={notification.job_status} />
                )}
                
            </StyledNotificationBody>

            
        </StyledNotification>
    );
}

export default Notification;