import { createContext, useContext, useState } from 'react';
import { shutdown as intercomShutdown } from '@intercom/messenger-js-sdk';

import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import {
	getUserData,
	getUserSubscription,
	logoutUserOnServer,
	deleteUserCookiesFromBrowser,
} from '../utils/api';

export const ROLES = {
	Admin: 'admin',
	User: 'user',
};

export const TIERS = {
	Trial: 'trial',
	Pro: 'pro',
	Premium: 'premium',
};

const defaultUser = {
	userName: '',
	email: '',
	loggedIn: false,
	active_org_name: null,
	active_org_id: null,
	active_org_access: null,
};

function createUserObject(data, error) {
	if (error || !data) {
		return { ...defaultUser };
	}

	return { ...data, loggedIn: true };
}

export const useAuth = () => {
	const context = useContext(UserContext);

	if (context === undefined) {
		throw new Error('useAuth must be used within a UserContext');
	}

	return context;
};

export const UserContext = createContext({});

export const UserProvider = ({ children }) => {
	const [notifications, setNotifications] = useState([]);
	const navigate = useNavigate();

	const { isLoading, isError, data, error, refetch } = useQuery({
		queryKey: ['user-me'],
		queryFn: getUserData,
		retry: 3,
		staleTime: 1000 * 60 * 5, // data is considered fresh for 5 minutes
	});

	// Get the user's subscription status
	const {
		data: subscription,
		isLoading: subscriptionLoading,
		refetch: refetchSubscription,
		error: subscriptionError,
	} = useQuery({
		queryKey: ['userSubscription', data?.subscription_id, data?.uuid],
		queryFn: () => getUserSubscription(data?.subscription_id),
		enabled: !!data?.subscription_id && !!data?.uuid,
	}) || {};

	const logoutCurrentUser = async () => {
		await logoutUserOnServer();
		deleteUserCookiesFromBrowser();

		// Shutting down Intercom from Chatbot.jsx
		intercomShutdown();

		await refetch();

		navigate('/login');
	};

	const currentUser = createUserObject(data, isError);

	// Destructure properties from currentUser for cleaner code
	const {
		role_admin,
		role_user,
		tier_trial,
		active_org_id,
		trial_expired,
		tier_pro,
		tier_premium,
		is_org_admin,
		active_org_access,
		access,
	} = currentUser || {};

	const subscriptionActive = subscription && subscription.status === 'active';

	const value = {
		// User data
		currentUser,
		rawCurrentUser: data,
		logoutCurrentUser,
		refetchCurrentUser: refetch,

		// Loading and error states
		loading: isLoading,
		isError,
		error,

		// User roles
		roleAdmin: role_admin,
		roleUser: role_user,

		// User tiers
		tierTrial: tier_trial && !active_org_id,
		trialEnded: trial_expired,
		tierPro: tier_pro && !active_org_id,
		tierPremium: tier_premium && !active_org_id,

		// Subscription
		subscription,
		subscriptionLoading,
		refetchSubscription,
		subscriptionActive,
		subscriptionError,

		// User access - only for premium users
		userAccess: tier_premium ? access : null,

		// User organization
		isEnterprise: !!active_org_id,
		isOrgAdmin: is_org_admin,
		activeOrgAccess: is_org_admin ? ['all'] : active_org_access ?? null,

		// Notifications
		notifications,
		setNotifications,
	};

	return (
		<UserContext.Provider value={value}>{children}</UserContext.Provider>
	);
};
