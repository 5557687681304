const HelperText = ({ id, children }) => {
	if (!id){
		console.error('HelperText component requires an id prop');
		return;
	}

	return (
		<div id={`help-${id}`} className="small text-muted lh-sm my-2">{children}</div>
	);
};
export default HelperText;
