import { useLocation, Navigate, Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';

import { useAuth } from '../contexts/User.context';

const Loader = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80vh;
	width: 100%;
	color: ${props => props.theme.colors.textColor};
`;

const RequireAuth = ({ allowedRoles, allowedTiers }) => {
	const {
		currentUser,
		loading,
		subscriptionLoading,
		tierPro,
		subscriptionActive
	} = useAuth();
	const location = useLocation();

	if (loading || (!!currentUser?.subscription_id && subscriptionLoading))
		return (
			<Loader>
				<Spinner animation="border" role="status">
					<span className="visually-hidden">Loading page</span>
				</Spinner>
			</Loader>
		);

	// Used to restrict pro users that no longer have an active subscription
	const allowedPaths = ['/checkout', '/profile', '/projects/'];
	const currentPath = location.pathname;

	if (tierPro && !subscriptionActive && !allowedPaths.some(path => currentPath.includes(path))) {
		return <Navigate to="/" state={{ from: location }} replace />
	}

	// Check if the user is allowed to access the route based on their role or tier.
	return (allowedRoles ? allowedRoles.includes(currentUser?.role) : true) && (allowedTiers ? allowedTiers.includes(currentUser?.tier) : true) ? (
		<Outlet />
	) : currentUser?.loggedIn ? (
		<Navigate to="/unauthorized" state={{ from: location }} replace />
	) : (
		<Navigate to="/login" state={{ from: location }} replace />
	);
};

export default RequireAuth;
