import { api, baseURL } from './index.js';

export const getProjectJobs = async project_uuid => {
	try {
		const res = await api.get(
			`/projects/${project_uuid}/jobs?active_only=true`
		);
		return res.data;
	} catch (err) {
		return err.response;
	}
};

export const getUserJobs = async user_uuid => {
	try {
		const res = await api.get(`/jobs/${user_uuid}/jobs`);
		return res.data;
	} catch (err) {
		return err.response;
	}
};

export const getJobProgress = async job_uuid => {
	try {
		const res = await api.get(`/jobs/${job_uuid}/progress`);
		return res.data;
	} catch (err) {
		return err.response;
	}
};

export const getJobProgressStream = job_uuid => {
	try {
		const url = baseURL + `/jobs/${job_uuid}/progress_sse`;

		const source = new EventSource(url, {
			withCredentials: true, // enables sending cookies for authentication
		});

		return source;
	} catch (err) {
		return err;
	}
};

export const getModelsDetectedOn = async project_uuid => {
	const { data } = await api.get(`/jobs/models_detected_on/${project_uuid}`);
	return data;
};
