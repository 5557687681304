import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

import View from '@components/layout/views/View';

const Unauthorized = () => {
	const navigate = useNavigate();

	return (
		<View maxWidth={550} title="Unathorized">
			<p>You are not authorized to view this page.</p>
			<Button onClick={() => navigate(-1)} variant="success">
				Go Back
			</Button>
		</View>
	);
};

export default Unauthorized;
