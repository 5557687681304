import Container from "react-bootstrap/Container";
import styled from "styled-components";

const OuterContainer = styled.div`
	padding-top: 2.5rem;
	padding-bottom: 3rem;
`;
export const StyledView = styled(Container)`
	color: ${(props) => props.theme.colors.textColor};


	${p => p.maxwidth && `
		max-width: ${p.maxwidth}px;
	`}
`;

const Split = styled.div`
	display: grid;
	grid-template-columns: ${(props) => props.splittemplate};
	grid-gap: 2rem;

	@media screen and (max-width: 1199px) {
		grid-template-columns: 1fr;
	}
`;

const SplitView = ({
	maxWidth = null,
	splitTemplate = "3fr 2fr",
	leftContent,
	rightContent,
	bottomContent,
	...props
}) => {
	return (
		<OuterContainer>
			<StyledView maxwidth={maxWidth} {...props}>
				<Split splittemplate={splitTemplate}>
					<div>
						{leftContent}
					</div>
					<div>
						{rightContent}
					</div>
				</Split>

			</StyledView>
			<div>
				{bottomContent}
			</div>
		</OuterContainer>
	);
};

export default SplitView;
