import Alert from '@components/alert/Alert';

const DatasetQuality = ({exifFootprint}) => {
    const variant = exifFootprint?.overlap_score >= 60 ? 'success' : (exifFootprint?.overlap_score >= 40 ? 'warning' : 'danger');

    if (!exifFootprint?.overlap_score) return null;

    return (
        <Alert variant={variant} heading="Dataset Quality">
            Added dataset has an overlap score of {exifFootprint.overlap_score.toFixed()}%.
        </Alert>
    );
}
export default DatasetQuality;