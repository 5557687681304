import { getToken } from '@contexts/Token.context';
import { fileApiUrl } from '@utils/api';

import { Tile as TileLayer } from 'ol/layer';
import XYZ from 'ol/source/XYZ';

/**
 * Adds a photo layer to the map
 * @param {string} url - the url to the photo
 * @returns {TileLayer} the layer
 */
export const makePhotoLayer = ({
	name,
	zIndex,
	defaultProjection,
	url,
	extent,
	maxZoomLevel = 22,
	visible,
	layerId,
}) => {
	return new TileLayer({
		preload: Infinity,
		name: name,
		defaultProjection: defaultProjection,
		extent: extent,
		visible: visible,
		zIndex: zIndex,
		properties: {
			customLayerId: layerId,
		},
		source: new XYZ({
			tileLoadFunction: async (imageTile, src) => {
				if (!src.includes(fileApiUrl))
					return (imageTile.getImage().src = src);

				const token = getToken();
				const response = await fetch(src, {
					method: 'GET',
					headers: {
						authorization: `Bearer ${token}`,
					},
				});
				if (!response.ok || response.status === 204) return;

				const signedUrl = await response.json();
				imageTile.getImage().src = signedUrl.url;
			},
			url: url,
			tileSize: [256, 256],
			title: 'ortho',
			attributions: '',
			minZoom: 10,
			maxZoom: maxZoomLevel,
			crossOrigin: 'anonymous',
		}),
	});
};
