import { adminGetProjectFilesDowloadUrl } from '@api';

export const downloadProjectFiles = async (project_uuid, path = 'images/') => {
	return new Promise((resolve, reject) => {
		adminGetProjectFilesDowloadUrl(project_uuid, path)
			.then(download_urls => {
				if (Object.keys(download_urls).length === 0) {
					// If download_urls is empty object, then the folder has no files
					reject(
						`No images to download found in folder ${path} in s3 bucket.`
					);
				} else if (Object.keys(download_urls).length > 0) {
					console.log('Download urls:', download_urls);
					for (const [key, url] of Object.entries(download_urls)) {
						downloadFile(key, url);
						//break; // Exit the loop after the first file is downloaded for testing
					}

					resolve(
						'Files found and download initiated. Check your downloads folder.'
					);
				} else {
					reject('Failed to get any download urls.');
				}
			})
			.catch(err => {
				console.error(err);
				reject(
					'Something went wrong while trying to get download urls. Check the console for more details.'
				);
			});
	});
};

export const downloadFile = (key, signed_url) => {
	console.log('Downloading file:', key, signed_url);
	fetch(signed_url)
		.then(response => {
			if (!response.ok) {
				throw new Error('Network response was not ok');
			}
			return response.blob();
		})
		.then(blob => {
			const filename = key.split('/').pop(); // Extract filename from the key
			const a = document.createElement('a');
			const url = URL.createObjectURL(blob);
			a.href = url;
			a.download = filename;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(url);
			a.remove();
		})
		.catch(error => {
			console.error('Error downloading file:', error);
			window.alert(
				`Failed to download file ${key}. Check the console for more information.`
			);
		});
};
