import { useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

import { archiveProject } from '@utils/api';

import { StyledModal } from '@components/modal/ModalStyle';
import Input from '@components/form/Input';

const ArchiveProjectDialog = ({ project, callBack = false }) => {
	const [inputValue, setInputValue] = useState('');
	const innerRef = useRef();

	const resetStates = (success = false) => {
		setInputValue('');

		if (callBack) {
			callBack(success);
		}
	};

	const archiveMutation = useMutation({
		mutationFn: archiveProject,
		onSuccess: data => {
			resetStates(true);
		},
	});

	const handleArchiveProject = e => {
		e.preventDefault();

		if (!project) return;

		archiveMutation.mutate(project.id);
	};

	if (!project) return null;

	return (
		<StyledModal
			variant="dark"
			show={!!project}
			centered={true}
			onHide={() => {
				resetStates();
			}}
			onShow={() => {
				innerRef.current.focus();
			}}>
			<Modal.Header closeButton closeVariant="white">
				<Modal.Title>Delete project</Modal.Title>
			</Modal.Header>

			<Form onSubmit={handleArchiveProject}>
				<Modal.Body>
					{archiveMutation.isLoading && (
						<Spinner animation="border" variant="primary" />
					)}

					{!archiveMutation.isLoading && archiveMutation.error && (
						<p>
							Something went wrong and the project could not be
							deleted. Please try again later.
						</p>
					)}

					{!archiveMutation.isLoading && !archiveMutation.error && (
						<>
							<p>
								You are about to delete the project{' '}
								<strong>
									<i>{project?.title}</i>
								</strong>
								.
							</p>

							<p>
								Please type <strong>DELETE</strong> to confirm.
							</p>
							<Input
								type="text"
								placeholder="Type DELETE in all caps to confirm this action"
								onChange={e => setInputValue(e.target.value)}
								className="text-white"
								ref={innerRef}
							/>
						</>
					)}
				</Modal.Body>

				<Modal.Footer>
					<Button variant="secondary" onClick={() => resetStates()}>
						Cancel
					</Button>
					{!archiveMutation.isError && (
						<Button
							variant="danger"
							type="submit"
							disabled={inputValue !== 'DELETE'}>
							Delete
						</Button>
					)}
				</Modal.Footer>
			</Form>
		</StyledModal>
	);
};
export default ArchiveProjectDialog;
