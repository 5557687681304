import { useEffect } from 'react';
import styled from 'styled-components';
import { Fill, Stroke, Style } from 'ol/style';
import { Select } from 'ol/interaction';

import { useProject } from '@contexts/Project.context';
import HexagonAccordion from './sidebarElements/HexagonAccordion';
import {
	Header,
	HeaderText,
	Container,
	CloseIcon,
} from './sidebarElements/SidebarTemplate';

const Content = styled.div`
	background-color: ${props => props.theme.colors.background};
`;

const HexagonInfo = () => {
	const { selectedHexagon, selectedSingleImage, dispatch } = useProject();

	const closeHexagonInfo = () => {
		selectedHexagon.selectInteraction?.getFeatures().clear();
		dispatch({ type: 'setSelectedHexagon', payload: null });
	};

	const handleKeyUp = e => {
		if (
			selectedHexagon?.selectInteraction?.getFeatures() &&
			e.key === 'Escape'
		)
			closeHexagonInfo();
	};

	useEffect(() => {
		window.addEventListener('keyup', handleKeyUp);
		return () => {
			window.removeEventListener('keyup', handleKeyUp);
		};
	}, [selectedHexagon]);

	if (!selectedHexagon || selectedSingleImage) return null;

	return (
		<Container align="right" zindex="20">
			<Content>
				<Header>
					<HeaderText>Selected Hexagon Info</HeaderText>
					<CloseIcon onClick={closeHexagonInfo} role="button" />
				</Header>
				<HexagonAccordion hexagonData={selectedHexagon.data} />
			</Content>
		</Container>
	);
};

export default HexagonInfo;

/**
 * Extracts unique feature types from an array of features and returns them as a comma-separated string.
 *
 * @param {Array} featuresAtPixel - An array of features from which to extract the feature types.
 * @returns {string} A comma-separated string of unique feature types.
 */
const getFeatureTypes = featuresAtPixel => {
	return featuresAtPixel
		.reduce((accumulator, feature) => {
			const featureType = feature.get('type');
			if (featureType)
				if (!accumulator.includes(featureType))
					accumulator.push(featureType);
			return accumulator;
		}, [])
		.join(', ');
};

export function addHexagonInteraction(
	layer,
	mapObject,
	dispatch,
	hexagonInteractionAdded
) {
	/**
	 * Determines if a hexagon can be selected based on the event and feature types at the pixel.
	 *
	 * @param {Object} event - The event object containing information about the pointer event.
	 * @returns {boolean} True if the hexagon can be selected, false otherwise.
	 */
	const selectHexagonCondition = event => {
		const featuresAtPixel = mapObject.getFeaturesAtPixel(event.pixel);
		const featureTypes = getFeatureTypes(featuresAtPixel);

		if (!featureTypes?.includes('detection') && event.type === 'pointerup')
			return true;

		return false;
	};

	const hexagonSelectStyle = new Style({
		zIndex: 1000,
		stroke: new Stroke({
			color: 'rgba(255, 140, 0, 1)',
			width: 4,
		}),
		fill: new Fill({
			color: 'rgba(255, 140, 0, 0.25)',
		}),
	});

	hexagonInteractionAdded.current = new Select({
		toggleCondition: () => false, // stops shift-hold from multi-selecting
		condition: selectHexagonCondition, // selects hexagon on pointer up if not clicking on detection feature
		style: hexagonSelectStyle,
		layers: [layer],
	});

	hexagonInteractionAdded.current.on('select', function (e) {
		const { data } = e.selected[0]?.getProperties() ?? { data: null };
		if (data) {
			dispatch({
				type: 'setSelectedHexagon',
				payload: {
					data,
					selectInteraction: hexagonInteractionAdded.current,
				},
			});
		} else {
			dispatch({ type: 'setSelectedHexagon', payload: null });
		}
	});

	mapObject.addInteraction(hexagonInteractionAdded.current);

	layer.on('change:visible', () => {
		dispatch({ type: 'setSelectedHexagon', payload: null });
	});
}
