import styled from 'styled-components';
import { PiAngle, PiMapPin, PiFileImage } from 'react-icons/pi';

import CopyButton from '@routes/adminRoutes/sharedComponents/CopyButton';

const StyledOverlay = styled.div`
	background-color: ${props => props.theme.colors.background};
	padding: 10px 15px;
	font-size: 0.9rem;

	ul {
		list-style: none;
		padding: 0;
		margin: 0;
		display: flex;
		gap: 20px;
		justify-content: space-between;
	}

	li {
		display: flex;
		align-items: center;
		gap: 5px;
	}

	button {
		font-size: 0.9rem;
		font-weight: 400;
		color: ${props => props.theme.colors.textColor};
	}
`;
export const SelectedImageOverlayTooltip = ({ overlayContent }) => {
	if (!overlayContent) return null;

	const { name, rotation, center } = overlayContent;

	// Function to format coordinates to 5 decimal places
	const formatCoordinate = coord => parseFloat(coord.toFixed(5));

	// Format the center coordinates
	const formattedCenter = center.map(formatCoordinate).join(', ');

	return (
		<StyledOverlay>
			<ul>
				<li>
					<PiFileImage /> <span>{name}</span>
				</li>
				<li>
					<PiAngle /> <span>{rotation}</span>
				</li>
				<li>
					<PiMapPin />
					<CopyButton
						value={formattedCenter}
						placement="right"
						className="d-inline w-auto">
						{formattedCenter}
					</CopyButton>
				</li>
			</ul>
		</StyledOverlay>
	);
};

const StyledImage = styled.img`
	max-width: 250px;
	max-height: 250px;
`;
const ClassItems = styled.div`
	padding: 5px 10px;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	gap: 10px;
	font-size: 0.9rem;
	color: white;
	text-shadow: 1px 1px 1px black;
`;
const ColorDot = styled.span`
	width: 10px;
	height: 10px;
	display: inline-block;
	border-radius: 50%;
	margin-right: 2px;
	background-color: rgba(
		${props => props.r},
		${props => props.g},
		${props => props.b},
		1
	);
	box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
`;
export const SingleImageOverlayTooltip = ({
	imageUrl,
	detectionClasses,
	colorScheme,
}) => {
	if (!imageUrl || !detectionClasses) return null;

	return (
		<div>
			<StyledImage src={imageUrl} />
			<ClassItems>
				{detectionClasses?.map(item => {
					const { r, g, b } = colorScheme[item.id]?.color || {
						r: 0,
						g: 0,
						b: 0,
						a: 0,
					};
					return (
						<div key={item.id}>
							<ColorDot r={r} g={g} b={b} />
							{item.count}
						</div>
					);
				})}
			</ClassItems>
		</div>
	);
};
