import { Link } from 'react-router-dom';
import styled from 'styled-components';

import Button from 'react-bootstrap/Button';
import { FaLocationDot } from 'react-icons/fa6';

import ProjectTagsWrapper from '@components/projectComponents/projectTags/ProjectTagsWrapper';
import ProjectThumbnail from '@components/projectComponents/ProjectThumbnail';
import { CardTitle, CardDate } from '../ProjectCardComponents';
import { getFeatureByUuid } from '../utils/helpers';

const StyledListItem = styled.div`
	display: grid;
	grid-template-columns: 8rem minmax(14rem, 100vw);
	${props => props.$error && 'grid-template-columns: minmax(auto, 100vw);'}
	grid-template-rows: auto;
	grid-auto-flow: column;

	height: max-content;
	background: ${props => props.theme.colors.modal};
	border-radius: 0.375rem;
	position: relative;

	overflow: hidden;
`;

const ProjectTags = styled(ProjectTagsWrapper)`
	/* Styles tag */
	ul li span.badge {
		padding: 0.125rem 0.25rem !important;
	}
`;

const Body = styled.div`
	display: grid;
	grid-template-rows: min-content min-content;
	${props => props.$error && 'background: var(--bs-danger-bg-subtle);'}

	padding: 1rem 1.25rem;
	padding-top: 1.5rem;
`;

const StyledCardTitle = styled(CardTitle)`
	/* Truncates Overflowing Text */
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
`;

const ButtonWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	gap: 0.5rem;
	margin-top: 1.5rem;

	/* Attaches button wrapper to right side for larger screens */
	@media (min-width: 2000px) {
		justify-self: end;
	}
	@media (max-width: 991.98px) {
		justify-self: end;
	}
`;

const ZoomToPinButton = styled(Button)`
	justify-self: start;
`;

const StyledError = styled.p`
	margin: 0;
	margin-top: 1.5rem;
`;

const ListItem = ({ projectData, map }) => {
	if (!projectData || !map) return null;
	const { orthophoto_progress, uuid, tags, title, created_at } = projectData;
	const error = !!orthophoto_progress?.error;

	const zoomToProject = () => {
		if (!uuid) return;
		const feature = getFeatureByUuid(map, 'Cluster Pin Layer', uuid);
		if (!feature) return;

		const view = map.getView();
		view.setCenter(feature.get('data').center_coordinate);
		view.setZoom(20);
	};

	return (
		<StyledListItem $error={error}>
			<ProjectTags tags={tags} uuid={uuid} />
			{!error && <ProjectThumbnail uuid={uuid} />}
			<Body $error={error}>
				<StyledCardTitle> {title} </StyledCardTitle>
				<CardDate created_at={created_at} />
				{error ? (
					<StyledError className="alert alert-danger small p-2">
						Project processing has failed
					</StyledError>
				) : (
					<ButtonWrapper>
						<Button
							to={`/projects/${uuid}/`}
							as={Link}
							variant="dark">
							View Project
						</Button>
						<ZoomToPinButton onClick={zoomToProject} variant="dark">
							<FaLocationDot />
						</ZoomToPinButton>
					</ButtonWrapper>
				)}
			</Body>
		</StyledListItem>
	);
};
export default ListItem;
