import { useCallback, useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';

import { Circle, Polygon } from 'ol/geom';
import Feature from 'ol/Feature';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style.js';

import { useProject, ProjectMode } from '@contexts/Project.context';
import { getMasterFeaturesTrainingData } from '@api';
import Checkbox from '../sidebars/sidebarElements/Checkbox';
import { deleteLayerByCustomId, getLayerByCustomId } from '@utils/map/helpers';

const createColorScheme = (classData, colorOptions) => {
	const classIds = classData.map(data => data.classid);
	const labels = classData.map(data => data.label);

	const colorStyles = [];

	classIds.forEach((classId, index) => {
		colorStyles[classId] = {
			label: labels[index],
			color: colorOptions[index],
			visible: true,
		};
	});

	return colorStyles;
};

/*
 *  This component is responsible for rendering the human training data
 */
const TrainingDataLayer = ({ layerId, featureType }) => {
	const {
		mapObject,
		project,
		colorOptions,
		pickedTask,
		projectMode,
		dispatch,
	} = useProject();

	const taskId = pickedTask?.model_uuid;

	const { data, isError, error } = useQuery({
		queryKey: ['training_data_master_features', project.uuid, taskId],
		queryFn: () =>
			getMasterFeaturesTrainingData(project.uuid, taskId, featureType),
		enabled:
			!!project?.uuid &&
			!!taskId &&
			projectMode === ProjectMode.ORTHOPHOTO,
		refetchOnWindowFocus: false,
		retry: false,
	});

	const adding = useRef(false);
	const [trainingDataLayer, setTrainingDataLayer] = useState(null);

	const removeTrainingDataLayer = () => {
		if (mapObject) {
			const deleted = deleteLayerByCustomId(mapObject, layerId);
			if (deleted) {
				console.log('removed training data layer');
			}
		}

		setTrainingDataLayer(null);
	};

	const updateTrainingDataLayer = useCallback(() => {
		// if the map object exists, task ID and project ID is set, create and add the layer
		if (mapObject && taskId && !adding.current) {
			adding.current = true;
			let layerSource = null;

			if (!data) {
				adding.current = false;
				return;
			}

			const existingLayer = getLayerByCustomId(mapObject, layerId);

			const { features } = data;

			// get the feature for area of interest from the API
			if (features?.length && features[0]?.geometry) {
				const classData = _.uniqBy(
					features.map(feature => ({
						classid: feature.properties.classid,
						label: feature.properties.classname,
					})),
					'classid'
				);

				const colorScheme = createColorScheme(classData, colorOptions);

				dispatch({
					type: 'setTrainingDataColorScheme',
					payload: colorScheme,
				});

				const makeGeometry = feature => {
					const geometry = feature.geometry;
					if (geometry.type === 'Point') {
						return new Circle(
							geometry.coordinates,
							feature.properties.radius
						);
					} else if (geometry.type === 'Polygon') {
						return new Polygon(geometry.coordinates);
					}
				};

				const makeStyle = color => {
					return new Style({
						fill: new Fill({
							color: 'rgba(0, 0, 0, 0.05)',
						}),
						stroke: new Stroke({
							color: color,
							width: 2,
						}),
					});
				};

				const trainingDataVectorSource = new VectorSource({
					features: features.map(feature => {
						const { classid, classname, confidence } =
							feature.properties;

						const feat = new Feature({
							geometry: makeGeometry(feature),
							data: feature.properties,
							classId: classid,
							className: classname,
							confidence: confidence,
						});

						let colorStr = 'red';

						try {
							const { r, g, b } =
								colorScheme[classid].color ??
								colorOptions[classid];
							colorStr = `rgba(${r}, ${g}, ${b}, 0.5)`;
						} catch (e) {
							console.warn('Error in styling training data:', e);
						}

						feat.setStyle(makeStyle(colorStr));

						return feat;
					}),
				});

				if (existingLayer) {
					console.log(
						`Training Data Layer already exists. Adding it to state.`
					);
					layerSource = existingLayer;
					// Update source
					layerSource.setSource(trainingDataVectorSource);
				} else {
					layerSource = new VectorLayer({
						zIndex: 12,
						source: trainingDataVectorSource,
						name: 'Training data',
						properties: {
							customLayerId: layerId,
						},
						visible: false,
					});

					// add the layer to the map
					mapObject.addLayer(layerSource);
					console.log(
						'added training data points layer',
						featureType
					);
				}
			} else if (existingLayer) {
				// No data related to the layer and it is not needed anymore. Delete it.
				deleteLayerByCustomId(mapObject, layerId);
			}

			setTrainingDataLayer(layerSource);
			adding.current = false;
		}
	}, [data]);

	useEffect(() => {
		updateTrainingDataLayer();

		if (isError) {
			console.warn('could not fetch training data', error);
			removeTrainingDataLayer();
		}

		return () => {
			removeTrainingDataLayer();
		};
	}, [updateTrainingDataLayer, isError]);

	if (!trainingDataLayer) return null;

	return (
		<div id="trainingDataLayer">
			<Checkbox
				label={trainingDataLayer.get('name')}
				handleCheck={() => trainingDataLayer.setVisible(true)}
				handleUncheck={() => trainingDataLayer.setVisible(false)}
				defaultState={false}
				layer={trainingDataLayer}
				canEdit={false}
			/>
		</div>
	);
};
export default TrainingDataLayer;
